import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material/styles";
import {CloseIcon, DeleteIcon, Logo, LogoIcon, NewProjectIcon, RenameIcon} from "./HomeIcons";
import React, {useContext, useEffect, useState} from "react";
import {AuthContext} from "../../context/AuthContext";
import {
    formatToReadableLargeTitle, IMAGE_UPLOAD,
    RefreshTokenAndRetry
} from "../../utils/utils";
import {collection, doc, onSnapshot, query, updateDoc, where} from "firebase/firestore";
import {db} from "../../Firebase";
import {
    AssetDeleted, AssetProjectId, AssetType,
    AssetUserId,
    kAssetsCollectionName,
    kProjectsCollectionName,
    ProjectDeleted,
    ProjectUserId
} from "../../utils/CollectionConstants";
import {
    ChangeViewButton, EditButton, MobileLogoutButton,
    RecordingStudioButton,
    ShareButton,
    SortButton,
    ThreeDotsButton,
    UploadButton
} from "./HomeButtons";
import {Card, ClickAwayListener, DialogContent, Typography} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import {FetchContext} from "../../context/FetchContext";
import NewEditableTitle, {ReadOnlyTitle} from "./NewEditableTitle";
import {NewGridRecordings, RecordingsListView} from "../NewHome/NewAssetAndProjectCards";
import {useConfirmDialog} from "./DeleteConfirmation";
import TextField from "@mui/material/TextField";
import {useNavigate, useParams} from "react-router-dom";
import UploadContentCard from "./UploadContentCard";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SettingsIcon from "@mui/icons-material/Settings";
import LogoutIcon from "@mui/icons-material/Logout";
import NewShareProjectCard from "./NewShareProjectCard";
import mixpanel from "mixpanel-browser";
import {Laptop, VideoCall} from "@mui/icons-material";


const kLibrettoCurrentProjectId = 'librettoCurrentProjectId';
const kLibrettoSortNewest = 'librettoSortNewest';
const kLibrettoGridView = 'librettoGridView';

const NewProjectCard = ({newProjectTitle, setNewProjectTitle, onKeyDown, onClose, onClick}) => {

    const boxStyle = {
        display: "flex",
        flexDirection: "column",
        width: "486px",
        padding: "24px 24px 40px 24px",
        alignItems: "center",
        gap: "32px",
        borderRadius: "12px",
    }

    const headerStyle = {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "flex-start",
        alignSelf: "stretch",
    }

    const headerTextStyle = {
        fontFamily: "Inter",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "24px",
        letterSpacing: "0.18px",
    }

    const textBoxStyle = {
        display: "flex",
        width: "100%",
    }

    const confirmButtonStyle = {
        display: "flex",
        height: "52px",
        padding: "10px 60px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        background: "#2B6BFD",
        borderRadius: "6px",
        cursor: "pointer",
        // Hover effect
        '&:hover': {
            background: "linear-gradient(0deg, rgba(0, 0, 0, 0.10)0%, rgba(0, 0, 0, 0.10)100%), #2B6BFD",
        },
    }

    const buttonTextStyle = {
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "20px",
        letterSpacing: "0.14px",
        color: "#FFFFFF",
    }

    return (
        <Box style={boxStyle}>
            <Box style={headerStyle}>
                <Box style={headerTextStyle}>
                    Set the title of your new project
                </Box>
                <Box style={{display: "flex", marginLeft: "auto", cursor: "pointer"}} onClick={onClose}>
                    <CloseIcon/>
                </Box>
            </Box>
            <Box style={textBoxStyle}>
                <TextField
                    variant="outlined"
                    name="title"
                    fullWidth
                    onKeyDown={onKeyDown}
                    value={newProjectTitle}
                    onChange={(e) => setNewProjectTitle(e.target.value)}
                    margin="normal"
                    InputProps={{
                        style: {
                            fontFamily: "Inter",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 500,
                            lineHeight: "20px",
                            letterSpacing: "0.14px",
                            color: "#000000",
                            height: "64px",
                            backgroundColor: "white",
                        },
                    }}
                    InputLabelProps={{
                        style: {
                            color: '#9E9E9E',
                            fontFamily: 'Inter',
                            fontSize: '12px',
                            fontWeight: 400,
                        },
                        shrink: true,
                    }}
                    sx={{
                        width: '100%',
                        height: '64px',
                        marginTop: "10px",
                        '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                                border: "1px solid #e8e8e8",
                            },
                            '&:hover fieldset': {
                                border: "1px solid #e8e8e8",
                            },
                            '&.Mui-focused fieldset': {
                                border: "1px solid #e8e8e8",
                            },
                        },
                        '& .MuiInputBase-root': {
                            backgroundColor: 'white',
                        },
                    }}
                />
            </Box>
            <Box sx={confirmButtonStyle} onClick={onClick}>
                <Box style={buttonTextStyle}>Confirm</Box>
            </Box>
        </Box>
    )
}

export const RecordingTypesContextMenu = ({setRecordingTypesMenuOpen, handleScreenRecordingClick, handleStudioClick}) => {

    const boxStyle = {
        position: "absolute",
        top: "70px",
        right: "390px",
        display: "inline-flex",
        padding: "16px",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        gap: "4px",
        borderRadius: "12px",
        background: "#FFFFFF",
        boxShadow: "0px 30px 80px 0px rgba(0, 0, 0, 0.15)",
        zIndex: 1000,
    }

    const itemStyle = {
        display: "flex",
        padding: "16px",
        alignItems: "center",
        gap: "8px",
        alignSelf: "stretch",
        cursor: "pointer",
        borderRadius: "8px",
        // On hover
        '&:hover': {
            background: "#F3F4F5",
        },
    }

    const itemTextStyle = {
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "20px",
        letterSpacing: "0.14px",
    }

    const itemTextSubtitileStyle = {
        fontFamily: "Inter",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "16px",
    }

    return (
        <ClickAwayListener onClickAway={() => setRecordingTypesMenuOpen(false)}>
            <Card style={boxStyle}>
                <Box sx={itemStyle} onClick={handleScreenRecordingClick}>
                    <Box style={{display: "flex", height: "20px", width: "20px", marginBottom: "2px"}}>
                        <Laptop/>
                    </Box>
                    <Box style={itemTextStyle}>
                        Screen Recording
                    </Box>
                </Box>
                <Box sx={itemStyle} onClick={handleStudioClick}>
                    <Box style={{display: "flex", height: "20px", width: "20px", marginBottom: "2px"}}>
                        <VideoCall/>
                    </Box>
                    <Box style={itemTextStyle}>
                        Go to Studio
                    </Box>
                </Box>
            </Card>
        </ClickAwayListener>
    )
}

export const ProjectContextMenu = ({setThreeDotsMenuOpen, top, right, handleRenameClick, handleDeleteClick, isAsset}) => {

    const boxStyle = {
        position: "absolute",
        top: "70px",
        right: isAsset ? "260px" : "515px",
        display: "inline-flex",
        padding: "16px",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        gap: "4px",
        borderRadius: "12px",
        background: "#FFFFFF",
        boxShadow: "0px 30px 80px 0px rgba(0, 0, 0, 0.15)",
        zIndex: 1000,
    }

    const itemStyle = {
        display: "flex",
        padding: "16px",
        alignItems: "center",
        gap: "8px",
        alignSelf: "stretch",
        cursor: "pointer",
        borderRadius: "8px",
        // On hover
        '&:hover': {
            background: "#F3F4F5",
        },
    }

    const itemTextStyle = {
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "20px",
        letterSpacing: "0.14px",
    }

    return (
        <ClickAwayListener onClickAway={() => setThreeDotsMenuOpen(false)}>
            <Card style={boxStyle}>
                <Box sx={itemStyle} onClick={handleRenameClick}>
                    <Box style={{display: "flex", height: "20px", width: "20px"}}>
                        <RenameIcon/>
                    </Box>
                    <Box style={itemTextStyle}>
                        {isAsset ? "Rename Recording" : "Rename Project"}
                    </Box>
                </Box>
                <Box sx={itemStyle} onClick={handleDeleteClick}>
                    <Box style={{display: "flex", height: "20px", width: "20px"}}>
                        <DeleteIcon/>
                    </Box>
                    <Box style={itemTextStyle}>
                        {isAsset ? "Delete Recording" : "Delete Project"}
                    </Box>
                </Box>
            </Card>
        </ClickAwayListener>
    )
}

const ProfileAvatarMenu = ({
                               setProfileAvatarMenuOpen,
                               profileMenuLeft,
                               profileMenuRight,
                               handleLogoutClick,
                               handleSettingsClick
                           }) => {

    const boxStyle = {
        position: "absolute",
        bottom: "100px",
        left: profileMenuLeft,
        display: "inline-flex",
        padding: "16px",
        minWidth: profileMenuRight - profileMenuLeft,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        gap: "4px",
        borderRadius: "12px",
        background: "#FFFFFF",
        boxShadow: "0px 30px 80px 0px rgba(0, 0, 0, 0.15)",
        zIndex: 1000,
    }

    const itemStyle = {
        display: "flex",
        padding: "16px",
        alignItems: "center",
        gap: "8px",
        alignSelf: "stretch",
        cursor: "pointer",
        borderRadius: "8px",
        // On hover
        '&:hover': {
            background: "#F3F4F5",
        },
    }

    const itemTextStyle = {
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "20px",
        letterSpacing: "0.14px",
    }

    return (
        <ClickAwayListener onClickAway={() => setProfileAvatarMenuOpen(false)}>
            <Card style={boxStyle}>
                <Box sx={itemStyle} onClick={handleSettingsClick}>
                    <Box style={{marginTop: "8px"}}>
                        <SettingsIcon/>
                    </Box>
                    <Box style={itemTextStyle}>
                        Settings
                    </Box>
                </Box>
                <Box sx={itemStyle} onClick={handleLogoutClick}>
                    <Box style={{marginTop: "8px"}}>
                        <LogoutIcon/>
                    </Box>
                    <Box style={itemTextStyle}>
                        Logout
                    </Box>
                </Box>
            </Card>
        </ClickAwayListener>
    )
}

const RecordingsView = ({assets, setSwitchToComputerFormOpen, showAsGrids, isSharedView, token, isMobile}) => {

    if (showAsGrids && !isMobile) {
        return (
            <Grid container spacing={2} marginTop={1}>
                <NewGridRecordings recordings={assets} setSwitchToComputerFormOpen={setSwitchToComputerFormOpen} isSharedView={isSharedView} token={token}/>
            </Grid>
        )
    }


    return (
        <Grid item marginTop={2}>
            <RecordingsListView recordings={assets} setSwitchToComputerFormOpen={setSwitchToComputerFormOpen} isSharedView={isSharedView} token={token} isMobile={isMobile}/>
        </Grid>
    );
};

const mainAreaStyle = (isMobile) => {
    return {
        flex: 1,
        height: "100%",
        // Padding right "32px", top "20px", bottom "20px", left "60px"
        padding: isMobile ? "10px 10px 10px 10px" : "20px 32px 20px 60px",
        overflowY: "auto",
        overflowX: "hidden",
    }
}

const headerButtonsStyle = (isMobile) => {
    return {
        display: isMobile ? "none" : "flex",
        flexDirection: "row",
        gap: "8px",
    }
}

const topAreaStyle = (isMobile) => {
    return {
        display: isMobile ? "none" : "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    }
}

const headerAndButtonsStyle = {
    display: "flex",
    flexDirection: "row",
    marginTop: "40px",
    justifyContent: "space-between",
}

const recordingTextStyle = {
    fontFamily: "Inter",
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "24px",
}

const pageStyle = {
    height: "100vh",
    width: "100vw",
    backgroundColor: "#f3f4f5",
    display: "flex",
    flexDirection: "row",
}

const logoBoxStyle = {
    display: "flex",
    padding: "20px 12px",
    alignItems: "center",
    gap: "2px",
    alignSelf: "stretch",
    marginBottom: "24px",
    cursor: "pointer",
}

const NewHome = () => {

    const theme = useTheme();

    const navigate = useNavigate();

    const [projects, setProjects] = useState([]);
    const [currentProjectId, setCurrentProjectId] = useState(null);
    const [currentProjectAssets, setCurrentProjectAssets] = useState([]);
    const [sortedAssets, setSortedAssets] = useState([]);

    const authContext = useContext(AuthContext);
    const fetchContext = useContext(FetchContext);

    const projectId = authContext.getLibrettoCurrentProjectId();

    mixpanel.identify(authContext.getUserId());

    mixpanel.people.set({ '$name': authContext.getUserDisplayName(),
        '$email': authContext.getUserEmail(),
        'plan' : authContext.getUserPlan(),
    });

    const [showAsGrids, setShowAsGrids] = useState(() => {
        const stored = sessionStorage.getItem(kLibrettoGridView);
        return stored !== null ? stored === 'true' : false;
    });
    const [sortNewest, setSortNewest] = useState(() => {
        const stored = sessionStorage.getItem(kLibrettoSortNewest);
        return stored !== null ? stored === 'true' : true;
    });

    useEffect(() => {
        const handleStorageChange = () => {
            const stored = sessionStorage.getItem(kLibrettoSortNewest);
            const storedGrid = sessionStorage.getItem(kLibrettoGridView);
            setSortNewest(stored === 'true');
            setShowAsGrids(storedGrid === 'true');
        };

        window.addEventListener('storage', handleStorageChange);
        return () => window.removeEventListener('storage', handleStorageChange);
    }, []);

    const [isEditingTitle, setIsEditingTitle] = useState(false);

    const [titleCreationCardOpen, setTitleCreationCardOpen] = useState(false);
    const [titleCreationProjectId, setTitleCreationProjectId] = useState(null);

    const [threeDotsMenuOpen, setThreeDotsMenuOpen] = useState(false);
    const [threeDotsMenuRight, setThreeDotsMenuRight] = useState(0);
    const [threeDotsMenuTop, setThreeDotsMenuTop] = useState(0);

    const [recordingTypesMenuOpen, setRecordingTypesMenuOpen] = useState(false);

    const [switchToComputerFormOpen, setSwitchToComputerFormOpen] = useState(false);

    const [profileAvatarMenuOpen, setProfileAvatarMenuOpen] = useState(false);
    const [profileMenuLeft, setProfileMenuLeft] = useState(0);

    const [profileMenuRight, setProfileMenuRight] = useState(0);

    const [newProjectTitle, setNewProjectTitle] = useState('Untitled Project');

    const [showUploadCard, setShowUploadCard] = useState(false);

    const [showShareProjectCard, setShowShareProjectCard] = useState(false);

    const [editButtonLoading, setEditButtonLoading] = useState(false);

    const auth = useContext(AuthContext);

    const userId = authContext.getUserId();

    const handleSortButtonClick = () => {
        const newSortNewest = !sortNewest;
        setSortNewest(newSortNewest);
        sessionStorage.setItem(kLibrettoSortNewest, newSortNewest.toString());
    };

    const handleGridViewButtonClick = () => {
        const newShowAsGrids = !showAsGrids;
        setShowAsGrids(newShowAsGrids);
        sessionStorage.setItem(kLibrettoGridView, newShowAsGrids.toString());
    }

    useEffect(() => {
        if (projects.length > 0 && !projects.some(project => project.projectId === currentProjectId)) {
            const newCurrentProjectId = projects[0].projectId;
            setCurrentProjectId(newCurrentProjectId);
            sessionStorage.setItem(kLibrettoCurrentProjectId, newCurrentProjectId);
        }
    }, [projects, currentProjectId]);

    // Sort current project assets by date based on sortNewest
    useEffect(() => {
        const sorted = [...currentProjectAssets].sort((a, b) => {
            return sortNewest ? b.createTime - a.createTime : a.createTime - b.createTime;
        });
        setSortedAssets(sorted);
    }, [sortNewest, currentProjectAssets]);

    const pageStyle = {
        height: "100vh",
        width: "100vw",
        backgroundColor: "#f3f4f5",
        display: "flex",
        flexDirection: "row",
    }

    const isXl = useMediaQuery(theme.breakpoints.up('xl'));
    const isLg = useMediaQuery(theme.breakpoints.up('lg'));

    const sidebarWidth = isXl ? "280px" : isLg ? "250px" : "230px";

    const sideBarStyle = (isMobile) => {
        return {
            width: sidebarWidth,
            height: "100vh",
            backgroundColor: "#fff",
            display: isMobile ? "none" : "flex",
            flexDirection: "column",
            padding: "8px 12px 24px 12px",
            alignItems: "flex-start",
        }
    }

    const createProject = async (projectName) => {
        try {
            const {data} = await fetchContext.authAxios.post('/project', {
                projectName: projectName,
            }, {
                headers: {
                    Authorization: `Bearer ${authContext.getToken()}`,
                }
            });
            setTitleCreationProjectId(data.projectId);
            setTitleCreationCardOpen(true);
        } catch (err) {
            if (err.response.status === 401) {
                await RefreshTokenAndRetry(err, authContext, fetchContext);
            }
            console.log("Error is ", err)
        }
    }

    const sidebarHeaderStyle = {
        display: "flex",
        padding: "0px 12px",
        justifyContent: "space-between",
        alignItems: "center",
        alignSelf: "stretch",
        flexDirection: "row",
    }

    const sidebarHeaderTextStyle = {
        fontFamily: "Inter",
        fontSize: "18px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "24px",
        letterSpacing: "0.18px",
    }

    const newProjectIconStyle = {
        display: "flex",
        padding: "12px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        borderRadius: "100px",
        backgroundColor: "#EFF4FE",
        cursor: "pointer",
        // ON hover
        '&:hover': {
            backgroundColor: "rgba(43, 107, 253, 0.20)",
        },
    }

    const dividerStyle = {
        display: "flex",
        padding: "1px 0px",
        flexDirection: "column",
        alignItems: "flex-start",
        gap: "10px",
        alignSelf: "stretch",
        marginTop: "16px",
        marginBottom: "16px",
    }

    const projectTitleStyle = {
        display: 'flex',
        padding: '16px 12px',
        alignItems: 'center',
        gap: '12px',
        alignSelf: 'stretch',
        borderRadius: '8px',
        cursor: 'pointer',
        // ON hover
        '&:hover': {
            background: "rgba(43, 107, 253, 0.10)",
        },
    }

    const selectedProjectStyle = {
        ...projectTitleStyle,
        background: "#EFF4FE",
    }

    const actualDividerStyle = {
        height: "1px",
        alignSelf: "stretch",
        backgroundColor: "#E8E8E8",
    }

    const effectiveStyle = (index) => {
        if (projects[index].projectId === currentProjectId) {
            return selectedProjectStyle;
        } else {
            return projectTitleStyle;
        }
    }

    const projectTitleTextStyle = {
        color: "#1A1A1A",
        fontFamily: "Inter",
        fontSize: "15px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "20px",
        letterSpacing: "0.16px",
    }

    const selectedProjectTitleTextStyle = {
        ...projectTitleTextStyle,
        color: "#2B6BFD",
    }

    const effectiveTextStyle = (index) => {
        if (projects[index].projectId === currentProjectId) {
            return selectedProjectTitleTextStyle;
        } else {
            return projectTitleTextStyle;
        }
    }

    const profileBoxStyle = {
        display: 'flex',
        // Set width to be 15px less than sidebarWidth
        width: `calc(${sidebarWidth} - 15px)`,
        padding: '12px',
        alignItems: 'center',
        gap: '12px',
        alignSelf: 'stretch',
        borderRadius: '8px',
        justifyContent: "space-between",
        flexDirection: "row",
        marginTop: "auto",
        border: "1px solid #D9DBDE",
        cursor: "pointer",
        // ON hover
        '&:hover': {
            border: "1px solid #BFBFBF",
            //background: "linear-gradient(0deg, rgba(0, 0, 0, 0.10)0%, rgba(0, 0, 0, 0.10)100%), #E3E5E8",
        },
        // Focused state
        '&:focus': {
            border: "1px solid #BFBFBF",
            //background: "linear-gradient(0deg, rgba(255, 255, 255, 0.10)0%, rgba(255, 255, 255, 0.10)100%), #E3E5E8",
        }
    }

    const GetInitial = (name) => {
        return name ? name.charAt(0).toUpperCase() : '?';
    }

    const profileImgStyle = {
        flexShrink: 0,
        display: 'flex',
        width: '45px',
        height: '40px',
        borderRadius: '8px',
        border: '2px solid #E8E8E8',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#F0F0F0',
        fontSize: '20px',
        color: '#555',
        fontWeight: 'bold',
    }

    const titleAndDateAreaStyle = {
        display: "flex",
        gap: "12px",
        flexDirection: "column",
    }

    const topTitleTextStyle = {
        fontFamily: "Inter",
        fontSize: "42px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "52px",
    }

    const recordingsGridStyle = {}

    const userInfoStyle = {
        flexShrink: 1,
        minWidth: 0,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        cursor: "pointer",
    }

    const usernameTextStyle = {
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "20px",
        letterSpacing: "0.14px",
        color: "#1A1A1A",
        // Show ellipsis if the name is too long
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        width: "100%",
    }

    const settingsTextStyle = {
        color: "#808080",
        fontFamily: "Inter",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "16px",
        letterSpacing: "0.48px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        width: "100%",
    }

    const deleteProject = async () => {
        await fetchContext.authAxios.delete(`/project/${currentProjectId}`, {
            headers: {
                Authorization: `Bearer ${authContext.getToken()}`,
            }
        });
    };

    const {openDialog, ConfirmDialogComponent} = useConfirmDialog({confirmText: 'Delete', cancelText: 'Cancel'});

    const handleDeleteClick = () => {
        openDialog({
            title: 'Delete Project',
            message: 'This project and all its recordings will be permanently deleted.',
            onConfirm: async () => {
                await deleteProject();
            },
        });
    };

    useEffect(() => {
        if (!userId) {
            return;
        }

        const q = query(collection(db, kProjectsCollectionName), where(ProjectUserId, "==", userId), where(ProjectDeleted, "==", false));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
                const newProjects = [];
                querySnapshot.forEach((doc) => {
                    newProjects.push(doc.data());
                });
                if (newProjects.length > 0) {
                    setProjects(newProjects);
                    if (!currentProjectId) {
                        if (projectId && newProjects.find(project => project.projectId === projectId)) {
                            setCurrentProjectId(projectId);
                            sessionStorage.setItem(kLibrettoCurrentProjectId, projectId);
                        } else {
                            setCurrentProjectId(newProjects[0].projectId);
                            sessionStorage.setItem(kLibrettoCurrentProjectId, newProjects[0].projectId);
                        }
                    }
                }
            },
            (error) => {
                if (error.code === 'permission-denied') {
                    authContext.logout();
                }
            });

        return () => unsubscribe();
    }, [authContext, userId]);

    // Load the assets that belong to the current project.
    useEffect(() => {
        if (currentProjectId) {
            const q = query(collection(db, kAssetsCollectionName), where(AssetUserId, "==", userId), where(AssetDeleted, "==", false), where(AssetProjectId, "==", currentProjectId));
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                    const newAssets = [];
                    querySnapshot.forEach((doc) => {
                        if (doc.data()[AssetType] !== "SOUNDBOARD_AUDIO" && doc.data()[AssetType] !== "RSS_FEED" && doc.data()[AssetType] !== "BRAND_LOGO" && doc.data()[AssetType] !== "IMAGE_UPLOAD") {
                            newAssets.push(doc.data());
                        }
                    });
                    setCurrentProjectAssets(newAssets);
                },
                (error) => {
                    if (error.code === 'permission-denied') {
                        authContext.logout();
                    }
                });

            return () => unsubscribe();
        } else {
            setCurrentProjectAssets([]);
        }
    }, [currentProjectId]);

// Refreshes urls for assets and tracks of the project
    useEffect(() => {
        if (currentProjectId === null) {
            return;
        }

        const LoadProjectUrls = async () => {
            try {
                await fetchContext.authAxios.get(`/project/${currentProjectId}`, {
                    headers: {
                        Authorization: `Bearer ${authContext.getToken()}`,
                    }
                });
            } catch (error) {
                console.error("Error getting track urls:", error);
            }
        }

        LoadProjectUrls();
        return () => {
        };
    }, [currentProjectId]);

    const handleCardClick = (item) => {
        setCurrentProjectId(item.projectId);
        sessionStorage.setItem(kLibrettoCurrentProjectId, item.projectId);
    }

    const effectiveRecordingsStyle = (showAsGrid) => {

    }

    const handleThreeDotsButtonClick = (event) => {

        if (isMobile) {
            event.preventDefault();
            setSwitchToComputerFormOpen(true);
            return;
        }

        if (threeDotsMenuOpen) {
            setThreeDotsMenuOpen(false);
            return;
        }

        // Get the top and right position of the button
        const top = event.currentTarget.getBoundingClientRect().top;
        const right = event.currentTarget.getBoundingClientRect().right;
        // Set the position of the context menu
        setThreeDotsMenuTop(top);
        setThreeDotsMenuRight(right);
        setThreeDotsMenuOpen(true);
    }

    const handleRecordingTypesButtonClick = (event) => {
        if (isMobile) {
            event.preventDefault();
            setSwitchToComputerFormOpen(true);
            return;
        }

        if (recordingTypesMenuOpen) {
            setRecordingTypesMenuOpen(false);
            return;
        }

        setRecordingTypesMenuOpen(true);
    }

    const handleRenameClick = (e) => {
        if (isMobile) {
            e.preventDefault();
            setSwitchToComputerFormOpen(true);
            return;
        }

        e.stopPropagation();
        setIsEditingTitle(true);
        setThreeDotsMenuOpen(false);
    }

    const updateTitle = async ({projectId, title}) => {
        // Update Firestore
        const entityRef = doc(db, kProjectsCollectionName, projectId);
        try {
            await updateDoc(entityRef, {name: title});
            console.log('Title updated successfully');
        } catch (error) {
            console.error('Error updating title: ', error);
        }
    };

    const handleUpdateTitleClick = async () => {
        await updateTitle({projectId: titleCreationProjectId, title: newProjectTitle});
        setTitleCreationCardOpen(false);
        setNewProjectTitle('Untitled Project');
        setCurrentProjectId(titleCreationProjectId);
        sessionStorage.setItem(kLibrettoCurrentProjectId, titleCreationProjectId);
        setTitleCreationProjectId(null);
    }

    const handleKeyDownForTitle = async (e) => {
        if (e.key === 'Enter') {
            await handleUpdateTitleClick();
        }
    }

    const handleCloseNewProjectCard = () => {
        setTitleCreationCardOpen(false);
        setNewProjectTitle('Untitled Project');
    }

    const navigateToSettings = () => {
        navigate('/settings');
    }

    const upArrowStyle = {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#888',
        flexShrink: 0,
    };

    const handleProfileButtonClick = (e) => {
        e.stopPropagation();
        setProfileMenuLeft(e.currentTarget.getBoundingClientRect().left);
        setProfileMenuRight(e.currentTarget.getBoundingClientRect().right);
        setProfileAvatarMenuOpen(!profileAvatarMenuOpen);
    }

    const logoutUser = async () => {
        await authContext.logout()
    }

    const handleShareButtonClick = (e) => {
        if (isMobile) {
            e.preventDefault();
            setSwitchToComputerFormOpen(true);
            return;
        }

        e.stopPropagation();
        // e is a click on a button. Get the right position of the button
        setShowShareProjectCard(!showShareProjectCard);
    }

    const getFirstName = (name) => {
        if (name) {
            return name.split(' ')[0];
        }
        return '';
    }

    const isMobile = useMediaQuery(theme.breakpoints.down('smd'));

    const handleRecordingStudioButtonClick = (e) => {
        if (isMobile) {
            e.preventDefault();
            setSwitchToComputerFormOpen(true);
            return;
        }

        navigate(`/studio/${currentProjectId}`);
    }

    const handleScreenRecordingClick = (e) => {
        if (isMobile) {
            e.preventDefault();
            setSwitchToComputerFormOpen(true);
            return;
        }

        navigate(`/screen/${currentProjectId}`);
    }

    const handleUploadButtonClick = (e) => {
        if (isMobile) {
            e.preventDefault();
            setSwitchToComputerFormOpen(true);
            return;
        }

        setShowUploadCard(!showUploadCard);
    }

    const CreateEditForProject = async ({projectId}) => {
        try {
            const {data} = await fetchContext.authAxios.post(`/editor/project/${projectId}`, {}, {
                headers: {
                    Authorization: `Bearer ${auth.getToken()}`,
                },
            });
            return data.editId;
        } catch (error) {
            if (error.response.status === 401) {
                await RefreshTokenAndRetry(error, auth, fetchContext);
            }
        }
    }

    const CreateEditAndNavigateToEditor = () => {
        setEditButtonLoading(true);
        CreateEditForProject({projectId: currentProjectId}).then((editId) => {
            navigate(`/edit/${editId}`);
            window.location.reload();
            setEditButtonLoading(false);
        }).catch((error) => console.error("Error creating edit: ", error));
    }

    return (
        <Box style={pageStyle}>
            <Box style={sideBarStyle(isMobile)}>
                <Box style={logoBoxStyle} onClick={() => navigate("/")}>
                    <LogoIcon/>
                </Box>
                <Box style={sidebarHeaderStyle}>
                    <Box style={sidebarHeaderTextStyle}>
                        Projects
                    </Box>
                    <Dialog
                        open={titleCreationCardOpen}
                        onClose={handleCloseNewProjectCard}
                        aria-labelledby="form-dialog-title"
                    >
                        <NewProjectCard setNewProjectTitle={setNewProjectTitle} newProjectTitle={newProjectTitle}
                                        onKeyDown={handleKeyDownForTitle} onClose={handleCloseNewProjectCard}
                                        onClick={handleUpdateTitleClick}/>
                    </Dialog>
                    <Box sx={newProjectIconStyle} onClick={() => createProject("Untitled project")}>
                        <NewProjectIcon/>
                    </Box>
                </Box>
                <Box style={dividerStyle}>
                    <Box style={actualDividerStyle}/>
                </Box>
                {projects.map((item, i) => (
                    <Box key={i} sx={effectiveStyle(i)} onClick={() => handleCardClick(item)}>
                        <Box sx={effectiveTextStyle(i)}>
                            {formatToReadableLargeTitle(item.name)}
                        </Box>
                    </Box>
                ))}
                <Box sx={profileBoxStyle} onClick={handleProfileButtonClick}>
                    <Box sx={profileImgStyle}>
                        {GetInitial(authContext.getUserDisplayName())}
                    </Box>
                    <Box sx={userInfoStyle}>
                        <Box sx={usernameTextStyle}>
                            {getFirstName(authContext.getUserDisplayName())}
                        </Box>
                        <Box sx={settingsTextStyle}>
                            {authContext.getUserEmail()}
                        </Box>
                    </Box>
                    <Box sx={upArrowStyle}>
                        <KeyboardArrowUpIcon/>
                    </Box>
                </Box>
                {profileAvatarMenuOpen &&
                    <ProfileAvatarMenu profileMenuLeft={profileMenuLeft} profileMenuRight={profileMenuRight}
                                       setProfileAvatarMenuOpen={setProfileAvatarMenuOpen}
                                       handleLogoutClick={() => logoutUser()}
                                       handleSettingsClick={() => navigate("/settings")}/>}
            </Box>
            <Box style={mainAreaStyle(isMobile)}>
                {isMobile ?
                    <Box sx={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
                        <Box style={logoBoxStyle} onClick={() => navigate("/")}>
                            <LogoIcon/>
                        </Box>
                        <Box style={logoBoxStyle}>
                            <MobileLogoutButton onClick={logoutUser}/>
                        </Box>
                    </Box> : null}
                <Box style={topAreaStyle(isMobile)}>
                    {currentProjectId ? <NewEditableTitle projectId={currentProjectId} isEditing={isEditingTitle}
                                                          setIsEditingTitle={setIsEditingTitle}/> :
                        <Typography>Loading...</Typography>}
                    <Box style={{flexDirection: "row", gap: "12px", display: "flex"}}>
                        <Box>
                            <ThreeDotsButton onClick={handleThreeDotsButtonClick}/>
                        </Box>
                        {threeDotsMenuOpen &&
                            <ProjectContextMenu setThreeDotsMenuOpen={setThreeDotsMenuOpen} top={threeDotsMenuTop}
                                                right={threeDotsMenuRight} handleRenameClick={handleRenameClick}
                                                handleDeleteClick={handleDeleteClick} isAsset={false}/>}
                        <ConfirmDialogComponent/>
                        <Box>
                            <RecordingStudioButton onClick={handleRecordingTypesButtonClick}/>
                        </Box>
                        {recordingTypesMenuOpen &&
                            <RecordingTypesContextMenu setRecordingTypesMenuOpen={setRecordingTypesMenuOpen} handleStudioClick={handleRecordingStudioButtonClick}
                                                handleScreenRecordingClick={handleScreenRecordingClick}/>}
                        <Box>
                            <EditButton onClick={() => CreateEditAndNavigateToEditor()} loading={editButtonLoading} disabled={true}/>
                        </Box>
                        <Box>
                            <UploadButton onClick={handleUploadButtonClick}/>
                        </Box>
                        <Dialog
                            open={switchToComputerFormOpen}
                            onClose={() => setSwitchToComputerFormOpen(false)}
                            aria-labelledby="form-dialog-title"
                        >
                            <DialogContent sx={{borderRadius: '30px',
                                display: 'flex',        // Enable flexbox
                                justifyContent: 'center', // Center horizontally
                                alignItems: 'center',     // Center vertically
                                minWidth: { xs: '300px', md: '400px' },
                            }}>
                                <Typography><b>Please switch to your computer.</b> For now, you can only do this on a larger screen.</Typography>
                            </DialogContent>
                        </Dialog>
                        <Dialog
                            open={showUploadCard}
                            onClose={() => setShowUploadCard(false)}
                            aria-labelledby="form-dialog-title"
                            maxWidth={"1000px"}
                        >
                            <DialogContent sx={{
                                borderRadius: '30px',
                                display: 'flex',        // Enable flexbox
                                justifyContent: 'center', // Center horizontally
                                alignItems: 'center',     // Center vertically
                                padding: "0px 0px 0px 0px",
                            }}>
                                <UploadContentCard onClose={() => setShowUploadCard(false)}
                                                   currentProjectId={currentProjectId} allowImageUpload={false}/>
                            </DialogContent>
                        </Dialog>
                        <Box>
                            <ShareButton onClick={handleShareButtonClick}/>
                        </Box>
                        {showShareProjectCard && <NewShareProjectCard entityId={currentProjectId}
                                                                      onClose={() => setShowShareProjectCard(false)} isAsset={false}/>}
                    </Box>
                </Box>
                <Box style={headerAndButtonsStyle}>
                    <Box style={recordingTextStyle}>
                        Recordings
                    </Box>
                    <Box style={headerButtonsStyle(isMobile)}>
                        <Box>
                            <SortButton onClick={handleSortButtonClick} sortNewest={sortNewest} iconOnly={false}/>
                        </Box>
                        <Box>
                            <ChangeViewButton isGridView={showAsGrids} onClick={handleGridViewButtonClick}/>
                        </Box>
                    </Box>
                </Box>
                <Box style={effectiveRecordingsStyle(showAsGrids)} key={sortNewest}>
                    <RecordingsView assets={sortedAssets} showAsGrids={showAsGrids}
                                    setSwitchToComputerFormOpen={setSwitchToComputerFormOpen} isMobile={isMobile}/>
                </Box>
            </Box>
        </Box>
    )
}

export const SharedHomePage = () => {

    const theme = useTheme();

    const {projectId, token} = useParams();

    const isMobile = useMediaQuery(theme.breakpoints.down('smd'));

    const fetchContext = useContext(FetchContext);

    const [sortNewest, setSortNewest] = useState(true);
    const [showAsGrids, setShowAsGrids] = useState(false);
    const [projectData, setProjectData] = useState({error: null, isLoaded: false, title: '', assets: [], createTime: null});

    const [switchToComputerFormOpen, setSwitchToComputerFormOpen] = useState(false);

    const [sortedAssets, setSortedAssets] = useState([]);

    useEffect(() => {
        const getProject = async () => {
            try {
                const {data} = await fetchContext.authAxios.get(`/shared/project/${projectId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setProjectData({error: null, isLoaded: true, title: data.name, assets: data.assets, createTime: data.createTime});
            } catch (error) {
                setProjectData({error: error, isLoaded: true, title: '', assets: []});
            }
        }
        getProject();
    }, [projectId, token]);

    const {error, isLoaded, title, assets, createTime} = projectData;

    useEffect(() => {
        const sorted = [...assets].sort((a, b) => {
            return sortNewest ? b.createTime - a.createTime : a.createTime - b.createTime;
        });
        setSortedAssets(sorted);
    }, [sortNewest, assets]);

    const navigate = useNavigate();

    if (error) {
        return <Typography>Error: {error.message}</Typography>;
    }

    if (!isLoaded) {
        return <Typography>Loading...</Typography>;
    }

    const handleSortButtonClick = () => {
        setSortNewest(!sortNewest);
    }

    const handleGridViewButtonClick = () => {
        setShowAsGrids(!showAsGrids);
    }

    return (
        <Box style={pageStyle}>
            <Box style={mainAreaStyle(isMobile)}>
                <Box style={logoBoxStyle} onClick={() => navigate("/")}>
                    <LogoIcon/>
                </Box>
                <Dialog
                    open={switchToComputerFormOpen}
                    onClose={() => setSwitchToComputerFormOpen(false)}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogContent sx={{borderRadius: '30px',
                        display: 'flex',        // Enable flexbox
                        justifyContent: 'center', // Center horizontally
                        alignItems: 'center',     // Center vertically
                        minWidth: { xs: '300px', md: '400px' },
                    }}>
                        <Typography><b>Please switch to your computer.</b> For now, you can only do this on a larger screen.</Typography>
                    </DialogContent>
                </Dialog>
                <Box style={topAreaStyle(isMobile)}>
                    {isLoaded ? <ReadOnlyTitle title={title} date={createTime}/> :
                        <Typography>Loading...</Typography>}
                </Box>
                <Box style={headerAndButtonsStyle}>
                    <Box style={recordingTextStyle}>
                        Recordings
                    </Box>
                    <Box style={headerButtonsStyle(isMobile)}>
                        <Box>
                            <SortButton onClick={handleSortButtonClick} sortNewest={sortNewest} iconOnly={false}/>
                        </Box>
                        <Box>
                            <ChangeViewButton isGridView={showAsGrids} onClick={handleGridViewButtonClick}/>
                        </Box>
                    </Box>
                </Box>
                <Box style={{}} key={sortNewest}>
                    <RecordingsView assets={sortedAssets} showAsGrids={showAsGrids}
                                    setSwitchToComputerFormOpen={setSwitchToComputerFormOpen} isSharedView={true} token={token} isMobile={isMobile}/>
                </Box>
            </Box>
        </Box>
    );
}

export default NewHome;
