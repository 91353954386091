import React, {useState, useEffect, useContext} from 'react';
import {Box, IconButton} from '@mui/material';
import {AudioWaveformIcon, VideoCameraIcon} from "../NewHome/ProjectThumbnail";
import {
    formatDuration,
    formatDurationBrief,
    formatDurationForEditor, formatToReadableLargeTitle,
    formatToReadableShortTitle
} from "../../utils/utils";
import {FetchContext} from "../../context/FetchContext";
import CloseIcon from "@mui/icons-material/Close";
import {Close, Delete} from "@mui/icons-material";
import {TrashIcon} from "../../editor/assets/icons/TrashIcon";
import Tooltip from "@mui/material/Tooltip";

// Replace these imports with your actual icon components

export const SoundboardThumbnail = ({title, isSelected, status, thumbnailUrl, duration, onClick, onDelete, isUpload}) => {
    const [aspectRatio, setAspectRatio] = useState('landscape');

    const handleOnClick = () => {
        if (status === "Ready") {
            onClick();
        }
    }

    const handleOnDelete = (event) => {
        event.stopPropagation();
        if (isUpload && status === "Ready") {
            onDelete();
        }
    }

    const fetchContext = useContext(FetchContext);
    const getProxiedURL = fetchContext.getProxiedURL;

    if (status === "Processing...") {
        status = "Processing";
    }
    if (status === "Upload starting") {
        status = "Uploading";
    }

    useEffect(() => {
        if (thumbnailUrl) {
            const img = new Image();
            img.onload = () => {
                const ratio = img.width / img.height;
                if (ratio > 1.1) setAspectRatio('landscape');
                else if (ratio < 0.9) setAspectRatio('portrait');
                else setAspectRatio('square');
            };
            img.src = getProxiedURL(thumbnailUrl);
        }
    }, [thumbnailUrl]);

    const renderThumbnail = () => {
        if (status === 'Ready') {
            if (!thumbnailUrl) {
                return (
                    <div style={styles.iconContainer}>
                        <AudioWaveformIcon backgroundColor="#E3E5E8"/>
                    </div>
                );
            }
            const proxiedUrl = getProxiedURL(thumbnailUrl);
            return <img src={proxiedUrl} alt={title} style={getThumbnailStyle()}/>;
        } else if (status === 'Processing') {
            return (
                <div style={styles.iconContainer}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="20" viewBox="0 0 12 20" fill="none">
                        <path opacity="0.3" d="M2 5.5L6 9.5L10 5.5V2H2V5.5Z" fill="#F2880C"/>
                        <path opacity="0.3" d="M2 5.5L6 9.5L10 5.5V2H2V5.5Z" fill="#F2880C"/>
                        <path
                            d="M12 0H0V6L4 10L0.0100002 14.01L0 20H12L11.99 14.01L8 10L12 6.01V0ZM10 14.5V18H2V14.5L6 10.5L10 14.5ZM10 5.5L6 9.5L2 5.5V2H10V5.5Z"
                            fill="#F2880C"/>
                    </svg>
                </div>
            );
        } else if (status === 'Uploading') {
            return (
                <div style={styles.iconContainer}>
                    {/* You can add an upload icon SVG here */}
                    <span>Uploading...</span>
                </div>
            );
        }
    };

    const closeIconVisible = isUpload && status === "Ready";

    const cursorNotAllowed = status !== "Ready";

    const getThumbnailStyle = () => {
        const baseStyle = {
            backgroundColor: '#E3E5E8',
        };

        switch (aspectRatio) {
            case 'portrait':
                return {
                    ...baseStyle,
                    position: 'absolute',
                    top: 0,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    height: '100%',
                    width: 'auto',
                    maxWidth: '100%',
                    objectFit: 'contain',
                };
            case 'square':
                return {
                    ...baseStyle,
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    height: '100%',
                    width: 'auto',
                    maxWidth: '100%',
                    maxHeight: '100%',
                    objectFit: 'contain',
                };
            case 'landscape':
            default:
                return {
                    ...baseStyle,
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                };
        }
    };

    const styles = {
        cardWrapper: {
            width: '100%',
            cursor: cursorNotAllowed ? 'not-allowed' : 'pointer',
        },
        thumbnailContainer: {
            width: '100%',
            paddingTop: '56.25%', // 16:9 aspect ratio
            position: 'relative',
            backgroundColor: '#E3E5E8',
            borderRadius: '8px',
            overflow: 'hidden',
            marginBottom: '8px',
            border: '1px solid',
            borderColor: isSelected ? '#2B6bFD' : 'transparent',
        },
        thumbnailImage: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
        },
        iconContainer: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: '#E3E5E8',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        durationOverlay: {
            position: 'absolute',
            bottom: 2,
            right: 2,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            color: 'white',
            padding: '4px 8px',
            borderRadius: '4px',
            fontFamily: 'Inter, sans-serif',
            fontSize: '10px',
            fontWeight: 400,
            letterSpacing: '1%',
            lineHeight: '10px',
        },
        closeButton: {
            position: 'absolute',
            top: 4,
            right: 4,
            padding: 0,
            opacity: 0,
            transition: 'opacity 0.2s ease-in-out',
        },
        closeButtonVisible: {
            opacity: 1,
        },
        titleLabel: {
            fontFamily: 'Inter',
            fontSize: '11px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '14px',
            letterSpacing: '0.16px',
            textAlign: 'flex-start',
            maxWidth: '100%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    };

    return (
        <Box sx={styles.cardWrapper}>
            <Box
                sx={styles.thumbnailContainer}
                onClick={handleOnClick}
                onMouseEnter={(e) => {
                    e.currentTarget.querySelector('.close-button').style.opacity = closeIconVisible ? 1 : 0;
                }}
                onMouseLeave={(e) => {
                    e.currentTarget.querySelector('.close-button').style.opacity = 0;
                }}
            >
                {renderThumbnail()}
                {/* Close Icon on Hover */}
                <Tooltip title={'Delete'} placement={'top'}>
                    <IconButton
                        onClick={handleOnDelete}
                        className="close-button"
                        sx={styles.closeButton}
                        size="small"
                    >
                        <Delete style={{fontSize: '1rem', color: 'black'}}/>
                    </IconButton>
                </Tooltip>
                {/* Duration Overlay - only show for video or audio */}
                {duration && (
                    <div style={styles.durationOverlay}>{duration}</div>
                )}
            </Box>
            {/* Title Below Thumbnail */}
            <div style={styles.titleLabel}>{title}</div>
        </Box>
    );
};

const EditorMediaThumbnail = ({title, thumbnailUrl, duration, status, isVideo, isImage, onClick, onDelete}) => {
    const [aspectRatio, setAspectRatio] = useState('landscape');

    const fetchContext = useContext(FetchContext);
    const getProxiedURL = fetchContext.getProxiedURL;

    useEffect(() => {
        if ((isVideo || isImage) && thumbnailUrl) {
            const img = new Image();
            img.onload = () => {
                const ratio = img.width / img.height;
                if (ratio > 1.1) setAspectRatio('landscape');
                else if (ratio < 0.9) setAspectRatio('portrait');
                else setAspectRatio('square');
            };
            img.src = getProxiedURL(thumbnailUrl);
        }
    }, [thumbnailUrl, isVideo, isImage]);

    if (status === "Processing...") {
        status = "Processing";
    }
    if (status === "Upload starting") {
        status = "Uploading";
    }

    const renderThumbnail = () => {
        if (status === 'Ready') {
            if (isImage && thumbnailUrl) {
                const proxiedUrl = getProxiedURL(thumbnailUrl);
                return <img src={proxiedUrl} alt={title} style={getThumbnailStyle()}/>;
            }

            if (!isVideo) {
                return (
                    <div style={styles.iconContainer}>
                        <AudioWaveformIcon backgroundColor="#E3E5E8"/>
                    </div>
                );
            }

            if (thumbnailUrl) {
                const proxiedUrl = getProxiedURL(thumbnailUrl);
                return <img src={proxiedUrl} alt={title} style={getThumbnailStyle()}/>;
            }

            return (
                <div style={styles.iconContainer}>
                    <VideoCameraIcon backgroundColor="#E3E5E8"/>
                </div>
            );
        } else if (status === 'Processing') {
            return (
                <div style={styles.iconContainer}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="20" viewBox="0 0 12 20" fill="none">
                        <path opacity="0.3" d="M2 5.5L6 9.5L10 5.5V2H2V5.5Z" fill="#F2880C"/>
                        <path opacity="0.3" d="M2 5.5L6 9.5L10 5.5V2H2V5.5Z" fill="#F2880C"/>
                        <path
                            d="M12 0H0V6L4 10L0.0100002 14.01L0 20H12L11.99 14.01L8 10L12 6.01V0ZM10 14.5V18H2V14.5L6 10.5L10 14.5ZM10 5.5L6 9.5L2 5.5V2H10V5.5Z"
                            fill="#F2880C"/>
                    </svg>
                </div>
            );
        } else if (status === 'Uploading') {
            return (
                <div style={styles.iconContainer}>
                    {/* You can add an upload icon SVG here */}
                    <span>Uploading...</span>
                </div>
            );
        }
    };

    const getThumbnailStyle = () => {
        const baseStyle = {
            backgroundColor: '#E3E5E8',
        };

        switch (aspectRatio) {
            case 'portrait':
                return {
                    ...baseStyle,
                    position: 'absolute',
                    top: 0,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    height: '100%',
                    width: 'auto',
                    maxWidth: '100%',
                    objectFit: 'contain',
                };
            case 'square':
                return {
                    ...baseStyle,
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    height: '100%',
                    width: 'auto',
                    maxWidth: '100%',
                    maxHeight: '100%',
                    objectFit: 'contain',
                };
            case 'landscape':
            default:
                return {
                    ...baseStyle,
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                };
        }
    };

    const styles = {
        cardWrapper: {
            width: '100%',
            cursor: 'pointer',
        },
        thumbnailContainer: {
            width: '100%',
            paddingTop: '56.25%', // 16:9 aspect ratio
            position: 'relative',
            backgroundColor: '#E3E5E8',
            borderRadius: '8px',
            overflow: 'hidden',
            marginBottom: '8px',
        },
        thumbnailImage: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
        },
        iconContainer: {
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: '#E3E5E8',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        durationOverlay: {
            position: 'absolute',
            bottom: 2,
            right: 2,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
            color: 'white',
            padding: '4px 8px',
            borderRadius: '4px',
            fontFamily: 'Inter, sans-serif',
            fontSize: '10px',
            fontWeight: 400,
            letterSpacing: '1%',
            lineHeight: '10px',
        },
        closeButton: {
            position: 'absolute',
            top: 4,
            right: 4,
            padding: 0,
            opacity: 0,
            transition: 'opacity 0.2s ease-in-out',
        },
        closeButtonVisible: {
            opacity: 1,
        },
        titleLabel: {
            fontFamily: 'Inter',
            fontSize: '11px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '14px',
            letterSpacing: '0.16px',
            textAlign: 'flex-start',
            maxWidth: '100%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    };

    return (
        <Box sx={styles.cardWrapper}>
            <Box
                sx={styles.thumbnailContainer}
                onClick={onClick}
                onMouseEnter={(e) => {
                    e.currentTarget.querySelector('.close-button').style.opacity = 1;
                }}
                onMouseLeave={(e) => {
                    e.currentTarget.querySelector('.close-button').style.opacity = 0;
                }}
            >
                {renderThumbnail()}
                {/* Close Icon on Hover */}
                <Tooltip title={'Delete'} placement={'top'}>
                    <IconButton
                        onClick={(event) => {
                            event.stopPropagation();
                            onDelete();
                        }}
                        className="close-button"
                        sx={styles.closeButton}
                        size="small"
                    >
                        <Delete style={{fontSize: '1rem', color: 'black'}}/>
                    </IconButton>
                </Tooltip>
                {/* Duration Overlay - only show for video or audio */}
                {!isImage && duration && (
                    <div style={styles.durationOverlay}>{formatDurationBrief(duration)}</div>
                )}
            </Box>
            {/* Title Below Thumbnail */}
            <div style={styles.titleLabel}>{formatToReadableLargeTitle(title)}</div>
        </Box>
    );
};

export default EditorMediaThumbnail;
