/* eslint-disable react/no-unescaped-entities */
import React, {useState} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export const NewLiveSoundboardFeature = () => {
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const [viewPortEntered, setViewPortEntered] = useState(false);
    const setViewPortVisibility = (isVisible) => {
        if (viewPortEntered) {
            return;
        }

        setViewPortEntered(isVisible);
    };

    return (
        <Box>
            <Grid container spacing={isMd ? 4 : 0} direction={isMd ? 'row' : 'column-reverse'} alignItems="center">
                <Grid
                    item
                    container
                    justifyContent="center"
                    alignItems="center"
                    xs={12}
                    md={7}
                    sx={{
                        display: { xs: 'flex' },
                    }}
                >
                    <Box component={Card} boxShadow={4} height={1} width={1}>
                        <Box
                            component={CardMedia}
                            height={1}
                            width={1}
                            minHeight={{ xs: 280, sm: 370, md: 540 }}
                            image="https://storage.googleapis.com/libretto-public-assets/images/mediaboard.png"
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} md={5}>
                    <Box marginBottom={4}>
                        <Typography sx={{ fontWeight: 700 }} variant={'h3'} gutterBottom>
                            <Typography
                                color={'#2B6BFD'}
                                component={'span'}
                                variant={'inherit'}
                            >
                                Media Board for live editing
                            </Typography>
                        </Typography>
                        <Typography component={'p'} color={'text.secondary'} fontFamily={"Inter"} fontSize="1.2rem" fontWeight={500} marginBottom={3}>
                            No more screen-sharing to play audio. With the Media board, you can add pre-recorded sounds or choose from a variety of sound effects and royalty-free music to enhance your recording.
                        </Typography>
                        <Typography component={'p'} color={'text.secondary'} fontFamily={"Inter"} fontSize="1.2rem" fontWeight={500}>
                            Insert intros, outros, and background music during your recording session.
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

const LiveSoundboardFeature = () => {

    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box>
            <Box marginBottom={4}>
                <Box marginBottom={2}>
                    <Typography
                        variant="h3"
                        color="text.primary"
                        align={'flex-start'}
                        gutterBottom
                        sx={{
                            fontWeight: 700,
                        }}
                    >
                        Live
                        {' '}<Typography
                        color={'primary'}
                        component={'span'}
                        variant={'inherit'}
                    >
                        Soundboard
                    </Typography>
                    </Typography>
                    <Typography
                        variant="h6"
                        component="p"
                        color="text.secondary"
                        sx={{ fontWeight: 400 }}
                        align={'flex-start'}
                    >

                    </Typography>
                </Box>
            </Box>
            <Grid container spacing={2} paddingTop={1} justifyContent={'center'} marginLeft={isMobile ? 0.05 : 0}>
                <Box component={Card} boxShadow={4} height={1} width={1}>
                    <Box
                        component={CardMedia}
                        height={1}
                        width={1}
                        minHeight={isMobile ? 100 : 150}
                        style={{
                            backgroundSize: 'contain',  // Ensures the image is scaled down to fit inside the container without cropping
                            backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                            backgroundPosition: 'center', // Centers the image within the container
                            backgroundColor: '#222B45',
                        }}
                        image={isMobile ? 'https://storage.googleapis.com/libretto-public-assets/DarkLiveboard.png' : 'https://storage.googleapis.com/libretto-public-assets/DarkSBoard.png'} // Use "/other-image.png" for xs screens
                    />
                </Box>
            </Grid>
        </Box>
    );
};

export default LiveSoundboardFeature;
