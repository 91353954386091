import {Box, Button} from "@mui/material";
import {StudioBackIconButton} from "../NewStudio/SettingsIcon";
import {
    ClipSize16And9RatioId,
    ClipSize1And1RatioId,
    ClipSize9And16RatioId,
    DisplayToClipSizeId,
    LibrettoStudioTheme
} from "../../utils/utils";
import * as React from "react";
import {useNavigate} from "react-router-dom";
import ClipSizeCard from "../Editor/ClipSizeCard";
import AspectRatioSelectCard from "./ClipSizeCard";
import {RendleyStore} from "../../editor/store/RendleyStore";
import {Crop169, CropPortrait, CropSquare} from "@mui/icons-material";
import MagicToolsCard from "../Editor/MagicToolsCard";
import ModernMagicToolsCard from "../ModernEditor/ModernMagicToolsCard";
import {Avatars} from "../Editor/Avatars";
import {useContext, useEffect} from "react";
import {AuthContext} from "../../context/AuthContext";
import {FetchContext} from "../../context/FetchContext";
import {autorun} from "mobx";
import {Engine} from "@rendley/sdk";

const NewEditorTopbar = ({handleNavigateBack, editId, track, containerRef, canvasRef, noAudioStream, handleRemoveFillerWordsRequest, selectedTrackIndex}) => {

    const navigate = useNavigate();

    const authContext = useContext(AuthContext);
    const fetchContext = useContext(FetchContext);

    const [showClipSizeCard, setShowClipSizeCard] = React.useState(false);
    const [clipSizeTopPosition, setClipSizeTopPosition] = React.useState(0);
    const [clipSizeLeftPosition, setClipSizeLeftPosition] = React.useState(0);
    const [magicToolsTopPosition, setMagicToolsTopPosition] = React.useState(0);
    const [magicToolsLeftPosition, setMagicToolsLeftPosition] = React.useState(0);
    const [showMagicToolsCard, setShowMagicToolsCard] = React.useState(false);
    const [aspectRatioId, setAspectRatioId] = React.useState(DisplayToClipSizeId(RendleyStore.display));

    useEffect(() => {
        const disposeAutorun = autorun(() => {
            const aspectRatioId = DisplayToClipSizeId(RendleyStore.display);
            setAspectRatioId(aspectRatioId);
        });

        // Cleanup autorun on component unmount
        return () => {
            disposeAutorun();
        };
    }, [setAspectRatioId]);

    const navBarStyle = {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    }

    const magicToolsButtonStyle = {
        display: "flex",
        height: "40px",
        width: "180px",
        padding: "16px 24px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        borderRadius: "8px",
        background: "rgba(43, 107, 253, 0.10)",
        // Hover
        '&:hover': {
            background: "rgba(43, 107, 253, 0.20)",
            cursor: "pointer",
        },
    }

    const aspectRatioButtonStyle = {
        display: "flex",
        height: "40px",
        width: "120px",
        padding: "16px 24px",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        borderRadius: "8px",
        background: "rgba(43, 107, 253, 0.10)",
        // Hover
        '&:hover': {
            background: "rgba(43, 107, 253, 0.20)",
            cursor: "pointer",
        },
    }

    const MagicToolsIcon = () => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path
                    d="M13.6903 6.34366L13.3367 5.99011L12.9832 6.34366L12.111 7.21578L11.7575 7.56934L12.111 7.92289L12.9831 8.79498L13.3367 9.14853L13.6902 8.79498L14.5624 7.92286L14.9159 7.56931L14.5624 7.21575L13.6903 6.34366Z"
                    fill="#2B6BFD" stroke="#2B6BFD"/>
                <path
                    d="M10.4661 9.56759L10.1126 9.21403L9.759 9.56759L2.07662 17.25L1.72307 17.6035L2.07662 17.9571L2.94874 18.8292L3.30229 19.1827L3.65585 18.8292L11.3382 11.1468L11.6918 10.7933L11.3382 10.4397L10.4661 9.56759Z"
                    fill="#2B6BFD" stroke="#2B6BFD"/>
                <path
                    d="M19.2158 15.0196C18.6401 14.8706 18.1328 14.5289 17.7783 14.0513C17.4239 13.5738 17.2438 12.9892 17.268 12.395C17.2716 12.3013 17.2404 12.2096 17.1805 12.1375C17.1206 12.0653 17.0362 12.0178 16.9435 12.0041C16.8507 11.9904 16.7562 12.0114 16.6779 12.063C16.5997 12.1147 16.5433 12.1935 16.5196 12.2842C16.3707 12.8599 16.0289 13.3673 15.5514 13.7217C15.0738 14.0762 14.4892 14.2562 13.895 14.232C13.8013 14.2284 13.7096 14.2596 13.6374 14.3195C13.5653 14.3794 13.5178 14.4638 13.5041 14.5565C13.4904 14.6493 13.5114 14.7438 13.563 14.8221C13.6147 14.9003 13.6935 14.9567 13.7842 14.9804C14.3599 15.1294 14.8673 15.4711 15.2217 15.9487C15.5761 16.4263 15.7562 17.0109 15.732 17.6051C15.7285 17.6988 15.7596 17.7905 15.8195 17.8626C15.8794 17.9347 15.9638 17.9822 16.0566 17.9959C16.1493 18.0096 16.2439 17.9886 16.3221 17.937C16.4003 17.8853 16.4567 17.8065 16.4804 17.7158C16.6292 17.14 16.9709 16.6325 17.4485 16.2781C17.9261 15.9236 18.5108 15.7436 19.1051 15.768C19.1988 15.7716 19.2905 15.7404 19.3626 15.6805C19.4347 15.6206 19.4822 15.5362 19.4959 15.4434C19.5096 15.3507 19.4886 15.2561 19.4369 15.1779C19.3853 15.0997 19.3065 15.0433 19.2158 15.0196Z"
                    fill="#2B6BFD"/>
                <path
                    d="M18.8658 0.285028C18.4954 0.542559 18.048 0.66542 17.5981 0.63314C17.1481 0.600861 16.7228 0.415391 16.393 0.10763C16.3199 0.0399841 16.2244 0.00168168 16.1248 5.40786e-05C16.0252 -0.00157352 15.9285 0.0335878 15.8532 0.098808C15.7779 0.164028 15.7293 0.254726 15.7167 0.353539C15.7041 0.452352 15.7284 0.552339 15.7849 0.634361C16.0424 1.00471 16.1653 1.45211 16.133 1.90202C16.1007 2.35194 15.9153 2.77721 15.6075 3.10701C15.5399 3.18014 15.5017 3.27561 15.5001 3.37518C15.4984 3.47474 15.5336 3.57141 15.5988 3.64668C15.664 3.72195 15.7546 3.77054 15.8534 3.78316C15.9522 3.79579 16.0522 3.77155 16.1342 3.71509C16.5046 3.45762 16.952 3.33479 17.4019 3.36707C17.8519 3.39935 18.2771 3.58478 18.607 3.89249C18.6801 3.96008 18.7756 3.99834 18.8752 3.99995C18.9747 4.00156 19.0714 3.9664 19.1467 3.90121C19.2219 3.83602 19.2705 3.74536 19.2832 3.64659C19.2958 3.54781 19.2715 3.44785 19.2151 3.36582C18.9576 2.99546 18.8347 2.54806 18.867 2.09813C18.8993 1.64821 19.0847 1.22292 19.3925 0.893107C19.4601 0.819982 19.4983 0.724504 19.4999 0.624939C19.5016 0.525374 19.4664 0.428711 19.4012 0.35344C19.336 0.278169 19.2454 0.229575 19.1466 0.216954C19.0478 0.204332 18.9478 0.228569 18.8658 0.285028Z"
                    fill="#2B6BFD"/>
                <path
                    d="M3.68767 2.4803C4.37854 2.86783 4.91579 3.48035 5.20993 4.21585C5.50407 4.95135 5.53735 5.76543 5.30421 6.52248C5.27775 6.60964 5.28437 6.70349 5.32279 6.78607C5.36122 6.86866 5.42875 6.93417 5.51247 6.97006C5.59618 7.00595 5.6902 7.00971 5.77651 6.98061C5.86282 6.9515 5.93537 6.89159 5.98026 6.81233C6.36776 6.12144 6.98029 5.58417 7.71581 5.29003C8.45132 4.99589 9.26542 4.96264 10.0225 5.19581C10.1096 5.22227 10.2035 5.21565 10.2861 5.17723C10.3687 5.1388 10.4342 5.07126 10.4701 4.98755C10.506 4.90383 10.5097 4.80982 10.4806 4.72351C10.4515 4.6372 10.3916 4.56466 10.3123 4.51977C9.62144 4.13224 9.08418 3.5197 8.79002 2.78419C8.49587 2.04868 8.46259 1.23459 8.69573 0.477523C8.72219 0.390365 8.71557 0.29651 8.67715 0.213927C8.63872 0.131344 8.57119 0.0658343 8.48747 0.0299411C8.40375 -0.00595212 8.30974 -0.00970699 8.22343 0.0193952C8.13712 0.0484974 8.06457 0.108412 8.01968 0.187668C7.63217 0.878539 7.01965 1.41579 6.28415 1.70993C5.54865 2.00407 4.73458 2.03734 3.97753 1.80419C3.89037 1.77773 3.79651 1.78435 3.71393 1.82277C3.63135 1.8612 3.56583 1.92874 3.52994 2.01245C3.49405 2.09617 3.49029 2.19018 3.5194 2.27649C3.5485 2.3628 3.60841 2.43541 3.68767 2.4803Z"
                    fill="#2B6BFD"/>
            </svg>
        )
    }

    const magicToolsTextStyle = {
        color: "#2B6BFD",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "20px",
        letterSpacing: "0.14px",
    }

    const exportButtonStyle = {
        display: "flex",
        padding: "10px 20px",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
        borderRadius: "8px",
        background: "#2B6BFD",
        // Set hovered state
        "&:hover": {
            background: "linear-gradient(0deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%), #2B6BFD",
            cursor: "pointer",
        },
        // Set focused state
        "&:focus": {
            background: "linear-gradient(0deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.15) 100%), #2B6BFD"
        },
        // Set disabled state
        "&:disabled": {
            background: "#2B6BFD",
            opacity: 0.3,
            cursor: "not-allowed",
        }
    }

    const exportTextStyle = {
        color: "#FFF",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "20px",
        letterSpacing: "0.14px",
    }

    const ClipSizeText = (aspectRatioId) => {
        if (aspectRatioId === ClipSize16And9RatioId) {
            return "16:9"
        }
        if (aspectRatioId === ClipSize9And16RatioId) {
            return "9:16"
        }
        return "1:1"
    }

    const ClipSizeIcon = ({aspectRatioId}) => {
        if (aspectRatioId === ClipSize16And9RatioId) {
            return <Crop169/>
        }
        if (aspectRatioId === ClipSize9And16RatioId) {
            return <CropPortrait/>
        }
        return <CropSquare/>
    }

    const handleClipSizeButtonClick = (event) => {
        const buttonRect = event.target.getBoundingClientRect();
        const left = buttonRect.left;
        const top = buttonRect.top;
        setClipSizeTopPosition(top);
        setClipSizeLeftPosition(left);
        setShowMagicToolsCard(false);
        setShowClipSizeCard(true);
    }

    const handleMagicToolsButtonClick = (event) => {
        const buttonRect = event.target.getBoundingClientRect();
        const left = buttonRect.left;
        const top = buttonRect.top;
        setMagicToolsTopPosition(top);
        setMagicToolsLeftPosition(left);
        setShowClipSizeCard(false);
        setShowMagicToolsCard(true);
    }

    const handleEnhanceAudioRequest = async (trackId) => {
        try {
            const response = await fetchContext.authAxios.post(`enhance/${trackId}`, {}, {
                headers: {
                    Authorization: `Bearer ${authContext.getToken()}`,
                }
            });
        } catch (error) {
            if (error.response.status === 401) {
                authContext.logout();
            }
        }
    }

    const handleExportClick = () => {
        const serializedData = Engine.getInstance().serialize();
        // Download the serialized data as an editor.json file
        const blob = new Blob([JSON.stringify(serializedData)], {type: 'application/json'});
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = 'editor.json';
        a.click();
        URL.revokeObjectURL(url);
    }

    const GroupIcon = () => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path
                    d="M5.33398 0.666504V3.33317H2.66732V12.6665H13.334V1.99984H14.006C14.3713 1.99984 14.6673 2.2965 14.6673 2.66184V13.3378C14.6661 13.5129 14.5961 13.6805 14.4723 13.8044C14.3486 13.9282 14.1811 13.9984 14.006 13.9998H1.99532C1.81986 13.9997 1.65165 13.9298 1.52764 13.8057C1.40364 13.6816 1.33398 13.5133 1.33398 13.3378V2.66184C1.3352 2.48676 1.40525 2.31918 1.52899 2.19532C1.65273 2.07145 1.82024 2.00123 1.99532 1.99984H4.00065V0.666504H5.33398ZM8.00065 5.33317L10.6673 7.99984H8.66732V10.6665H7.33398V7.99984H5.33398L8.00065 5.33317ZM12.0007 0.666504V3.33317H6.66732V1.99984H10.6673V0.666504H12.0007Z"
                    fill="white"/>
            </svg>
        )
    }

    return (
        <Box sx={navBarStyle}>
            <Box sx={{}}>
                <StudioBackIconButton theme={LibrettoStudioTheme.Light}
                                      handleClick={handleNavigateBack}/>
            </Box>
            <Box
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                gap={1}
                flexGrow={1} // Allows it to expand and take available space
            >
                <Box sx={magicToolsButtonStyle} onClick={handleMagicToolsButtonClick}>
                    <Box sx={{marginTop: "4px"}}>
                        <MagicToolsIcon/>
                    </Box>
                    <Box sx={magicToolsTextStyle}>
                        AI Tools
                    </Box>
                </Box>
                {showMagicToolsCard && <ModernMagicToolsCard track={track} noAudioStream={noAudioStream} handleRemoveFillerWordsRequest={handleRemoveFillerWordsRequest} selectedTrackindex={selectedTrackIndex} handleClose={() => setShowMagicToolsCard(false)} handleEnhanceAudioRequest={handleEnhanceAudioRequest} leftPosition={magicToolsLeftPosition} topPosition={magicToolsTopPosition} guestEditor={false}/>}
                <Box sx={aspectRatioButtonStyle} onClick={handleClipSizeButtonClick}>
                    <Box sx={{marginTop: "4px"}}>
                        <ClipSizeIcon aspectRatioId={aspectRatioId}/>
                    </Box>
                    <Box sx={magicToolsTextStyle}>
                        {ClipSizeText(aspectRatioId)}
                    </Box>
                </Box>
            </Box>
            {showClipSizeCard &&
                <AspectRatioSelectCard guestEditor={false} setAspectRatioId={setAspectRatioId} aspectRatioId={aspectRatioId} handleClose={() => setShowClipSizeCard(false)}
                                       topPosition={clipSizeTopPosition} leftPosition={clipSizeLeftPosition} containerRef={containerRef} canvasRef={canvasRef}/>}
            <Box style={{flexDirection: "row", gap: "12px", display: "flex"}}>
                {/*<Box marginLeft={2} marginTop={0.5}>*/}
                {/*    <Avatars/>*/}
                {/*</Box>*/}
                <Box sx={exportButtonStyle} onClick={() => handleExportClick()}>
                    <Box sx={{width: "16px", height: "16px", paddingBottom: "7px"}}>
                        <GroupIcon/>
                    </Box>
                    <div style={exportTextStyle}>
                        Export
                    </div>
                </Box>
            </Box>
        </Box>
    )
}

export default NewEditorTopbar;
