import React, {useContext, useEffect, useRef, useState} from "react";
import {FetchContext} from "../../context/FetchContext";
import {AuthContext} from "../../context/AuthContext";
import {collection, doc, onSnapshot, query, updateDoc, where} from "firebase/firestore";
import {db} from "../../Firebase";
import {
    kAssetsCollectionName,
    kLibrettoUserCollectionName,
    kRoomStateCollectionName,
    LibrettoUserId
} from "../../utils/CollectionConstants";
import {
    formatToReadableLargeTitle,
    getFileTypeFromMime, IMAGE_UPLOAD,
    RefreshTokenAndRetry,
    UploadToLibretto
} from "../../utils/utils";
import {Button, Card, CardContent, ClickAwayListener, IconButton, Typography} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import {UploadIcon} from "./HomeButtons";
import NewTranscriptionLangDropdown from "../NewStudio/NewTranscriptionLangDropdown";
import TranscriptionLanguageSelectDropdown from "../Studio/TranscriptionLanguageDropdown";
import {AudioWaveformIcon} from "./ProjectThumbnail";
import mixpanel from "mixpanel-browser";

const uploadTextStyle = {
    color: '#1A1A1A',
    fontSize: '14px',
    fontFamily: 'Inter',
    fontWeight: '500',
    lineHeight: '20px',
    letterSpacing: '0.14px',
}

const ContentLanguageArea = ({selectedLanguage, updateUserLanguageCode}) => {
    return (
        <Box
            style={{
                flexDirection: "column",
                display: "flex",
                width: "592px",
                gap: "8px",
            }}
        >
            <div
                style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                }}
            >
                <div style={{ maxWidth: "300px" }}>
                    <div
                        style={{
                            fontFamily: "Inter",
                            fontSize: "18px",
                            fontWeight: 600,
                            color: "#333333",
                            marginBottom: "4px",
                        }}
                    >
                        Content Language
                    </div>
                    <div
                        style={{
                            fontFamily: "Inter",
                            fontSize: "13.5px",
                            fontWeight: 500,
                            color: "#999999",
                            lineHeight: "1.4",
                        }}
                    >
                        If you know it, set the language of speech content for better transcription.
                    </div>
                </div>
                <Box
                    style={{ paddingBottom: "10px" }}
                    className="dropdown-class"
                    onClick={(e) => e.stopPropagation()}
                >
                    <NewTranscriptionLangDropdown
                        selectedLanguage={selectedLanguage}
                        onChange={updateUserLanguageCode}
                    />
                </Box>
            </div>
        </Box>
    )
}

const FileUploadPreview = ({ file }) => {
    const [previewUrl, setPreviewUrl] = useState(null);
    const [isHovered, setIsHovered] = useState(false);
    const [aspectRatio, setAspectRatio] = useState('horizontal');

    useEffect(() => {
        if (file && file.type.startsWith('video/')) {
            const videoUrl = URL.createObjectURL(file);
            const video = document.createElement('video');
            video.src = videoUrl;
            video.onloadedmetadata = () => {
                const ratio = video.videoWidth / video.videoHeight;
                if (ratio > 1.1) setAspectRatio('horizontal');
                else if (ratio < 0.9) setAspectRatio('vertical');
                else setAspectRatio('square');

                video.currentTime = 1; // Seek to 1 second
            };
            video.onseeked = () => {
                const canvas = document.createElement('canvas');
                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;
                canvas.getContext('2d').drawImage(video, 0, 0, canvas.width, canvas.height);
                setPreviewUrl(canvas.toDataURL());
                URL.revokeObjectURL(videoUrl); // Clean up the object URL
            };
        }

        // Cleanup function
        return () => {
            if (previewUrl) {
                URL.revokeObjectURL(previewUrl);
            }
        };
    }, [file]);

    const isVideo = file && file.type.startsWith('video/');
    const isAudio = file && file.type.startsWith('audio/');
    const isImage = file && file.type.startsWith('image/');

    const uploadAreaStyleSecond = {
        alignSelf: 'stretch',
        height: '228px',
        padding: '24px',
        borderRadius: '6px',
        border: '1px #BFBFBF dotted',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '12px',
    };

    const thumbnailContainerStyle = {
        position: 'relative',
        width: '400px',
        height: '225px',
        borderRadius: '6px',
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#f0f0f0', // Light grey background for non-covered areas
    };

    const getThumbnailStyle = () => {
        const baseStyle = {
            objectFit: 'contain',
            maxWidth: '100%',
            maxHeight: '100%',
        };

        switch (aspectRatio) {
            case 'vertical':
                return { ...baseStyle, height: '100%', width: 'auto' };
            case 'square':
                return { ...baseStyle, height: '100%', width: 'auto' };
            case 'horizontal':
            default:
                return { ...baseStyle, width: '100%', height: 'auto' };
        }
    };

    const overlayStyle = {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        opacity: isHovered ? 1 : 0,
        transition: 'opacity 0.3s ease',
    };

    const titleStyle = {
        color: 'white',
        textAlign: 'center',
        padding: '10px',
    };

    return (
        <Box sx={uploadAreaStyleSecond}>
            <Box
                sx={thumbnailContainerStyle}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                {isVideo && previewUrl && (
                    <img src={previewUrl} alt="Video thumbnail" style={getThumbnailStyle()} />
                )}
                {isAudio && (
                    <AudioWaveformIcon style={getThumbnailStyle()} />
                )}
                {isImage && (
                    <img src={URL.createObjectURL(file)} alt="Image preview" style={getThumbnailStyle()} />
                )}
                <Box sx={overlayStyle}>
                    <Typography sx={titleStyle}>
                        {formatToReadableLargeTitle(file.name)}
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

const UploadContentCard = ({onClose, currentProjectId, allowImageUpload}) => {
    const fetchContext = useContext(FetchContext);
    const authContext = useContext(AuthContext);

    const userId = authContext.getUserId();

    const [uploadFileChosen, setUploadFileChosen] = useState(false);
    const [chosenFileType, setChosenFileType] = useState(null);
    const [selectedLanguage, setSelectedLanguage] = useState("auto");
    const [selectedTranscriptionMode, setSelectedTranscriptionMode] = useState("word-accuracy");
    const uploadInputRef = useRef(null);

    const startUpload = async (input) => {
        uploadInputRef.current = input;
        setUploadFileChosen(true);
        const mimeType = getFileTypeFromMime(input.files[0].type);
        console.log("Mime type is: ", mimeType)
        setChosenFileType(mimeType);
    }

    useEffect(() => {

        if (!userId) {
            return;
        }

        const q = query(collection(db, kLibrettoUserCollectionName), where(LibrettoUserId, "==", userId));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const newUserState = [];
            querySnapshot.forEach((doc) => {
                newUserState.push(doc.data());
            });
            if (newUserState.length > 0) {
                setSelectedLanguage(newUserState[0].languageCode ? newUserState[0].languageCode : "en");
                setSelectedTranscriptionMode(newUserState[0].transcriptionMode ? newUserState[0].transcriptionMode : "word-accuracy");
            }
        });

        return () => unsubscribe();
    }, [userId, setSelectedLanguage]);

    const setProgress = async ({assetId, uploadProgress}) => {
        const entityRef = doc(db, kAssetsCollectionName, assetId);
        try {
            await updateDoc(entityRef, {uploadProgress: uploadProgress});
        } catch (e) {
            console.error('Error updating upload progress for asset: ', e)
        }
    }

    const headerStyle = {
        alignSelf: 'stretch',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
    }

    const headerTextStyle = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        gap: '8px',
    }

    const titleStyle = {
        color: '#1A1A1A',
        fontSize: '18px',
        fontFamily: 'Inter',
        fontWeight: '500',
        lineHeight: '24px',
        letterSpacing: '0.18px',
    }

    const subtitleStyle = {
        width: '508px',
        color: '#999999',
        fontSize: '14px',
        fontFamily: 'Inter',
        fontWeight: '500',
        lineHeight: '20px',
        letterSpacing: '0.14px',
    }

    const uploadAreaStyleSecond = {
        alignSelf: 'stretch',
        height: '228px',
        padding: '24px',
        borderRadius: '6px',
        border: '1px #BFBFBF dotted',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '12px',
    }

    const uploadButtonStyle = {
        alignSelf: 'stretch',
        padding: '18px 20px',
        background: '#2B6BFD',
        borderRadius: '8px',
        marginTop: "-8px",
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '8px',
        cursor: 'pointer',
        // Set hovered state
        "&:hover": {
            background: "linear-gradient(0deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%), #2B6BFD",
        },
        // Set focused state
        "&:focus": {
            background: "linear-gradient(0deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.15) 100%), #2B6BFD"
        },
    }

    const uploadButtonTextStyle = {
        color: 'white',
        fontSize: '14px',
        fontFamily: 'Inter',
        fontWeight: '500',
        lineHeight: '20px',
        letterSpacing: '0.14px',
    }

    const handleUpload = async () => {
        onClose();

        try {
            const assetId = await UploadToLibretto({
                inputRef: uploadInputRef.current,
                setProgress: setProgress,
                filetype: getFileTypeFromMime(uploadInputRef.current.files[0].type),
                projectId: currentProjectId,
                editId: "",
                languageCode: selectedLanguage,
                transcriptionMode: selectedTranscriptionMode,
                fetchContext,
                authContext
            });
            mixpanel.track('Uploaded asset', {
                'filetype': getFileTypeFromMime(uploadInputRef.current.files[0].type),
                'filesize': uploadInputRef.current.files[0].size,
                'assetId': assetId,
                'languageCode': selectedLanguage,
                'transcriptionMode': selectedTranscriptionMode,
            });
        } catch (e) {
            console.log("Error is: ", e)
            if (e.response && e.response.status === 401) {
                await RefreshTokenAndRetry(e, authContext, fetchContext);
            }
        }
    }

    const initialCardStyle = {
        position: 'fixed',
        top: 'calc(50% - 200px)',
        left: 'calc(50% - 240px)',
        display: 'flex',
        width: '640px',
        padding: '24px 24px 40px 24px',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '32px',
        borderRadius: '12px',
        zIndex: 1000,
        background: "#FFFFFF",
    }

    const headerAreaStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        alignSelf: 'stretch',
    }

    const headerTextAreaStyle = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        gap: '8px',
    }

    const headerText = {
        fontFamily: 'Inter',
        fontSize: 18,
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '24px',
        letterSpacing: '0.18px',
    }

    const baseTextStyle = {
        fontFamily: 'Inter',
        fontSize: 14,
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '20px',
        letterSpacing: '0.14px',
    }

    const subtextStyle = {
        ...baseTextStyle,
        color: '#999',
    }

    const innerTextStyle = {
        ...baseTextStyle,
        color: '#1a1a1a',
    }

    const uploadAreaStyle = {
        display: 'flex',
        height: "228px",
        padding: "24px",
        justifyContent: 'center',
        alignItems: 'center',
        gap: "12px",
        alignSelf: 'stretch',
        borderRadius: "6px",
        border: "1px dashed #BFBFBF",
        cursor: "pointer",
        // Hover styles
        ':hover': {
            backgroundColor: "#F5F5F5",
            borderColor: "#999999",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)"
        }
    }

    const iconStyle = {
        display: 'flex',
        width: "20px",
        height: "20px",
        justifyContent: 'center',
        alignItems: 'center',
    }

    const updateUserLanguageCode = async (languageCode) => {

        const entityRef = doc(db, kLibrettoUserCollectionName, userId);
        try {
            await updateDoc(entityRef, {languageCode: languageCode});
        } catch (error) {
            console.error('Error updating recording type for room: ', error);
        }
    }

    const acceptStr = allowImageUpload ? "video/*, audio/*, image/*" : "video/*, audio/*";

    if (uploadFileChosen) {
        return (
                <Box sx={initialCardStyle}>
                    <Box sx={headerStyle}>
                        <Box sx={headerTextStyle}>
                            <Box sx={titleStyle}>Upload File</Box>
                            <Box sx={subtitleStyle}>Upload your content to edit on Libretto</Box>
                        </Box>
                        <Box sx={{cursor: "pointer"}} onClick={onClose}>
                            <CloseIcon/>
                        </Box>
                    </Box>
                    <FileUploadPreview file={uploadInputRef.current.files[0]}/>
                    {chosenFileType !== IMAGE_UPLOAD && <ContentLanguageArea selectedLanguage={selectedLanguage} updateUserLanguageCode={updateUserLanguageCode}/>}
                    <Box sx={uploadButtonStyle} onClick={handleUpload}>
                        <Box sx={uploadButtonTextStyle}>Upload</Box>
                    </Box>
                </Box>
        );
    }


    return (
            <Box sx={initialCardStyle}>
                <Box sx={headerAreaStyle}>
                    <Box sx={headerTextAreaStyle}>
                        <Box sx={headerText}>
                            Upload File
                        </Box>
                        <Box sx={subtextStyle}>
                            Upload your content to edit on Libretto.
                        </Box>
                    </Box>
                    <Box sx={{cursor: "pointer"}} onClick={onClose}>
                        <CloseIcon/>
                    </Box>
                </Box>
                <Box sx={uploadAreaStyle} htmlFor="media-upload" component="label">
                    <Box style={iconStyle}>
                        <UploadIcon/>
                    </Box>
                    <Box sx={innerTextStyle}>
                        {allowImageUpload ? "Upload video, audio, or image" : "Upload video or audio"}
                    </Box>
                </Box>
                <input hidden accept={acceptStr} type="file" id="media-upload"
                       onChange={(e) => startUpload(e.target)}/>
            </Box>
    );
};

export default UploadContentCard;
