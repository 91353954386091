import {createBrowserRouter, RouterProvider, Navigate} from "react-router-dom";
import React, {useContext} from 'react';
import {AuthContext, AuthProvider} from "./context/AuthContext";
import {FetchProvider} from "./context/FetchContext";
import Home from "./pages/Home/Home";
import Asset from "./pages/Asset/Asset";
import Page from "./components/Page";
import StudioAndLobby from "./pages/Studio/StudioAndLobby";
import GuestStudioAndLobby from "./pages/GuestStudio/GuestStudioAndLobby";
import LandingPage from "./pages/Landing/Landing";
import Settings from "./pages/Settings/Settings";
import PrivacyPolicy from "./pages/Landing/PrivacyPolicy";
import TermsOfService from "./pages/Landing/Terms";
import {NewPricingpage, Pricing} from "./pages/Pricing/Pricing";
import SharedProject from "./pages/SharedProject/SharedProject";
import SharedAsset from "./pages/SharedAsset/SharedAsset";
import {LibrettoLiveblocksProvider} from "./pages/Editor/LibrettoLiveblocksContext";
import EditorWrapper from "./pages/Editor/Editor";
import GuestEditorWrapper from "./pages/GuestEditor/GuestEditor";
import NewHome, {SharedHomePage} from "./pages/NewHome/NewHome";
import NewStudioAndLobby from "./pages/NewStudio/NewStudioAndLobby";
import NewSettings from "./pages/NewSettings/NewSettings";
import NewGuestStudioAndLobby from "./pages/NewGuestStudio/NewGuestStudioAndLobby";
import NewAsset, {NewSharedAsset} from "./pages/NewAsset/NewAsset";
import NewLandingPage from "./pages/NewLanding/LandingPage";
import ModernEditorWrapper from "./pages/ModernEditor/ModernEditor";
import RecordScreen from "./pages/Screen/Screen";

const AuthenticatedRoute = ({children, path}) => {
  const auth = useContext(AuthContext);

  if (auth.isUserAuthenticated()) {

    if (path === "settings") {
      return <NewSettings/>
    }

    if (!auth.isUserSubscriptionActive()) {
      return <Navigate to="/settings" replace={true}/>
    }

    return children;
  }
  return (
      <Navigate to="/" replace={true}/>
  );
};

if (typeof process === 'undefined') {
  global.process = {
    env: { NODE_ENV: 'development' }
    // You can add more properties to this object if needed
  };
}
const UnAuthenticatedRoute = ({children}) => {
  const auth = useContext(AuthContext);

  if (!auth.isUserAuthenticated()) {
    return children;
  }
  return (
      <Navigate to="/dashboard"/>
  );
}

const router = createBrowserRouter([
  // {path: "/", element: <UnAuthenticatedRoute><LandingPage/></UnAuthenticatedRoute>},
  // {path: "/dashboard", element: <AuthenticatedRoute><Home/></AuthenticatedRoute>},
  {path: "/editor/:assetId/:editId", element: <AuthenticatedRoute><EditorWrapper/></AuthenticatedRoute>}, // S
  {path: "/editor/:assetId/:editId/:token", element: <UnAuthenticatedRoute><GuestEditorWrapper/></UnAuthenticatedRoute>}, // S
  // {path: "/assets/:assetId", element: <AuthenticatedRoute><Asset/></AuthenticatedRoute>},
  // {path: "/guest/:roomName/:userFriendlyRoomName", element: <GuestStudioAndLobby/>},
  {path: "/welcome", element: <UnAuthenticatedRoute><Pricing/></UnAuthenticatedRoute>},
  {path: "/privacy", element: <PrivacyPolicy/>},
  // {path: "/settings", element: <AuthenticatedRoute><Settings/></AuthenticatedRoute>},
  {path: "/terms", element: <TermsOfService/>},
  {path: "/debugstudio/:projectId", element: <AuthenticatedRoute><StudioAndLobby/></AuthenticatedRoute>}, // R
  // {path: "/shared/assets/:assetId/:token", element: <SharedAsset/>},
  // {path: "/shared/projects/:projectId/:token", element: <SharedProject/>},


  // {path: "/screenrecord", element: <AuthenticatedRoute><ScreenCaptureWithWebcam/></AuthenticatedRoute>},
  {path: "/screen/:projectId", element: <AuthenticatedRoute><RecordScreen/></AuthenticatedRoute>},
  {path: "/", element: <UnAuthenticatedRoute><NewLandingPage/></UnAuthenticatedRoute>},
  {path: "/neweditor/:assetId/:editId", element: <AuthenticatedRoute><ModernEditorWrapper/></AuthenticatedRoute>},
  {path: "/edit/:editId", element: <AuthenticatedRoute><ModernEditorWrapper/></AuthenticatedRoute>},
  {path: "/pricing", element: <UnAuthenticatedRoute><NewPricingpage/></UnAuthenticatedRoute>},
  {path: "/guest/:roomName/:userFriendlyRoomName", element: <NewGuestStudioAndLobby/>},
  {path: "/dashboard", element: <AuthenticatedRoute><NewHome/></AuthenticatedRoute>},
  {path: "/studio/:projectId", element: <AuthenticatedRoute><NewStudioAndLobby/></AuthenticatedRoute>},
  {path: "/settings", element: <AuthenticatedRoute path={"settings"}><NewSettings/></AuthenticatedRoute>},
  {path: "/shared/projects/:projectId/:token", element: <SharedHomePage/>},
  {path: "/assets/:assetId", element: <AuthenticatedRoute><NewAsset/></AuthenticatedRoute>},
  {path: "/shared/assets/:assetId/:token", element: <NewSharedAsset/>},
]);


const App = () => {
  return (
      <Page>
        <FetchProvider>
          <AuthProvider>
            <LibrettoLiveblocksProvider>
              <RouterProvider router={router}/>
            </LibrettoLiveblocksProvider>
          </AuthProvider>
        </FetchProvider>
      </Page>
  );
}

export default App;
