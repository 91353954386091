import React, {useContext} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import {alpha, useTheme} from '@mui/material/styles';

import Container from '../../../components/Container';
import {VideoPlayer} from '../../../components/VideoPlayer';
import {LibrettoPlan} from "../../../utils/utils";
import {AuthContext} from "../../../context/AuthContext";
import HeroSectionCarousel from "./HeroSectionCarousel";

const flashingDotStyle = {
    animation: 'flashing 1s infinite',
    '@keyframes flashing': {
        '0%, 100%': {opacity: 1},
        '50%': {opacity: 0},
    },
    height: '10px',
    width: '10px',
    backgroundColor: 'red',
    borderRadius: '50%',
    display: 'inline-block',
};

const niceTextStyle = {
    background: 'linear-gradient(135deg, #2b6bfd, #e0eaff)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontSize: '72px',
    fontWeight: '800'
};

export const ModernHero = () => {

    const theme = useTheme();

    const isMd = useMediaQuery(theme.breakpoints.up('md'));

    const authContext = useContext(AuthContext);
    const handleSignupFormOpen = authContext.handleSignupFormOpen;

    const handleGetStartedButtonClick = () => {
        handleSignupFormOpen({LibrettoPlan: LibrettoPlan.StarterPaidAnnual, Code: ""})
    }

    return (
        <Container>
            <Box style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                <Box sx={{fontSize: "75px", fontWeight: "800"}}>
                    Recording and Editing
                </Box>
                <Box sx={niceTextStyle}>
                    Made Simple
                </Box>
                <Box sx={{fontSize: "22px", marginTop: "15px", marginBottom: "20px", fontWeight: "600", color: "#6f6e77"}}>
                    Libretto empowers you to record at high quality, edit effortlessly, and share assets easily.
                </Box>
                <Box
                    display="flex"
                    flexDirection={{xs: 'column', sm: 'row'}}
                    alignItems={{xs: 'stretched', sm: 'flex-start'}}
                    gap={"30px"}
                >
                    <Button sx={{
                        height: '52px',
                        width: isMd ? '180px' : '0px',
                        display: isMd ? 'flex' : 'none',
                        borderRadius: '15px',
                    }}
                            variant="outlined"
                    >
                       <Typography fontWeight={500} fontSize={"20px"}>Try it now</Typography>
                    </Button>
                    <Box
                        component="div"
                        sx={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: '#2B6BFD',
                            color: 'white',
                            padding: '8px 16px',
                            borderRadius: '15px',
                            cursor: 'pointer',
                            transition: 'background-color 0.3s',
                            '&:hover': {
                                background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.15)0%, rgba(0, 0, 0, 0.15)100%), #2B6BFD;',
                            },
                            height: '52px',
                            width: isMd ? '180px' : '100%',
                        }}
                        onClick={handleGetStartedButtonClick}
                    >
                        <Typography fontWeight={500} fontSize={"20px"}>Sign up</Typography>
                    </Box>
                </Box>
                <Box>
                    <HeroSectionCarousel/>
                </Box>
            </Box>
        </Container>
    )

}

export const NewHero = () => {
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const text = 'Recording and editing made simple.';

    const scrollToPricing = () => {
        const section = document.getElementById('pricing-section');
        if (section) {
            section.scrollIntoView({behavior: 'smooth'});
        }
    };

    const authContext = useContext(AuthContext);
    const handleSignupFormOpen = authContext.handleSignupFormOpen;

    const handleGetStartedButtonClick = () => {
        handleSignupFormOpen({LibrettoPlan: LibrettoPlan.StarterPaidAnnual, Code: ""})
    }

    return (
        <Box
            sx={{
                backgroundImage: `linear-gradient(to bottom, ${alpha(
                    theme.palette.background.paper,
                    0,
                )}, ${alpha(theme.palette.alternate.main, 1)} 100%)`,
                backgroundRepeat: 'repeat-x',
                position: 'relative',
            }}
        >
            <Box paddingY={{ xs: 0, sm: '4rem', md: '3rem' }}>
                <Container>
                    <Box maxWidth={{ xs: 1, sm: '50%' }}>
                        <Typography
                            variant="h2"
                            color="text.primary"
                            sx={{
                                fontWeight: 700,
                                minHeight: '2.5em', // Adjust as needed
                                fontSize: isMobile ? '1.7rem' : 'inherit', // Smaller font size on mobile
                            }}
                        >
                            {text}{' '}
                            {/*<Typography*/}
                            {/*    color={'#2B6BFD'}*/}
                            {/*    component={'span'}*/}
                            {/*    variant={'inherit'}*/}
                            {/*>*/}
                            {/*    podcasts.*/}
                            {/*</Typography>*/}
                        </Typography>
                        <Typography
                            variant="h6"
                            component="p"
                            color="text.secondary"
                            sx={{ fontWeight: 400 }}
                        >
                            High-quality remote and screen recording, and an intuitive transcript-based editor, making your content production easier.
                        </Typography>
                        <Box
                            display="flex"
                            flexDirection={{ xs: 'column', sm: 'row' }}
                            alignItems={{ xs: 'stretched', sm: 'flex-start' }}
                            marginTop={2}
                        >
                            <Box
                                component="div"
                                sx={{
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: '#2B6BFD',
                                    color: 'white',
                                    padding: '8px 16px',
                                    borderRadius: '4px',
                                    cursor: 'pointer',
                                    transition: 'background-color 0.3s',
                                    '&:hover': {
                                        background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.15)0%, rgba(0, 0, 0, 0.15)100%), #2B6BFD;',
                                    },
                                    height: '45px',
                                    width: isMd ? '135px' : '100%',
                                }}
                                onClick={handleGetStartedButtonClick}
                            >
                                <Typography fontWeight={600}>Get Started</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Container>
                <Box
                    sx={{
                        // transform: 'rotate(-20deg)',
                        display: { xs: 'none', sm: 'block' },
                    }}
                >
                    <Box
                        display={'flex'}
                        width={'50rem'}
                        left={'50%'}
                        top={'5%'}
                        position={'absolute'}
                    >
                        <VideoPlayer src={"https://stream.mux.com/Ni6Vo3onPWVa6JJsi9RsV402iXN4ga8mPgSQuDwZUEN00.m3u8"} poster={"https://image.mux.com/Ni6Vo3onPWVa6JJsi9RsV402iXN4ga8mPgSQuDwZUEN00/thumbnail.png?time=0&width=728&height=410"} width={728} height={410} autoplay={true} controls={false} loop={true} muted={true}/>
                        <Box
                            sx={{
                                position: 'absolute',
                                bottom: '-30px', // Adjust based on your layout
                                left: '50%',
                                transform: 'translateX(-50%)',
                                display: 'flex',
                                alignItems: 'center',
                                color: 'red',
                                fontWeight: 'bold',
                            }}
                        >
                            <Box sx={flashingDotStyle}></Box>
                            <Typography variant="h6" sx={{ marginLeft: '8px'}}>
                                REC
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box
                component={'svg'}
                preserveAspectRatio="none"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 1920 100.1"
                sx={{
                    width: '100%',
                    marginBottom: theme.spacing(-4),
                }}
            >
                <path
                    fill={theme.palette.background.paper}
                    d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
                ></path>
            </Box>
        </Box>
    );
};

const Hero = () => {
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const text = 'Record and produce great';

    const scrollToPricing = () => {
        const section = document.getElementById('pricing-section');
        if (section) {
            section.scrollIntoView({behavior: 'smooth'});
        }
    };

    const authContext = useContext(AuthContext);
    const handleSignupFormOpen = authContext.handleSignupFormOpen;

    const handleGetStartedButtonClick = () => {
        handleSignupFormOpen({LibrettoPlan: LibrettoPlan.StarterPaidAnnual, Code: ""})
    }

    return (
        <Box
            sx={{
                backgroundImage: `linear-gradient(to bottom, ${alpha(
                    theme.palette.background.paper,
                    0,
                )}, ${alpha(theme.palette.alternate.main, 1)} 100%)`,
                backgroundRepeat: 'repeat-x',
                position: 'relative',
            }}
        >
            <Box paddingY={{xs: 0, sm: '4rem', md: '3rem'}}>
                <Container>
                    <Box maxWidth={{xs: 1, sm: '50%'}}>
                        <Typography
                            variant="h2"
                            color="text.primary"
                            sx={{
                                fontWeight: 700,
                                minHeight: '2.5em', // Adjust as needed
                                fontSize: isMobile ? '1.7rem' : 'inherit', // Smaller font size on mobile
                            }}
                        >
                            {text}{' '}
                            <Typography
                                color={'primary'}
                                component={'span'}
                                variant={'inherit'}
                            >
                                podcasts.
                            </Typography>
                        </Typography>
                        <Typography
                            variant="h6"
                            component="p"
                            color="text.secondary"
                            sx={{ fontWeight: 400 }}
                        >
                            Libretto makes podcasting and video creation simple with powerful tools for recording and editing.
                        </Typography>
                        <Box
                            display="flex"
                            flexDirection={{ xs: 'column', sm: 'row' }}
                            alignItems={{ xs: 'stretched', sm: 'flex-start' }}
                            marginTop={2}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                size="large"
                                fullWidth={isMd ? false : true}
                                onClick={handleGetStartedButtonClick}
                            >
                                <Typography fontWeight={600}>Get Started</Typography>
                            </Button>
                        </Box>
                    </Box>
                </Container>
                <Box
                    sx={{
                        // transform: 'rotate(-20deg)',
                        display: {xs: 'none', sm: 'block'},
                    }}
                >
                    <Box
                        display={'flex'}
                        width={'50rem'}
                        left={'50%'}
                        top={'5%'}
                        position={'absolute'}
                    >
                        <VideoPlayer src={"https://stream.mux.com/Ni6Vo3onPWVa6JJsi9RsV402iXN4ga8mPgSQuDwZUEN00.m3u8"} poster={"https://image.mux.com/Ni6Vo3onPWVa6JJsi9RsV402iXN4ga8mPgSQuDwZUEN00/thumbnail.png?time=0&width=728&height=410"} width={728} height={410} autoplay={true} controls={false} loop={true} muted={true}/>
                        <Box
                            sx={{
                                position: 'absolute',
                                bottom: '-30px', // Adjust based on your layout
                                left: '50%',
                                transform: 'translateX(-50%)',
                                display: 'flex',
                                alignItems: 'center',
                                color: 'red',
                                fontWeight: 'bold',
                            }}
                        >
                            <Box sx={flashingDotStyle}></Box>
                            <Typography variant="h6" sx={{ marginLeft: '8px'}}>
                                REC
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box
                component={'svg'}
                preserveAspectRatio="none"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 1920 100.1"
                sx={{
                    width: '100%',
                    marginBottom: theme.spacing(-4),
                }}
            >
                <path
                    fill={theme.palette.background.paper}
                    d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
                ></path>
            </Box>
        </Box>
    );
};

export default Hero;
