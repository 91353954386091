import Box from "@mui/material/Box";
import Main, {NewMain} from "../Landing/Components/Main";
import React, {useContext, useState} from "react";
import {useTheme} from "@mui/material/styles";
import Container from '../../components/Container';
import {
    Button, Card, CardActions, CardContent, DialogContent,
    Typography
} from "@mui/material";
import CircleIcon from '@mui/icons-material/Circle';
import {AuthContext} from "../../context/AuthContext";
import Faq from "./FAQ";
import TextField from "@mui/material/TextField";
import {
    AdaptRecordingMode,
    KAppSumoCodeLength,
    LibrettoPlan,
    LibrettoRecordingMode,
    OppositeRecordingMode
} from "../../utils/utils";
import {doc, updateDoc} from "firebase/firestore";
import {db} from "../../Firebase";
import {kRoomStateCollectionName} from "../../utils/CollectionConstants";
import Dialog from "@mui/material/Dialog";

const AnnualPlan = "Annual";
const MonthlyPlan = "Monthly";

const FeatureLineNoIcon = ({highlightText, text, hidden}) => (
    hidden ? (<Box marginBottom={0.5}>
            <Typography component="span" fontFamily='roboto' variant="subtitle2" fontWeight={900} color="primary">
                {`   `}
            </Typography>
            <Typography component="span" marginLeft={0.5} fontFamily='roboto' variant="subtitle2" fontWeight={400}>
                {``}
            </Typography>
        </Box>) :
        (<Box marginBottom={0.5}>
            <Typography component="span" fontFamily='roboto' variant="subtitle1" fontWeight={900} color="#2B62FD">
                <CircleIcon fontSize="small"
                            style={{verticalAlign: 'middle', fontSize: '0.3rem', marginRight: '10px'}}/>
                {`   ${highlightText}`}
            </Typography>
            <Typography component="span" marginLeft={0.5} fontFamily='roboto' variant="subtitle1" fontWeight={400}>
                {text}
            </Typography>
        </Box>)
);

const NewFeatureLine = ({text}) => {
   return (
       <Box marginBottom={0.5}>
        <Typography variant={"body1"} fontWeight={600}>
            <CircleIcon fontSize="small"
                        style={{verticalAlign: 'middle', fontSize: '0.3rem', marginRight: '10px'}}/>
            {text}
        </Typography>
    </Box>)
}

const NewFeatureLineNoIcon = ({text}) => {
    return (
        <Box marginBottom={0.5}>
            <Typography variant={"body1"} fontWeight={600}>
                {`   ${text}`}
            </Typography>
        </Box>)
}

const FeatureLine = ({text, hidden}) => (
    hidden ? (<Box>
        <Typography component="span" marginLeft={0.5} fontFamily='roboto' variant="subtitle1" fontWeight={900}
                    color="#2B6BFD">
            {` `}
        </Typography>
    </Box>) : (
        <Box>
            <Typography component="span" marginLeft={0.5} fontFamily='roboto' variant="subtitle1" fontWeight={900}
                        color="#2B6BFD">
                {`   ${text}`}
            </Typography>
        </Box>
    )
);

const FeatureLineHeader = ({text, hidden}) => (
    hidden ? (<Box>
        <Typography component="span" marginLeft={0.5} fontFamily='roboto' variant="subtitle1" fontWeight={900}
                    color="primary.light">
            {` `}
        </Typography>
    </Box>) : (
        <Box>
            <Typography component="span" marginLeft={0.5} fontFamily='roboto' variant="subtitle1" fontWeight={900}
                        color="primary.light">
                {`   ${text}`}
            </Typography>
        </Box>
    )
);

const StarterPricingCard = ({planType, handleStartFreeTrial}) => {
    const theme = useTheme();

    const cardStyle = {
        display: 'flex', // Add this line to make the card a flex container
        flexDirection: 'column', // Add this line to arrange children in a column
        width: 350,
        paddingBottom: theme.spacing(4),
        borderRadius: '15px',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        height: '100%', // Ensure the card takes full height
        border: `2px solid transparent`,
    };

    const monthlyCost = planType === AnnualPlan ? "$9" : "$12";

    const handleGetStartedClick = () => {
        if (planType === AnnualPlan) {
            handleStartFreeTrial({librettoPlan: LibrettoPlan.StarterPaidAnnual, code: ""});
            return;
        }
        handleStartFreeTrial({librettoPlan: LibrettoPlan.StarterPaidMonthly, code: ""});
    }

    return (
        <Card style={cardStyle}>
            <CardContent style={{flexGrow: 1, marginBottom: '5px'}}> {/* flexGrow: 1 allows this area to expand */}
                <Typography variant="h4" fontWeight={700} marginBottom={2}>
                    Starter
                </Typography>
                <Typography fontWeight={600} variant="subtitle1" color="text.secondary">
                    Recording and editing essentials
                </Typography>
                <br/>
                <Typography fontWeight={600} color="text.primary">
                    <span style={{fontSize: '2.0rem', fontWeight: 900}}>{monthlyCost}</span>/month
                </Typography>
                <Typography
                    style={{
                        visibility: planType === AnnualPlan ? 'visible' : 'hidden',
                        marginBottom: '2',
                        height: planType === AnnualPlan ? '20px' : '20px', // Adjust this as necessary
                    }}
                    color="text.secondary"
                >
                    $108 billed annually
                </Typography>
                <br/>
                <br/>
                <NewFeatureLine text={"Remote audio & video recording"}/>
                <NewFeatureLine text={"Unlimited screen recording"}/>
                {/*<NewFeatureLine text={"Separate recording tracks"}/>*/}
                <NewFeatureLine text={"48khz 16-bit raw audio quality"}/>
                <NewFeatureLine text={"Upto 4K resolution video"}/>
                <NewFeatureLine text={"Multitrack audio & video editor"}/>
                <NewFeatureLine text={"3 hrs/month of AI audio enhance"}/>
                <NewFeatureLine text={"5 hrs/month of audio transcription"}/>
            </CardContent>
            <CardActions>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column', // Stack children vertically
                        alignItems: 'center', // Align items in the center horizontally
                        width: '100%' // Ensure it takes the full width of the parent
                    }}
                >
                    <Button variant="contained" fullWidth sx={{
                        marginRight: '10px',
                        marginLeft: '10px',
                        borderRadius: '10px',
                        marginBottom: '15px',
                        backgroundColor: "#2B6BFD",
                        "&:hover": {
                            background: "linear-gradient(0deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%), #2B6BFD",
                        },
                        // Set focused state
                        "&:focus": {
                            background: "linear-gradient(0deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.15) 100%), #2B6BFD"
                        },
                        // Set disabled state
                        "&:disabled": {
                            background: "#2B6BFD",
                            opacity: 0.3,
                            cursor: "not-allowed",
                        }
                    }} onClick={handleGetStartedClick}>
                        <Typography fontWeight={700}>Free 3 day trial</Typography>
                    </Button>
                    <Typography variant="subtitle2">No credit card required</Typography>
                </Box>
            </CardActions>
        </Card>
    );
};

const StandardPricingCard = ({planType, handleStartFreeTrial}) => {
    const theme = useTheme();

    const cardStyle = {
        display: 'flex', // Add this line to make the card a flex container
        flexDirection: 'column', // Add this line to arrange children in a column
        width: 350,
        paddingBottom: theme.spacing(4),
        borderRadius: '15px',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        height: '100%', // Ensure the card takes full height
        border: `2px solid #2B62FD`,
    };

    const monthlyCost = planType === AnnualPlan ? "$15" : "$18";

    const handleGetStartedClick = () => {
        if (planType === AnnualPlan) {
            handleStartFreeTrial({librettoPlan: LibrettoPlan.StarterPaidAnnual, code: ""});
            return;
        }
        handleStartFreeTrial({librettoPlan: LibrettoPlan.StarterPaidMonthly, code: ""});
    }

    return (
        <Card style={cardStyle}>
            <CardContent style={{flexGrow: 1, marginBottom: '5px'}}> {/* flexGrow: 1 allows this area to expand */}
                <Typography variant="h4" fontWeight={700} marginBottom={2}>
                    Standard
                </Typography>
                <Typography fontWeight={600} variant="subtitle1" color="text.secondary">
                    Complete recording and editing suite
                </Typography>
                <br/>
                <Typography fontWeight={600} color="text.primary">
                    <span style={{fontSize: '2.0rem', fontWeight: 900}}>{monthlyCost}</span>/month
                </Typography>
                <Typography
                    style={{
                        visibility: planType === AnnualPlan ? 'visible' : 'hidden',
                        marginBottom: '2',
                        height: planType === AnnualPlan ? '20px' : '20px', // Adjust this as necessary
                    }}
                    color="text.secondary"
                >
                    $180 billed annually
                </Typography>
                <br/>
                <NewFeatureLineNoIcon text={"Everything in Starter, plus:"}/>
                <NewFeatureLine text={"Shared editor access"}/>
                <NewFeatureLine text={"Collaborative editing"}/>
                <NewFeatureLine text={"Unlimited storage for recordings"}/>
                <NewFeatureLine text={"6 hrs/month of AI audio enhance"}/>
                <NewFeatureLine text={"10 hrs/month of audio transcription"}/>
                <br/>
                <FeatureLineNoIcon hidden={true}/>
            </CardContent>
            <CardActions>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column', // Stack children vertically
                        alignItems: 'center', // Align items in the center horizontally
                        width: '100%' // Ensure it takes the full width of the parent
                    }}
                >
                    <Button variant="contained" fullWidth sx={{
                        marginRight: '10px',
                        marginLeft: '10px',
                        borderRadius: '10px',
                        marginBottom: '15px',
                        backgroundColor: "#2B6BFD",
                        "&:hover": {
                            background: "linear-gradient(0deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%), #2B6BFD",
                        },
                        // Set focused state
                        "&:focus": {
                            background: "linear-gradient(0deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.15) 100%), #2B6BFD"
                        },
                        // Set disabled state
                        "&:disabled": {
                            background: "#2B6BFD",
                            opacity: 0.3,
                            cursor: "not-allowed",
                        }
                    }} onClick={handleGetStartedClick}>
                        <Typography fontWeight={700}>Free 3 day trial</Typography>
                    </Button>
                    <Typography variant="subtitle2">No credit card required</Typography>
                </Box>
            </CardActions>
        </Card>
    );
};


const CreatorPricingCard = ({planType, handleStartFreeTrial}) => {
    const theme = useTheme();

    const cardStyle = {
        width: 350,
        // margin: theme.spacing(2),
        paddingBottom: theme.spacing(4),
        borderRadius: '15px',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        border: "2px solid transparent",
    }

    const monthlyCost = planType === AnnualPlan ? "$24" : "$30";

    const handleGetStartedClick = () => {
        if (planType === AnnualPlan) {
            handleStartFreeTrial({librettoPlan: LibrettoPlan.StarterPaidAnnual, code: ""});
            return;
        }
        handleStartFreeTrial({librettoPlan: LibrettoPlan.StarterPaidMonthly, code: ""});
    }

    return (
        <Card style={cardStyle}>
            <CardContent style={{marginBottom: '5px'}}>
                <Typography variant="h4" fontWeight={700} marginBottom={2}>
                    Creator
                </Typography>
                <Typography fontWeight={600} variant="subtitle1" color="text.secondary">
                    Complete recording and editing suite
                </Typography>
                <br/>
                <Typography fontWeight={600} color="text.primary">
                    <span style={{fontSize: '2.0rem', fontWeight: 900}}>{monthlyCost}</span>/month
                </Typography>
                <Typography
                    style={{
                        visibility: planType === AnnualPlan ? 'visible' : 'hidden',
                        marginBottom: '2',
                        height: planType === AnnualPlan ? '20px' : '20px', // Adjust this as necessary
                    }}
                    color="text.secondary"
                >
                    $288 billed annually
                </Typography>
                <br/>
                <br/>
                <FeatureLine text={"Everything in Standard, plus:"}/>
                <FeatureLineNoIcon highlightText={"30 Hours"} text={"separate track recording / month"}/>
                <FeatureLineNoIcon highlightText={"30 transcription hours"} text={"per month"}/>
                <FeatureLineNoIcon highlightText={"30 hours audio enhance"} text={"credits / month"}/>
                <FeatureLineNoIcon highlightText={"Live"} text={"chat support"}/>
                <FeatureLineNoIcon hidden={true}/>
                <FeatureLineNoIcon hidden={true}/>
                <FeatureLineNoIcon hidden={true}/>
                <FeatureLineNoIcon hidden={true}/>
            </CardContent>
            <CardActions>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column', // Stack children vertically
                        alignItems: 'center', // Align items in the center horizontally
                        width: '100%' // Ensure it takes the full width of the parent
                    }}
                >
                    <Button variant="contained" fullWidth sx={{
                        marginRight: '10px',
                        marginLeft: '10px',
                        borderRadius: '10px',
                        marginBottom: '15px',
                        backgroundColor: "#2B6BFD",
                        "&:hover": {
                            background: "linear-gradient(0deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%), #2B6BFD",
                        },
                        // Set focused state
                        "&:focus": {
                            background: "linear-gradient(0deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.15) 100%), #2B6BFD"
                        },
                        // Set disabled state
                        "&:disabled": {
                            background: "#2B6BFD",
                            opacity: 0.3,
                            cursor: "not-allowed",
                        }
                    }} onClick={handleGetStartedClick}>
                        <Typography fontWeight={700}>Buy now</Typography>
                    </Button>
                </Box>
            </CardActions>
        </Card>
    );
};


const AppSumoPlanTierOne = ({handleStartFreeTrial}) => {
    const theme = useTheme();

    const cardStyle = {
        display: 'flex', // Add this line to make the card a flex container
        flexDirection: 'column', // Add this line to arrange children in a column
        width: 350,
        paddingBottom: theme.spacing(4),
        borderRadius: '15px',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        height: '100%', // Ensure the card takes full height
        border: `1px solid ${theme.palette.primary.main}`,
    }

    const [appSumoCode, setAppSumoCode] = useState('');

    const handleCodeChange = (event) => {
        setAppSumoCode(event.target.value);
    }

    const handleGetStartedClick = () => {
        handleStartFreeTrial({librettoPlan: LibrettoPlan.AppSumoTier1, code: appSumoCode});
    }


    const buttonDisabled = appSumoCode.length !== KAppSumoCodeLength;

    return (
        <Card style={cardStyle}>
            <CardContent style={{marginBottom: '5px'}}>
                <Typography variant="h4" fontWeight={700} marginBottom={2}>
                    Standard Lifetime
                </Typography>
                <FeatureLineHeader text={"Record audio"}/>
                <FeatureLineNoIcon highlightText={"15 Hours"} text={"separate track recording / month"}/>
                <FeatureLineNoIcon highlightText={"Hi-Fi"} text={"48khz 16-bit raw audio recording"}/>
                <FeatureLineNoIcon highlightText={"Unlimited"} text={"single track recording"}/>
                <FeatureLineNoIcon highlightText={"Unlimited storage"} text={"for recordings"}/>
                <FeatureLineHeader text={"Edit, transcribe, caption, post-produce"}/>
                <FeatureLineNoIcon highlightText={"Collaborative audio & video editor"}/>
                <FeatureLineNoIcon highlightText={"10 hours audio enhance"} text={"credits / month"}/>
                <FeatureLineNoIcon highlightText={"15 transcription hours"} text={"/ month"}/>
                <FeatureLineNoIcon highlightText={"30GB storage"} text={"for uploads"}/>
                <FeatureLineNoIcon hidden={true}/>
                <FeatureLineNoIcon hidden={true}/>
                <FeatureLineNoIcon hidden={true}/>
                <FeatureLineNoIcon hidden={true}/>
                <FeatureLineNoIcon hidden={true}/>
            </CardContent>
            <CardActions>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column', // Stack children vertically
                        alignItems: 'center', // Align items in the center horizontally
                        width: '100%' // Ensure it takes the full width of the parent
                    }}
                >
                    <Typography variant={'subtitle2'} fontWeight={600}>
                        Enter code
                    </Typography>
                    <TextField
                        variant="outlined"
                        name={'email'}
                        fullWidth
                        value={appSumoCode}
                        onChange={handleCodeChange}
                        style={{height: '35px', marginBottom: '15px'}}
                        InputProps={{style: {height: '35px'}}}
                    />
                    <Button variant="contained" disabled={buttonDisabled} fullWidth sx={{
                        marginRight: '10px',
                        marginLeft: '10px',
                        borderRadius: '10px',
                        marginBottom: '15px',
                    }} onClick={handleGetStartedClick}>
                        <Typography fontWeight={700}>Create account</Typography>
                    </Button>
                </Box>
            </CardActions>
        </Card>
    );
};


const AppSumoPlanTierTwo = ({handleStartFreeTrial}) => {
    const theme = useTheme();

    const cardStyle = {
        display: 'flex', // Add this line to make the card a flex container
        flexDirection: 'column', // Add this line to arrange children in a column
        width: 350,
        paddingBottom: theme.spacing(4),
        borderRadius: '15px',
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        height: '100%', // Ensure the card takes full height
        border: `1px solid ${theme.palette.primary.main}`,
    }

    const [appSumoCode, setAppSumoCode] = useState('');
    const [appSumoCodeTwo, setAppSumoCodeTwo] = useState('');

    const handleCodeChange = (event) => {
        setAppSumoCode(event.target.value);
    }

    const handleCodeChangeTwo = (event) => {
        setAppSumoCodeTwo(event.target.value);
    }

    const handleGetStartedClick = () => {
        handleStartFreeTrial({librettoPlan: LibrettoPlan.AppSumoTier2, code: appSumoCode, codeTwo: appSumoCodeTwo});
    }

    const buttonDisabled = appSumoCode.length !== KAppSumoCodeLength || appSumoCodeTwo.length !== KAppSumoCodeLength || appSumoCode === appSumoCodeTwo;

    return (
        <Card style={cardStyle}>
            <CardContent style={{marginBottom: '5px'}}>
                <Typography variant="h4" fontWeight={700} marginBottom={2}>
                    Creator Lifetime
                </Typography>
                <FeatureLineHeader text={"Record audio & video"}/>
                <FeatureLineNoIcon highlightText={"30 Hours"} text={"separate track recording / month"}/>
                <FeatureLineNoIcon highlightText={"Hi-Fi"} text={"48khz 16-bit raw audio recording"}/>
                <FeatureLineNoIcon highlightText={"High-quality"} text={"local video recording"}/>
                <FeatureLineNoIcon highlightText={"Up to 4K"} text={"resolution video"}/>
                <FeatureLineNoIcon highlightText={"Unlimited"} text={"single track recording"}/>
                <FeatureLineNoIcon highlightText={"Unlimited storage"} text={"for recordings"}/>
                <FeatureLineHeader text={"Edit, transcribe, caption, post-produce"}/>
                <FeatureLineNoIcon highlightText={"30 hours audio enhance"} text={"credits / month"}/>
                <FeatureLineNoIcon highlightText={"30 transcription hours"} text={"/ month"}/>
                <FeatureLineNoIcon highlightText={"Collaborative audio & video editor"}/>
                <FeatureLineNoIcon highlightText={"100GB storage"} text={"for uploads"}/>
                <FeatureLineNoIcon highlightText={"Live chat"} text={"support"}/>
            </CardContent>
            <CardActions>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column', // Stack children vertically
                        alignItems: 'center', // Align items in the center horizontally
                        width: '100%' // Ensure it takes the full width of the parent
                    }}
                >
                    <Typography variant={'subtitle2'} fontWeight={600}>
                        Enter codes
                    </Typography>
                    <TextField
                        variant="outlined"
                        name={'code'}
                        fullWidth
                        value={appSumoCode}
                        onChange={handleCodeChange}
                        style={{height: '35px', marginBottom: '15px'}}
                        InputProps={{style: {height: '35px'}}}
                    />
                    <TextField
                        variant="outlined"
                        name={'code'}
                        fullWidth
                        value={appSumoCodeTwo}
                        onChange={handleCodeChangeTwo}
                        style={{height: '35px', marginBottom: '15px'}}
                        InputProps={{style: {height: '35px'}}}
                    />
                    <Button variant="contained" disabled={buttonDisabled} fullWidth style={{
                        marginRight: '10px',
                        marginLeft: '10px',
                        borderRadius: '10px',
                        marginBottom: '15px'
                    }} onClick={handleGetStartedClick}>
                        <Typography fontWeight={700}>Create account</Typography>
                    </Button>
                </Box>
            </CardActions>
        </Card>
    );
};

const PricingBox = React.forwardRef(({selectedPricingOption, setSelectedPricingOption, handleSignupFormOpen}) => {

    const theme = useTheme();

    const ToggleContainerStyle = {
        display: "flex",
        backgroundColor: "#2B6BFD",
        borderRadius: "10px",
        padding: "3px",
        cursor: "pointer",
        width: "fit-content",
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    }

    const ToggleOptionStyle = {
        padding: "10px 20px",
        borderRadius: "8px",
        transition: "all 0.3s ease",
        color: "white",
    }

    const ActiveToggleOptionStyle = {
        ...ToggleOptionStyle,
        backgroundColor: "white",
        color: "#2B6BFD",
    }

    const textStyle = {
        fontFamily: "Inter, sans-serif",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "24px",
        letterSpacing: "0.15px",
    }

    const PlanTypeToggle = ({ selectedPricingOption, setSelectedPricingOption }) => {
        const handleToggleClick = (option) => {
            setSelectedPricingOption(option);
        }

        return (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '20px 0' }}>
                <Box style={ToggleContainerStyle}>
                    {[AnnualPlan, MonthlyPlan].map((option) => (
                        <Box
                            key={option}
                            onClick={() => handleToggleClick(option)}
                            sx={{
                                ...ToggleOptionStyle,
                                ...(selectedPricingOption === option ? ActiveToggleOptionStyle : {}),
                                minWidth: "130px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                '&:hover': {
                                    backgroundColor: selectedPricingOption === option ? 'white' : 'rgba(255, 255, 255, 0.1)',
                                },
                            }}
                        >
                            <Typography sx={{
                                ...textStyle,
                                color: selectedPricingOption === option ? '#2B6BFD' : 'white',
                            }}>
                                {option === AnnualPlan ? "Annual" : "Monthly"}
                            </Typography>
                        </Box>
                    ))}
                </Box>
            </Box>
        );
    };

    return (
        <Box id="pricing-section">
            <Box marginBottom={8} marginTop={9}>
                <Typography
                    variant="h3"
                    gutterBottom
                    align={'center'}
                    sx={{
                        fontWeight: 650,
                    }}
                >
                    Simple pricing
                </Typography>
                <Typography align={'center'} variant="h6" color={"textSecondary"}>
                    Flexible options to support of your creative process.
                </Typography>
            </Box>
            {PlanTypeToggle({setSelectedPricingOption, selectedPricingOption})}
            <Box sx={{
                display: 'flex',
                alignItems: 'strech',
                gap: 7,
                justifyContent: 'center',
                marginTop: '50px',
                flexDirection: {xs: 'column', sm: 'row'}
            }} marginBottom={8}>
                <StarterPricingCard planType={selectedPricingOption} handleStartFreeTrial={handleSignupFormOpen}/>
                <StandardPricingCard planType={selectedPricingOption} handleStartFreeTrial={handleSignupFormOpen}/>
            </Box>
            {/*<Faq/>*/}
        </Box>
    )
});

const LifetimePricingBox = ({handleSignupFormOpen}) => {
    const theme = useTheme();

    const [campaignOverCardOpen, setCampaignOverCardOpen] = useState(false);

    const handleCloseCampaignOverCard = () => {
        setCampaignOverCardOpen(false);
    }

    return (
        <Box sx={{
            marginTop: {xs: theme.spacing(-2), md: theme.spacing(-5)},
        }} id="pricing-section">
            <Box marginBottom={4}>
                <Typography
                    variant="h2"
                    gutterBottom
                    align={'center'}
                    sx={{
                        fontWeight: 900,
                    }}
                >
                    AppSumo offers
                </Typography>
            </Box>
            <Box sx={{
                display: 'flex',
                alignItems: 'strech',
                gap: 3,
                justifyContent: 'space-around',
                marginTop: '50px',
                flexDirection: {xs: 'column', sm: 'row'}
            }} marginBottom={8}>
                <AppSumoPlanTierOne handleStartFreeTrial={handleSignupFormOpen}/>
                <AppSumoPlanTierTwo handleStartFreeTrial={handleSignupFormOpen}/>
            </Box>
            <Dialog
                open={campaignOverCardOpen}
                onClose={handleCloseCampaignOverCard}
                aria-labelledby="form-dialog-title"
            >
                <DialogContent>
                    <Typography>Hi. Due to unforseen circumstances, we have ceased AppSumo Lifetime deals and are working to support existing lifetime deal buyers. Please obtain a refund on Appsumo if you have purchased codes. We apologize for the inconvenience.</Typography>
                </DialogContent>
            </Dialog>
            <Faq/>
        </Box>
    )
}


const LifetimeComponent = ({handleSignupFormOpen}) => {

    const theme = useTheme();

    return (
        <Box>
            <Box
                sx={{
                    position: 'relative',
                    backgroundColor: theme.palette.alternate.main,
                    backgroundImage: `linear-gradient(120deg, ${theme.palette.background.paper} 0%, ${theme.palette.alternate.dark} 100%)`,
                }}
            >
                <Container position={'relative'} zIndex={3}>
                    <LifetimePricingBox handleSignupFormOpen={handleSignupFormOpen}/>
                </Container>
            </Box>
        </Box>
    );
}


const Pricing = () => {

    const authContext = useContext(AuthContext);

    return (
        <>
            <Box sx={{overflowX: 'hidden'}}>
                <NewMain bgcolor={'background.paper'} hideSignupButton={true}>
                    <LifetimeComponent handleSignupFormOpen={authContext.handleSignupFormOpen}/>
                </NewMain>
            </Box>
        </>
    );
}

const NewPricingpage = () => {
    const authContext = useContext(AuthContext);

    const [selectedPricingOption, setSelectedPricingOption] = useState(AnnualPlan);

    return (
        <>
            <Box sx={{overflowX: 'hidden'}}>
                <NewMain bgcolor={'background.paper'} pageEnum={2} selectedPricingOption={selectedPricingOption}>
                    <PricingBox selectedPricingOption={selectedPricingOption} setSelectedPricingOption={setSelectedPricingOption} handleSignupFormOpen={authContext.handleSignupFormOpen}/>
                    <Container position={'relative'} zIndex={3}>
                        <Faq/>
                    </Container>
                </NewMain>
            </Box>
        </>
    );
}

export {Pricing, PricingBox, NewPricingpage};
