import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import {LibrettoPlan} from "../../../utils/utils";

export const NewGetStarted = ({handleSignupFormOpen}) => {
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const handleGetStartedClick = () => {
        handleSignupFormOpen({LibrettoPlan: LibrettoPlan.StarterPaidAnnual, Code: ""})
    };

    return (
        <Box>
            <Typography
                variant="h4"
                color="text.primary"
                align={'center'}
                gutterBottom
                sx={{
                    fontWeight: 700,
                }}
            >
                Get started with Libretto today
            </Typography>
            <Typography
                variant="h6"
                component="p"
                color="text.secondary"
                sx={{ fontWeight: 400 }}
                align={'center'}
            >
                Recording and editing has never been easier.
            </Typography>
            <Box
                display="flex"
                flexDirection={{ xs: 'column', sm: 'row' }}
                alignItems={{ xs: 'stretched', sm: 'flex-start' }}
                justifyContent={'center'}
                marginTop={4}
            >
                <Button
                    variant="contained"
                    sx={{ background: "#2B6BFD", minWidth: "200px" }}
                    size="large"
                    fullWidth={isMd ? false : true}
                    onClick={handleGetStartedClick}
                >
                    Get started
                </Button>
            </Box>
        </Box>
    );
};

const GetStarted = ({handleSignupFormOpen}) => {
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const handleGetStartedClick = () => {
        handleSignupFormOpen({LibrettoPlan: LibrettoPlan.StarterPaidAnnual, Code: ""})
    };

    return (
        <Box>
            <Typography
                variant="h4"
                color="text.primary"
                align={'center'}
                gutterBottom
                sx={{
                    fontWeight: 700,
                }}
            >
                Get started with Libretto today
            </Typography>
            <Typography
                variant="h6"
                component="p"
                color="text.secondary"
                sx={{ fontWeight: 400 }}
                align={'center'}
            >
                Recording and editing has never been easier.
            </Typography>
            <Box
                display="flex"
                flexDirection={{ xs: 'column', sm: 'row' }}
                alignItems={{ xs: 'stretched', sm: 'flex-start' }}
                justifyContent={'center'}
                marginTop={4}
            >
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth={isMd ? false : true}
                    onClick={handleGetStartedClick}
                >
                    Get started for free
                </Button>
            </Box>
        </Box>
    );
};

export default GetStarted;
