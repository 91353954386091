import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import Box from "@mui/material/Box";
import {useTheme} from "@mui/material/styles";
import {AuthContext} from "../../context/AuthContext";
import {useNavigate, useParams} from "react-router-dom";
import {collection, doc, onSnapshot, query, updateDoc, where} from "firebase/firestore";
import {db} from "../../Firebase";
import {
    AssetDeleted, AssetId, AssetProjectId, AssetType,
    AssetUserId, EditorStateEditId, EditorStateUserId,
    kAssetsCollectionName, kEditorStateCollectionName, kLibrettoUserCollectionName,
    kTracksCollectionName,
    TrackDeleted,
    TrackId,
    TrackUserId
} from "../../utils/CollectionConstants";
import Typography from "@mui/material/Typography";
import {FetchContext} from "../../context/FetchContext";
import {ClientSideSuspense} from "@liveblocks/react";
import {LibrettoLiveblocksContext} from "../Editor/LibrettoLiveblocksContext";
import {ClipSize16And9RatioId, loadTranscriptForTrack, RefreshTokenAndRetry} from "../../utils/utils";
import {createSaveFillerWordRemovalTrims, createUndoAllFillerWordRemovalTrims} from "../Editor/Liveblocks";
import ModernEditorInner from "./ModernEditorInner";
import NewEditorTopbar from "../NewEditor/NewEditorTopbar";
import {RendleyService} from "../../editor/services/RendleyService";
import {getCanvasElement} from "../../editor/utils/dom/getCanvasElement";
import RendleyBridge from "../../editor/RendleyBridge";
import {TITLES_PATH_CDN} from "../../editor/config/config";
import {StockMediaService} from "../../editor/services/StockMediaService";
import {Engine} from "@rendley/sdk";


const ModernEditorWrapper = () => {

    const {editId} = useParams();

    const [projectId, setProjectId] = useState(null);
    const [assetId, setAssetId] = useState(null);
    const [stateData, setStateData] = useState(null);
    const [engineReady, setEngineReady] = useState(false);

    const userId = useContext(AuthContext).getUserId();

    // Get the edit state.
    useEffect(() => {
        const q = query(collection(db, kEditorStateCollectionName),
            where(EditorStateEditId, "==", editId),
            where(EditorStateUserId, "==", userId));

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            let newProjectId = null;
            let newAssetId = null;
            let newStateData = null;
            querySnapshot.forEach((doc) => {
                newProjectId = doc.data().projectId;
                newAssetId = doc.data().assetId;
                newStateData = doc.data().data;
            });
            setProjectId(newProjectId);
            setAssetId(newAssetId);
            setStateData(newStateData);
        });

        return () => unsubscribe();
    }, [editId, userId]);

    useEffect(() => {

        const initRendley = () => {
            // if (!RendleyService.getEngine().isInitialized()) {
                Engine.getInstance().init({
                    display: {
                        width: 1920,
                        height: 1080,
                        backgroundColor: "#000000",
                        view: getCanvasElement(),
                    },
                    license: {
                        licenseName: "Libretto",
                        licenseKey: "7324FD51F1255979246B0001",
                    },
                }).then(r => {
                    if (stateData) {
                        Engine.deserialize(stateData).then(() => {
                           setEngineReady(true);
                        });
                        return;
                    }
                    console.log("Rendley initialized")
                    setEngineReady(true);
                }).catch((error) => {
                    console.error("Error initializing Rendley", error);
                })
            // }
        }

        const initStockMediaService = () => {
            StockMediaService.getInstance().init({
                pexelsApiKey: "ZPSMxFlBYLs9JQmTeFmSkeXDFf9VjJqHSAJCk6y5RyDFGmXV9edrThpl",
                giphyApiKey: "cGA8JsUtlRseBgqdBYSCCsFxgLYlm3tu",
            });
        }

        const initRendleyService = () => {
            RendleyBridge.init({
                filtersPath: "",
                effectsPath: "",
                transitionsPath: "",
                titlesPath: TITLES_PATH_CDN,
            }).then(() => {
                console.log("Rendley service initialized");
            });
        }

        initRendley();
        initRendleyService();
        initStockMediaService();
    }, [stateData]);



    return (
        <Box>
            <ModernEditor projectId={projectId} assetId={assetId} editId={editId}/>
        </Box>
    )
}

const ModernEditor = ({assetId, editId, projectId}) => {
    const authContext = useContext(AuthContext);
    const fetchContext = useContext(FetchContext);

    const navigate = useNavigate();

    const userId = authContext.getUserId();
    const [tracks, setTracks] = useState(new Map());
    const [tracksLoaded, setTracksLoaded] = useState(false);

    const [selectedTrackIndex, setSelectedTrackIndex] = useState(0);

    const [waveDataForTracks, setWaveDataForTracks] = useState(new Map());
    const [enhancedWavedataForTracks, setEnhancedWavedataForTracks] = useState(new Map());
    const [transcripts, setTranscripts] = useState(new Map());
    const [transcriptsLoaded, setTranscriptsLoaded] = useState(false);
    const [captionsEnabled, setCaptionsEnabled] = useState(false);
    const [videoPlayerVisible, setVideoPlayerVisible] = useState(true);

    const editorRef = useRef(null);
    const videoplayerRef = useRef(null);
    const compositionContainerRef = useRef(null);
    const canvasRef = useRef(null);
    const timelineEditorRef = useRef(null);

    const liveblocksContext = useContext(LibrettoLiveblocksContext);

    const orderOfTracks = [];
    const trims = new Map();
    const fillerWordRemovalTrims = new Map();
    const corrections = new Map();
    const editSettings = new Map();

    const [trackIdSet, setTrackIdSet] = useState(() => new Set(orderOfTracks));
    const [waveformRefs, setWaveformRefs] = useState(() => orderOfTracks.map(() => React.createRef()));

    const [clipSize, setClipSize] = useState(() => {
        const initialSettings = editSettings.get("editSettings");
        return initialSettings && initialSettings.videoAspectRatio ? initialSettings.videoAspectRatio : ClipSize16And9RatioId;
    });

    useEffect(() => {
        if (editSettings && editSettings.get("editSettings")) {
            setClipSize(editSettings.get("editSettings").videoAspectRatio);
        }
    }, [editSettings]);


    const handleVideoPlayerPlayToggle = (time, play) => {

    }

    const getTrackForSelectedIndex = () => {
        return tracks.get(orderOfTracks[selectedTrackIndex]);
    }

    // Load the tracks.
    useEffect(() => {
        if (!userId) {
            return;
        }

        if (orderOfTracks === undefined || orderOfTracks === null) {
            return;
        }
        if (orderOfTracks.length === 0) {
            setTracksLoaded(true);
            return;
        }

        const filterList = orderOfTracks && orderOfTracks.length > 0 ? orderOfTracks : [];

        const q = query(collection(db, kTracksCollectionName),
            where(TrackId, "in", filterList),
            where(TrackDeleted, "==", false),
            where(TrackUserId, "==", userId));

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            let newTracks = [];
            querySnapshot.forEach((doc) => {
                newTracks.push(doc.data());
            });
            // Set the tracks to the tracks state map keyed by trackId
            setTracks(new Map(newTracks.map(track => [track.trackId, track])));
            setTracksLoaded(true);
        });

        return () => unsubscribe();
    }, [orderOfTracks, userId]);


    useEffect(() => {
        const loadAllTranscripts = async () => {
            const trackIdsArray = Array.from(trackIdSet);
            const transcriptPromises = trackIdsArray.map(trackId => loadTranscriptForTrack({
                trackId,
                authContext,
                fetchContext
            }));

            try {
                const transcriptsData = await Promise.all(transcriptPromises);

                const newTranscripts = new Map();
                trackIdsArray.forEach((trackId, index) => {
                    newTranscripts.set(trackId, transcriptsData[index]);
                });

                setTranscripts(newTranscripts);
                setTranscriptsLoaded(true);
            } catch (error) {
                console.error("Error loading transcripts:", error);
            }
        };

        if (trackIdSet.size > 0) {
            loadAllTranscripts();
        }
    }, [trackIdSet]);

    const saveAllFillerWordTrims = () => {
    }

    const undoAllFillerWordTrims = () => {
    }


    const handleRemoveFillerWordsRequest = async (removeFillerWords) => {
        if (removeFillerWords) {
            if (editorRef.current) {
                const fillerWordTrims = editorRef.current.getFillerWordTrims();
                saveAllFillerWordTrims(fillerWordTrims);
            }
        } else {
            undoAllFillerWordTrims(orderOfTracks.length);
        }
    }

    const pageStyle = {
        display: 'flex',
        flexDirection: 'column',
        background: "#F3F4F5",
        paddingTop: "20px",
        paddingLeft: "32px",
        paddingRight: "32px",
        height: "100vh",
        width: "100vw",
        gap: "28px",
        overflow: 'hidden',
    }

    const handleNavigateBack = () => {
        if (assetId) {
            navigate(`/assets/${assetId}`);
            window.location.reload();
        } else {
            navigate(`/dashboard`);
            window.location.reload();
        }
    }

    return (
        <Box sx={pageStyle}>
            <NewEditorTopbar selectedTrackIndex={selectedTrackIndex} containerRef={compositionContainerRef} editId={editId}
                             canvasRef={canvasRef} handleNavigateBack={handleNavigateBack} track={getTrackForSelectedIndex()} noAudioStream={true}
                             handleRemoveFillerWordsRequest={handleRemoveFillerWordsRequest}/>

            <ModernEditorInner tracks={tracks} transcriptsLoaded={transcriptsLoaded}
                               compositionContainerRef={compositionContainerRef}
                               canvasRef={canvasRef}
                               projectId={projectId}
                               tracksLoaded={tracksLoaded} transcripts={transcripts}
                               waveDataForTracks={waveDataForTracks}
                               enhancedWavedataForTracks={enhancedWavedataForTracks}
                               trims={trims}
                               fillerWordRemovalTrims={fillerWordRemovalTrims}
                               corrections={corrections}
                               handleSelect={() => console.log("Selected")} editorRef={editorRef}
                               timelineEditorRef={timelineEditorRef}
                               captionsEnabled={captionsEnabled} videoplayerRef={videoplayerRef}
                               videoPlayerVisible={videoPlayerVisible}
                               clipSize={clipSize}
                               waveformRefs={waveformRefs}
                               selectedTrackIndex={selectedTrackIndex}
                               setSelectedTrackIndex={setSelectedTrackIndex}
                               orderOfTracks={orderOfTracks}
                               handleVideoPlayerPlayToggle={handleVideoPlayerPlayToggle} assetId={assetId}
                               loadTracks={() => {
                               }}/>
        </Box>
    )
}

export default ModernEditorWrapper;

