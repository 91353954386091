import React, {useState, useEffect, useRef, useCallback, useContext} from 'react';
import {observer} from 'mobx-react-lite';
import {Engine} from "@rendley/sdk";
import {autorun, IReactionDisposer} from "mobx";
import {RendleyService} from "../../../../services/RendleyService";
import {MediaData, MediaDataStatusEnum, RendleyStore} from "../../../../store/RendleyStore";
// @ts-ignore
import './MediaPanelContainer.styles.scss';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
    determineTrackThumbnailUrl, EditorMediaTypeEnums,
    formatToReadableShortTitle,
    formatUnixTime, IsUploadedTrackType, IsVideoTrackType,
    RefreshTokenAndRetry
} from "../../../../../utils/utils";
import {ButtonGroup, Button, Card, CardActionArea, CardContent, Grid, DialogContent} from "@mui/material";
import {FetchContext} from "../../../../../context/FetchContext";
import {AuthContext} from "../../../../../context/AuthContext";
import EditorMediaThumbnail from "../../../../../pages/ModernEditor/EditorThumbnail";
import {ApplicationStore} from "../../../../store/ApplicationStore";
import {useConfirmDialog} from "../../../../../pages/NewHome/DeleteConfirmation";
import {SortButton} from "../../../../../pages/NewHome/HomeButtons";

interface MediaPanelContainerProps {
    onClose?: () => void;
    projectTracks: any;
    projectImages: any;
    selectedTab: number;
    setSelectedTab: (tab: number) => void;
    // @ts-ignore
}

const MediaPanelContainer: React.FC<MediaPanelContainerProps> = observer(({projectTracks, projectImages, selectedTab, setSelectedTab}) => {
    // const [data, setData] = useState<MediaData[]>([]);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const fetchContext = useContext(FetchContext);
    const authContext = useContext(AuthContext);

    const [sortedTracks, setSortedTracks] = useState<[] | null>(null);
    const [sortNewest, setSortNewest] = useState(true);

    const {openDialog, ConfirmDialogComponent} = useConfirmDialog({confirmText: 'Delete', cancelText: 'Cancel'});

    const deleteTrack = async ({trackId}) => {
        fetchContext.authAxios.post(`/tracks/delete`, {
            trackIds: [trackId],
        }, {
            headers: {
                Authorization: `Bearer ${authContext.getToken()}`,
            }
        }).then(() => {
        }).catch((err) => {
            if (err.response.status === 401) {
                RefreshTokenAndRetry(err, authContext, fetchContext);
            }
        })
    }

    const deleteAsset = async ({assetId}) => {
        fetchContext.authAxios.delete(`/assets/${assetId}`, {
            headers: {
                Authorization: `Bearer ${authContext.getToken()}`,
            }
        }).then(() => {

        }).catch((err) => {
            if (err.response.status === 401) {
                RefreshTokenAndRetry(err, authContext, fetchContext);
            }
        })
    };

    const handleDeleteClick = ({track, isImage}) => {
        console.log("open dialog")
        openDialog({
            title: 'Delete this ' + (isImage ? 'image' : 'track') + '?',
            message: 'It will be permanently deleted.',
            onConfirm: async () => {
                if (isImage) {
                    await deleteAsset({assetId: track.assetId});
                } else {
                    await deleteTrack({trackId: track.trackId});
                }
            },
        });
    }

    useEffect(() => {
        const assetsToSort = selectedTab === EditorMediaTypeEnums.IMAGE ? projectImages : projectTracks;

        if (!assetsToSort) return;

        const sorted = [...assetsToSort].sort((a, b) => {
            // @ts-ignore
            return sortNewest ? b.createTime - a.createTime : a.createTime - b.createTime;
        });
        // @ts-ignore
        setSortedTracks(sorted);
    }, [projectTracks, sortNewest, projectImages, selectedTab]);

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue);
    };

    const handleOpenFileViewer = useCallback((mediaId?: string) => {
        if (fileInputRef.current) {
            fileInputRef.current.dataset.mediaId = mediaId || '';
            fileInputRef.current.value = '';
            fileInputRef.current.click();
        }
    }, []);

    const handleUpload = useCallback(async (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (!files) return;

        const library = Engine.getInstance().getLibrary();
        const promises: Promise<string | null>[] = Array.from(files).map((file) =>
            library.addMedia(file)
        );

        try {
            await Promise.all(promises);
        } catch (e) {
            console.log(e);
        }
    }, []);

    const handleReplaceFile = useCallback(async (e: React.ChangeEvent<HTMLInputElement>) => {
        const mediaId = e.target.dataset.mediaId;
        const file = e.target.files?.[0];

        if (!mediaId || !file) return;

        RendleyService.replaceMedia(mediaId, file);
    }, []);

    const handleClickMediaThumbnail = useCallback(async ({track, isImage}) => {
        try {
            const objectUrl = isImage ? track.thumbnailUrl : track.renditionUrl;
            const mediaDataId = await RendleyService.addMediaToGallery(objectUrl);

            if (mediaDataId == null) return;

            RendleyService.getMediaById(mediaDataId)?.setPermanentUrl(objectUrl);

            const layer = RendleyService.createLayer();

            const clip = await RendleyService.addMediaToLayer(layer.id, mediaDataId, 0);

            if (clip != null) {
                RendleyStore.setFilenameByClipId(clip.id, track.title);
                ApplicationStore.setSelectedClipId(clip.id);
            }
        } catch (error) {
            console.error(error);
        }
    }, []);

    const filteredProjectTracks = sortedTracks ? sortedTracks.filter((track) => {
        if (selectedTab === EditorMediaTypeEnums.VIDEO) { // @ts-ignore
            return IsVideoTrackType(track.trackType) && IsUploadedTrackType(track.trackType);
        } else if (selectedTab === EditorMediaTypeEnums.AUDIO) { // @ts-ignore
            return !IsVideoTrackType(track.trackType) && IsUploadedTrackType(track.trackType);
        } else if (selectedTab === EditorMediaTypeEnums.IMAGE) {
            return true;
        }
    }) : [];

    const readableMediaType = (selectedTab) => {
        if (selectedTab === EditorMediaTypeEnums.VIDEO) {
            return "videos";
        } else if (selectedTab === EditorMediaTypeEnums.AUDIO) {
            return "audio";
        } else {
            return "images";
        }
    }

    // @ts-ignore
    return (
        <div style={{width: '100%', maxHeight: '600px', minWidth: "250px"}}>
            <ConfirmDialogComponent/>
            <Box
                display="flex"
                alignItems="center"
                flexDirection="row"
                width="100%"
            >
                <Box display="flex">
                    <Button
                        variant="text"
                        onClick={() => setSelectedTab(EditorMediaTypeEnums.IMAGE)}
                        sx={{
                            color: '#2B6BFD',
                            borderBottom: selectedTab === EditorMediaTypeEnums.IMAGE ? '2px solid #2B6BFD' : 'none',
                            borderRadius: '0px',
                        }}
                    >
                        <Typography fontWeight={400}>Image</Typography>
                    </Button>
                    <Button
                        variant="text"
                        onClick={() => setSelectedTab(EditorMediaTypeEnums.VIDEO)}
                        sx={{
                            color: '#2B6BFD',
                            borderBottom: selectedTab === EditorMediaTypeEnums.VIDEO ? '2px solid #2B6BFD' : 'none',
                            borderRadius: '0px',
                        }}
                    >
                        <Typography fontWeight={400}>Video</Typography>
                    </Button>
                    <Button
                        variant="text"
                        onClick={() => setSelectedTab(EditorMediaTypeEnums.AUDIO)}
                        sx={{
                            color: '#2B6BFD',
                            borderBottom: selectedTab === EditorMediaTypeEnums.AUDIO ? '2px solid #2B6BFD' : 'none',
                            borderRadius: '0px',
                        }}
                    >
                        <Typography fontWeight={400}>Audio</Typography>
                    </Button>
                </Box>
                <Box marginLeft="auto">
                    <SortButton sortNewest={sortNewest} onClick={() => setSortNewest(!sortNewest)} iconOnly={true}/>
                </Box>
            </Box>
            <div>
                <br/>
                {filteredProjectTracks && filteredProjectTracks.length > 0 ? (
                    <Grid container spacing={2}>
                        {filteredProjectTracks.map((track) => (
                            <Grid item xs={6} key={track.trackId}>
                                {/* @ts-ignore */}
                                <EditorMediaThumbnail
                                    thumbnailUrl={track.thumbnailUrl}
                                    title={track.title}
                                    status={track.status}
                                    isImage={selectedTab === EditorMediaTypeEnums.IMAGE}
                                    isVideo={selectedTab === EditorMediaTypeEnums.VIDEO}
                                    duration={selectedTab === EditorMediaTypeEnums.IMAGE ? null : track.duration}
                                    onClick={() => handleClickMediaThumbnail({track, isImage: selectedTab === EditorMediaTypeEnums.IMAGE})}
                                    onDelete={() => handleDeleteClick({track, isImage: selectedTab === EditorMediaTypeEnums.IMAGE})}
                                />
                            </Grid>
                        ))}
                    </Grid>
                ) : (
                    <Box>
                        <Typography align="center">No {readableMediaType(selectedTab)} found.</Typography>
                    </Box>
                )}
            </div>
        </div>
    );
});

export default MediaPanelContainer;