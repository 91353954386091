import {
    Int16ToUint8Array,
    SerializeAudioChunks,
    TimeKeeper,
} from "../utils/utils";
import {forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState} from "react";
import {BASE_WEBSOCKET_URL, FetchContext} from "../context/FetchContext";
import {LocalAudioTrack, RoomEvent} from "livekit-client";

const timeKeeper = new TimeKeeper();
const pongs = [];
const SendTimingRequest = ({ws}) => {
    if (ws && ws.readyState === WebSocket.OPEN) {
        const clientTime = performance.now();
        const buffer = new ArrayBuffer(8);
        const view = new DataView(buffer);
        view.setFloat64(0, clientTime, true); // true for little-endian
        ws.send(buffer);
    }
}

const WS_DATA_CHANNEL_CAPACITY = 2 * 10 * 1024 * 1024;

const EOS_MARKER = new Uint8Array([69, 79, 83, 45, 65, 85, 68, 73, 79]);

const HandleTimingResponse = (event) => {
    const parsedData = JSON.parse(event.data);
    const clientTime = parsedData.clientTimestamp;
    const serverTime = parsedData.serverTimestamp;
    const timeReceived = performance.now();
    pongs.push(timeReceived - clientTime);
    while (pongs.length > 5) {
        pongs.shift();
    }
    const latency = median(pongs);
    const remoteTime = serverTime + latency / 2;
    timeKeeper.setStartTime(remoteTime);
}
const median = (arr) => {
    const mid = Math.floor(arr.length / 2);
    const nums = [...arr].sort((a, b) => a - b);
    return arr.length % 2 !== 0 ? nums[mid] : (nums[mid - 1] + nums[mid]) / 2;
};

const audioWorkletCode = `
class AudioRecorderWorklet extends AudioWorkletProcessor {
  constructor(options) {
    super();
    this.startTime = options.processorOptions.startTime;
    this.sampleRate = options.processorOptions.sampleRate;
    this.frameSize = 960;
    this.sampleCount = 0;
    this.driftCorrection = 0;
    this.lastSyncTime = this.startTime;
    this.syncInterval = 10000;
    this.driftCorrectionRate = 0;
    
    this.port.onmessage = (event) => {
      if (event.data.type === 'syncResponse') {
        this.driftCorrectionRate = event.data.driftCorrectionRate;
      }
    };
  }

  process(inputs, outputs, parameters) {
    const input = inputs[0];
    if (input.length > 0) {
      const channelData = input[0];
      
      const elapsedTime = this.sampleCount / this.sampleRate;
      const timestamp = Math.round(this.startTime + elapsedTime * 1000 + this.driftCorrection);
      
      this.driftCorrection += this.driftCorrectionRate;
      
      if (timestamp - this.lastSyncTime >= this.syncInterval) {
        this.port.postMessage({ type: 'requestSync', timestamp: timestamp });
        this.lastSyncTime = timestamp;
      }

      const int16Data = new Int16Array(channelData.length);
      for (let i = 0; i < channelData.length; i++) {
        int16Data[i] = Math.max(-32768, Math.min(32767, Math.floor(channelData[i] * 32768)));
      }

      this.port.postMessage({
        type: 'audioData',
        timestamp: timestamp,
        audioData: int16Data
      });

      this.sampleCount += channelData.length;
    }
    return true;
  }
}

registerProcessor('audio-recorder-worklet', AudioRecorderWorklet);
`;

const ConsumeData = async (audioData, consumeAll, recordingSocket) => {
    if (audioData.length > 0) {

        if (consumeAll) {
            // console.log("Consuming all data");
        }

        let accumulatedChunks = [];
        let totalSize = 0;

        let sizeLimit = WS_DATA_CHANNEL_CAPACITY;
        if (consumeAll) {
            sizeLimit *= 1000;
        }

        while (audioData.length > 0 && totalSize < sizeLimit) {
            const chunk = audioData[0];
            const chunkSize = chunk.audioData.length;

            if (totalSize + chunkSize > WS_DATA_CHANNEL_CAPACITY) {
                break;
            }

            audioData.shift();  // Consider optimizing this operation if audioData is large
            accumulatedChunks.push(chunk);
            totalSize += chunkSize;
        }

        if (accumulatedChunks.length > 0) {
            // console.log("Total size to be sent is ", totalSize)
            const serializedChunks = SerializeAudioChunks(accumulatedChunks);
            // console.log("Serialized chunks has length ", serializedChunks.length);
            // console.log("Sending data over websocket")
            recordingSocket.send(serializedChunks);
            if (consumeAll) {
                console.log("Sending EOS_MARKER")
                recordingSocket.send(EOS_MARKER);
            }
        }
    } else if (consumeAll) {
        console.log("SENDING EOS_MARKER")
        recordingSocket.send(EOS_MARKER);
    }
};

const HighQualityRecording = forwardRef(function HighQualityRecording({
                                                                          livekitToken,
                                                                          localRecordingEnabled,
                                                                          room,
                                                                          setRecordingDone,
                                                                          setLocalRecordingStopped,
                                                                          roomName,
                                                                          componentName,
                                                                      }, ref) {
    const timingRequestsInterval = useRef(null);
    const [isRecording, setIsRecording] = useState(false);
    const recorderRef = useRef(null);
    const audioDataRef = useRef([]);
    const consumeIntervalRef = useRef(null);
    const [timingSocket, setTimingSocket] = useState(null);
    const [recordingSocket, setRecordingSocket] = useState(null);
    const serverReceivedEos = useRef(false);

    // useEffect(() => {
    //
    //     if (livekitToken === "" || !localRecordingEnabled) return;
    //
    //     const timerUrl = `${BASE_WEBSOCKET_URL}/ws/timer/${livekitToken}`;
    //     const ws = CreateWebSocket(
    //         timerUrl,
    //         (socket) => {
    //             setTimingSocket(socket);
    //             timingRequestsInterval.current = setInterval(() => SendTimingRequest({ws: socket}), 300);
    //         },
    //         HandleTimingResponse,
    //         localRecordingEnabled
    //     );
    //
    //     return () => {
    //         clearInterval(timingRequestsInterval.current);
    //         if (ws.readyState === WebSocket.OPEN) {
    //             ws.close();
    //         }
    //     };
    // }, [livekitToken, localRecordingEnabled]);

    // useEffect(() => {
    //     if (livekitToken === "" || roomName === "" || !localRecordingEnabled) return;
    //
    //     const recordUrl = `${BASE_WEBSOCKET_URL}/ws/recording/${roomName}/${livekitToken}`;
    //     const recordWs = CreateWebSocket(
    //         recordUrl,
    //         setRecordingSocket,
    //         (event) => {
    //             console.log("Received message from recording socket", event.data);
    //             if (event.data === "EOS-AUDIO") {
    //                 serverReceivedEos.current = true;
    //                 console.log("Server received EOS_MARKER");
    //             }
    //         },
    //         localRecordingEnabled
    //     );
    //
    //     return () => {
    //         if (recordWs.readyState === WebSocket.OPEN) {
    //             recordWs.close();
    //         }
    //     };
    // }, [livekitToken, roomName, localRecordingEnabled]);


    const audioContextRef = useRef(null);
    const mixedDestinationRef = useRef(null);

    useEffect(() => {
        if (!localRecordingEnabled) {
            return;
        }

        const newAudioContext = new AudioContext({sampleRate: 48000, latencyHint: "playback"});
        const newMixedDestination = newAudioContext.createMediaStreamDestination();
        audioContextRef.current = newAudioContext;
        mixedDestinationRef.current = newMixedDestination;

        return () => {
            if (newAudioContext !== null && newAudioContext.state !== "closed") {
                newAudioContext.close();
            }
        };
    }, [localRecordingEnabled]);

    useEffect(() => {
        if (room === null || audioContextRef.current === null || mixedDestinationRef.current === null || !localRecordingEnabled) {
            return;
        }

        const ConnectTrack = async (trackPublication) => {
            if (trackPublication.track instanceof LocalAudioTrack) {
                const audioTrack = trackPublication.audioTrack;
                const sourceNode = audioContextRef.current.createMediaStreamSource(new MediaStream([audioTrack.mediaStreamTrack]));
                sourceNode.connect(mixedDestinationRef.current);
            }
        };

        room.on(RoomEvent.LocalTrackPublished, ConnectTrack);
    }, [room, localRecordingEnabled]);


    const startRecording = async () => {
        if (isRecording) {
            console.log("Start recording called but already recording");
            return;
        }

        if (!localRecordingEnabled) {
            console.log("Local recording not enabled");
            setIsRecording(true);
            return;
        }

        console.log(componentName, ": Start recording called");

        const audioContext = audioContextRef.current;
        const mixedDestination = mixedDestinationRef.current;

        const workletBlob = new Blob([audioWorkletCode], {type: 'application/javascript'});
        const workletUrl = URL.createObjectURL(workletBlob);
        await audioContext.audioWorklet.addModule(workletUrl);

        const audioTrack = new LocalAudioTrack(mixedDestination.stream.getAudioTracks()[0]);
        const source = audioContext.createMediaStreamSource(audioTrack.mediaStream);

        const startTime = timeKeeper.getCurrentTime();
        const recorder = new AudioWorkletNode(audioContext, 'audio-recorder-worklet', {
            processorOptions: {
                startTime: startTime,
                sampleRate: audioContext.sampleRate
            }
        });

        recorder.port.onmessage = (event) => {
            if (event.data.type === 'requestSync') {
                const currentServerTime = timeKeeper.getCurrentTime();
                const drift = currentServerTime - event.data.timestamp;
                const correctionDuration = 5 * audioContext.sampleRate;
                recorder.port.postMessage({type: 'syncResponse', driftCorrectionRate: drift / correctionDuration});
            } else if (event.data.type === 'audioData') {
                const {timestamp, audioData} = event.data;
                const byteAudioData = Int16ToUint8Array(audioData);
                audioDataRef.current.push({timestamp, audioData: byteAudioData});
            }
        };

        if (consumeIntervalRef.current) {
            clearInterval(consumeIntervalRef.current);
        }
        consumeIntervalRef.current = setInterval(() => ConsumeData(audioDataRef.current, false, recordingSocket), 10000);

        source.connect(recorder);
        recorder.connect(audioContext.destination);

        recorderRef.current = recorder;
        setIsRecording(true);
        URL.revokeObjectURL(workletUrl);
    }

    const stopRecording = async () => {
        if (!isRecording) {
            console.log(componentName, ": Stop recording called but not recording")
            return;
        }

        if (!localRecordingEnabled) {
            console.log("Local recording not enabled");
            setLocalRecordingStopped(true);
            setTimeout(() => {
                setRecordingDone(true);
            }, 1000);
            setIsRecording(false);
            return;
        }

        console.log(componentName, ": Stop recording called")

        if (recorderRef.current) {
            recorderRef.current.disconnect();
            recorderRef.current = null;
        }

        if (consumeIntervalRef.current) {
            clearInterval(consumeIntervalRef.current);
        }

        setIsRecording(false);
        setLocalRecordingStopped(true);
        console.log(componentName, ": Local recording stopped");

        console.log("Consume all called");
        ConsumeData(audioDataRef.current, true, recordingSocket).then(() => {
            ReliablySendEosMarker(recordingSocket, serverReceivedEos, setRecordingDone);
        });
    };

    const SendEosMarker = (recordingSocket, serverReceivedEos, setRecordingDone) => {
        if (serverReceivedEos.current) {
            setRecordingDone(true);
            return;
        }
        if (recordingSocket.readyState === WebSocket.OPEN) {
            recordingSocket.send(EOS_MARKER);
        }
    }

    const ReliablySendEosMarker = (recordingSocket, serverReceivedEos, setRecordingDone) => {
        SendEosMarker(recordingSocket, serverReceivedEos);
        // Retry sending EOS_MARKER every 2 seconds three times
        setTimeout(() => SendEosMarker(recordingSocket, serverReceivedEos, setRecordingDone), 2000);
        setTimeout(() => SendEosMarker(recordingSocket, serverReceivedEos, setRecordingDone), 4000);
        setTimeout(() => SendEosMarker(recordingSocket, serverReceivedEos, setRecordingDone), 6000);
        // If server still hasn't received EOS_MARKER, set recording done
        setTimeout(() => {
            setRecordingDone(true);
        }, 8000);
    }

    const isHighQualityRecordingActive = () => {
        return isRecording;
    }

    useImperativeHandle(ref, () => ({
        startRecording,
        stopRecording,
        isHighQualityRecordingActive
    }), [startRecording, stopRecording]);

    return (
        <div style={{display: "none"}}/>
    )

});

export default HighQualityRecording;
