/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import CardMedia from "@mui/material/CardMedia";


export const NewEditLikeADocFeature = () => {
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    return (
        <Box>
            <Grid container spacing={isMd ? 4 : 0} direction={isMd ? 'row' : 'column'} alignItems="center">
                <Grid item xs={12} md={4}>
                    <Box marginBottom={4}>
                        <Typography sx={{ fontWeight: 700 }} variant={'h3'} gutterBottom>
                            <Typography
                                color={'#2B6BFD'}
                                component={'span'}
                                variant={'inherit'}
                            >
                                Edit like a doc
                            </Typography>
                        </Typography>
                        <Typography  fontFamily={"Inter"} color={'text.secondary'} fontSize="1.2rem" marginBottom={4}>
                            Libretto automatically transcribes recordings and allows you to edit them on a
                            {' '}<span style={{fontWeight: 1200}}>
                                    collaborative text editor and timeline.
                                </span>{' '}
                        </Typography>
                        <Typography fontWeight={500} color={'text.secondary'} fontSize="1.2rem">
                            Trim tracks, combine clips and add captions with ease
                        </Typography>
                    </Box>
                </Grid>
                <Grid
                    item
                    container
                    justifyContent="center"
                    alignItems="center"
                    xs={12}
                    md={8}
                >
                    <Box component={Card} boxShadow={4} height={1} width={1}>
                        <Box
                            component={CardMedia}
                            height={1}
                            width={1}
                            minHeight={{ xs: 210, sm: 280, md: 380 }}
                            style={{
                                backgroundSize: 'cover',  // Ensures the image is scaled down to fit inside the container without cropping
                                backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                                backgroundPosition: 'center', // Centers the image within the container
                                backgroundColor: '#222B45',
                            }}
                            image="https://storage.googleapis.com/libretto-public-assets/Editor.png"
                        />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};



const EditLikeADocFeature = () => {
    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    // @ts-ignore
    return (
        <Box>
            <Grid container spacing={isMd ? 4 : 0} direction={isMd ? 'row' : 'column'} alignItems="center">
                <Grid item xs={12} md={4}>
                    <Box marginBottom={4}>
                        <Typography sx={{ fontWeight: 700 }} variant={'h3'} gutterBottom>
                        <Typography
                            color={'primary'}
                            component={'span'}
                            variant={'inherit'}
                        >
                            Edit
                        </Typography>
                            {' '}like a{' '}
                            <Typography
                                color={'primary'}
                                component={'span'}
                                variant={'inherit'}
                            >
                                doc
                            </Typography>
                        </Typography>
                            <Typography color={'text.secondary'} fontSize="1.2rem" marginBottom={4}>
                                Libretto automatically transcribes recordings and allows you to edit them on a
                                {' '}<span style={{fontWeight: 1200}}>
                                    collaborative text editor and timeline.
                                </span>{' '}
                            </Typography>
                            <Typography fontWeight={500} color={'text.secondary'} fontSize="1.2rem">
                                Trim tracks, combine clips and add captions with ease
                            </Typography>
                    </Box>
                </Grid>
                <Grid
                    item
                    container
                    justifyContent="center"
                    alignItems="center"
                    xs={12}
                    md={8}
                >
                    <Box component={Card} boxShadow={4} height={1} width={1}>
                        <Box
                            component={CardMedia}
                            height={1}
                            width={1}
                            minHeight={{ xs: 210, sm: 280, md: 380 }}
                            style={{
                                backgroundSize: 'contain',  // Ensures the image is scaled down to fit inside the container without cropping
                                backgroundRepeat: 'no-repeat', // Prevents the image from repeating
                                backgroundPosition: 'center', // Centers the image within the container
                                backgroundColor: '#222B45',
                            }}
                            image="https://storage.googleapis.com/libretto-public-assets/DocEditor.png"
                        />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default EditLikeADocFeature;
