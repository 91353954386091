import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CollaborativeTextEditor from "../Editor/CollaborativeTextEditor";
import React, {useContext, useState} from "react";
import TimelineEditor from "../Editor/EditorComponents/TimelineEditor";
import ModernVideoPlayer from "../../components/VideoJs/ModernVideoPlayer";
import {LibrettoLiveblocksContext} from "../Editor/LibrettoLiveblocksContext";
import CompositionArea from "./CompositionArea";
import {FilmIcon} from "./FilmIcon";
import Split from "react-split";
import Timeline from "../../editor/modules/timeline/Timeline";
import NewTextEditor from "./NewTextEditor";
import TextPanelContainer from "../../editor/modules/sidebar/containers/TextPanelContainer/TextPanelContainer";
import Sidebar from "./Sidebar";

const ModernEditorInner = ({tracks, projectId, orderOfTracks, compositionContainerRef, canvasRef, timelineEditorRef, enhancedWavedataForTracks, trims, fillerWordRemovalTrims, corrections, transcripts, assetId, clipSize, transcriptsLoaded, tracksLoaded, waveDataForTracks, selectedTrackIndex, setSelectedTrackIndex, handleSelect, editorRef, captionsEnabled, videoplayerRef, videoPlayerVisible, waveformRefs, handleVideoPlayerPlayToggle, loadTracks}) => {

    let videoPlayerWidth = 6;
    let textEditorWidth = 5;

    const showTextEditor = true;

    if (!showTextEditor) {
        videoPlayerWidth = 10;
        textEditorWidth = 0;
    } else if (!videoPlayerVisible) {
        videoPlayerWidth = 0;
        textEditorWidth = 10;
    }

    const [sizes, setSizes] = useState([80, 20]); // Initial split sizes: 80% top, 20% bottom

    const handleDrag = (newSizes) => {
        setSizes(newSizes);
    };

    return (
        <Box>
            <Split
                sizes={sizes}
                minSize={150}
                expandToMin={false}
                gutterSize={3}
                gutterAlign="center"
                // snapOffset={30}
                dragInterval={1}
                direction="vertical"
                onDrag={handleDrag}
                gutter={(index, direction) => {
                    const gutter = document.createElement('div');
                    gutter.className = `gutter gutter-${direction}`;
                    gutter.style.backgroundColor = '#888';
                    gutter.style.backgroundRepeat = 'no-repeat';
                    gutter.style.backgroundPosition = '50%';
                    gutter.style.backgroundImage = 'url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==")';
                    gutter.style.transition = 'background-color 0.3s';
                    gutter.style.cursor = 'row-resize';
                    gutter.addEventListener('mouseover', () => gutter.style.backgroundColor = '#aaa');
                    gutter.addEventListener('mouseout', () => gutter.style.backgroundColor = '#888');
                    return gutter;
                }}
                style={{display: 'flex', flexDirection: 'column', height: 'calc(100vh - 88px)'}}
            >
                <div style={{display: 'flex', flexDirection: 'column', overflow: 'hidden'}}>
                    <Grid container alignItems="center" justifyContent="space-between"
                          style={{padding: 0, height: '100%'}}>
                        <Grid item xs={textEditorWidth} style={{
                            display: showTextEditor ? 'flex' : 'none',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%'
                        }}>
                            <NewTextEditor
                                editorRef={editorRef}
                                orderOfTracks={orderOfTracks}
                                onSelect={handleSelect}
                                transcripts={transcripts}
                                trims={trims}
                                fillerWordRemovalTrims={fillerWordRemovalTrims}
                                corrections={corrections}
                                transcriptsLoaded={transcriptsLoaded}
                                setSelectedTrackIndex={setSelectedTrackIndex}
                                currentTrackIndex={selectedTrackIndex}
                                isPlaying={false}
                                timelineEditorRef={timelineEditorRef}
                                videoPlayerRef={videoplayerRef}
                            />
                        </Grid>
                        <Grid item xs={videoPlayerWidth} style={{
                            display: videoPlayerVisible ? 'flex' : 'none',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%',
                        }}>
                            <CompositionArea containerRef={compositionContainerRef} canvasRef={canvasRef}/>
                        </Grid>
                        <Grid item style={{alignSelf: "flex-start", height: "100%"}}>
                            <Sidebar projectId={projectId}/>
                        </Grid>
                    </Grid>
                </div>
                <div style={{overflow: 'hidden', paddingTop: "20px"}}>
                    <Timeline/>
                </div>
            </Split>
    </Box>
    )
}

export default ModernEditorInner;
