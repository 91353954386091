import React, {useRef, useEffect, useCallback, useState} from 'react';
import { observer } from 'mobx-react-lite';
import {reaction, IReactionDisposer, set} from 'mobx';
import { InteractiveElement } from '../../utils/InteractiveElement';
import { RendleyStore } from '../../../../store/RendleyStore';
import { WindowStore } from '../../../../store/WindowStore';
import { Engine, TextClip } from '@rendley/sdk';
import { ApplicationStore } from '../../../../store/ApplicationStore';

const DragResizeRotateContainer: React.FC = observer(() => {
    const containerRef = useRef<HTMLDivElement>(null);
    const interactionRef = useRef<InteractiveElement | null>(null);
    const pixelRatioRef = useRef<number>(1);
    const [selectedClipId, setSelectedClipId] = useState(ApplicationStore.selectedClipId);

    const getSelectedClip = useCallback(() => {
        if (ApplicationStore.selectedClipId == null) {
            return null;
        }
        const clip = Engine.getInstance().getClipById(ApplicationStore.selectedClipId);
        return clip?.hasSprite() ? clip : null;
    }, []);

    const handleDrag = useCallback((x: number, y: number) => {
        const selectedClip = getSelectedClip();
        if (selectedClip == null) return;

        const width = selectedClip.style.getWidth();
        const height = selectedClip.style.getHeight();

        let newX = x / pixelRatioRef.current + width / 2;
        let newY = y / pixelRatioRef.current + height / 2;

        selectedClip.style.setPosition(newX, newY);
    }, [getSelectedClip]);

    const handleScale = useCallback((deltaWidth: number, deltaHeight: number) => {
        const selectedClip = getSelectedClip();
        if (selectedClip == null) return;

        const initialWidth = selectedClip.style.getWidth();
        const initialHeight = selectedClip.style.getHeight();
        const [initialScaleX, initialScaleY] = selectedClip.style.getScale();

        const newDeltaWidth = deltaWidth / pixelRatioRef.current;
        const newDeltaHeight = deltaHeight / pixelRatioRef.current;

        const scaleX = ((initialWidth + newDeltaWidth) * initialScaleX) / initialWidth;
        const scaleY = ((initialHeight + newDeltaHeight) * initialScaleY) / initialHeight;

        selectedClip.style.setScale(scaleX, scaleY);
    }, [getSelectedClip]);

    const handleChangeTextWarp = useCallback((deltaWidth: number) => {
        const selectedClip = getSelectedClip();
        if (selectedClip == null || selectedClip.getType() !== 'text') return;

        const initialWidth = selectedClip.style.getWidth();
        const width = initialWidth + deltaWidth * 2;

        (selectedClip as TextClip).style.setWordWrapWidth(width);
    }, [getSelectedClip]);

    const handleResize = useCallback((deltaWidth: number, deltaHeight: number) => {
        const selectedClip = getSelectedClip();
        if (selectedClip == null) return;

        if (selectedClip.getType() === 'text') {
            return handleChangeTextWarp(deltaWidth);
        }

        return handleScale(deltaWidth, deltaHeight);
    }, [getSelectedClip, handleChangeTextWarp, handleScale]);

    const handleRotate = useCallback((rotation: number) => {
        const selectedClip = getSelectedClip();
        if (selectedClip == null) return;

        selectedClip.style.setRotation(rotation);
    }, [getSelectedClip]);

    const handleUpdate = useCallback(() => {
        const selectedClip = getSelectedClip();
        if (!selectedClip?.hasSprite()) return;

        const style = selectedClip.style;

        const width = style.getWidth() * pixelRatioRef.current;
        const height = style.getHeight() * pixelRatioRef.current;
        const x = style.getPosition()[0] * pixelRatioRef.current;
        const y = style.getPosition()[1] * pixelRatioRef.current;
        const rotation = style.getRotation();
        const zIndex = style.getZIndex();

        const newX = x - width / 2;
        const newY = y - height / 2;

        interactionRef.current?.updateStyle({
            width: `${width}px`,
            height: `${height}px`,
            position: 'absolute',
            top: '0px',
            left: '0px',
            transform: `translate(${newX}px, ${newY}px) rotate(${rotation}rad)`,
            transformOrigin: '50% 50%',
            zIndex: `${zIndex}`,
        });
    }, [getSelectedClip]);

    useEffect(() => {
        if (containerRef.current) {
            interactionRef.current = new InteractiveElement(containerRef.current, {
                onDrag: handleDrag,
                onResize: handleResize,
                onScale: handleScale,
                onRotate: handleRotate,
                onUpdate: handleUpdate,
            });

            return () => {
                interactionRef.current?.destroy();
            };
        }
    }, [handleDrag, handleResize, handleScale, handleRotate, handleUpdate]);

    useEffect(() => {
        const disposer: IReactionDisposer = reaction(
            () => ({
                currentTime: RendleyStore.currentTime,
                selectedClipId: ApplicationStore.selectedClipId,
                canvasResolution: WindowStore.canvasResolution,
                videoResolution: RendleyStore.display,
                windowResolution: WindowStore.resolution,
                style: ApplicationStore.selectedClipId != null ? RendleyStore.styles?.[ApplicationStore.selectedClipId] : null,
                text: ApplicationStore.selectedClipId != null ? RendleyStore.clips[ApplicationStore.selectedClipId]?.text : null,
            }),
            (payload) => {
                const selectedClip = getSelectedClip();
                if (payload.selectedClipId == null || selectedClip == null) {
                    if (interactionRef.current) {
                        interactionRef.current.hideHandlers();
                    }
                    // interactionRef.current?.hideHandlers();
                    return;
                }

                setSelectedClipId(payload.selectedClipId);

                const isSelectedClipVisible =
                    payload.currentTime >= selectedClip.getLeftBound() &&
                    payload.currentTime <= selectedClip.getRightBound();

                if (isSelectedClipVisible) {
                    interactionRef.current?.showHandlers();
                } else {
                    interactionRef.current?.hideHandlers();
                    return;
                }

                const [canvasWidth, canvasHeight] = payload.canvasResolution;
                const { width, height } = payload.videoResolution;
                pixelRatioRef.current = Math.min(canvasWidth / width, canvasHeight / height);

                handleUpdate();
            },
            { fireImmediately: true }
        );

        return () => disposer();
    }, [getSelectedClip, handleUpdate]);

    return <div ref={containerRef} className="absolute"></div>;
});

export default DragResizeRotateContainer;