import React, {useCallback, useEffect, useRef, useState} from "react";
import {observer} from "mobx-react-lite";
import {autorun, IReactionDisposer, reaction} from "mobx";
import {CANVAS_ID, COMPOSITION_CONTAINER_ID} from "../../editor/config/config";
import {ApplicationStore} from "../../editor/store/ApplicationStore";
import {RendleyStore} from "../../editor/store/RendleyStore";
import {WindowStore} from "../../editor/store/WindowStore";
import {SelectionWatcher} from "../../editor/modules/composition/utils/CompositionSelectionWatcher";
import {calculateAspectRatioFit} from "../../editor/utils/math/calculateAspectRatioFit";
import './Composition.styles.scss';
import DragResizeRotateContainer
    from "../../editor/modules/composition/containers/DragResizeRotateContainer/DragResizeRotateContainer";
import {throttle} from "lodash-es";


const CompositionArea = observer(({ containerRef, canvasRef }) => {

    // Autorun for observing changes in MobX stores
    useEffect(() => {
        const disposer = reaction(
            () => ({
                canvasResolution: WindowStore.canvasResolution,
                videoResolution: RendleyStore.display,
                windowResolution: WindowStore.resolution,
                style: ApplicationStore.selectedClipId != null ? RendleyStore.styles?.[ApplicationStore.selectedClipId] : null,
                text: ApplicationStore.selectedClipId != null ? RendleyStore.clips[ApplicationStore.selectedClipId]?.text : null,
            }),
            () => {

            // This will run whenever RendleyStore.display or RendleyStore.updateTimestamp changes
            updateCanvasSize();
        });

        return () => {
            disposer(); // Dispose of the autorun when the component unmounts
        };
    }, []);

    // Handle ResizeObserver and MutationObserver
    useEffect(() => {
        if (containerRef.current) {
            // ResizeObserver for container size changes
            const resizeObserver = new ResizeObserver((entries) => {
                try {
                    for (const entry of entries) {
                        if (entry.target === containerRef.current) {
                            const {width, height} = entry.contentRect;
                            updateCanvasSize(); // Update canvas size based on container resize
                            WindowStore.setCanvasResolution([width, height]); // Sync with WindowStore
                        }
                    }
                } catch (e) {
                    console.error(e);
                }
            });
            resizeObserver.observe(containerRef.current);

            // MutationObserver for child DOM changes
            const mutationObserver = new MutationObserver(() => {
                initializeCanvas();
            });
            mutationObserver.observe(containerRef.current, { childList: true, subtree: true });

            // Initial canvas setup
            initializeCanvas();

            // Cleanup observers on component unmount
            return () => {
                resizeObserver.disconnect();
                mutationObserver.disconnect();
            };
        }
    }, [containerRef]);

    // Calculate the size for the canvas based on container dimensions
    const calculateWidthForComposition = () => {
        if (!containerRef.current || !canvasRef.current) return { width: 0, height: 0 };

        const { clientWidth: containerWidth, clientHeight: containerHeight } = containerRef.current;
        const { width: canvasWidth, height: canvasHeight } = canvasRef.current;

        return calculateAspectRatioFit(canvasWidth, canvasHeight, containerWidth, containerHeight);
    };

    // Update the canvas size based on the container's dimensions
    const updateCanvasSize = useCallback(throttle(() => {
        const { newWidth, newHeight } = calculateWidthForComposition();

        if (canvasRef.current) {
            canvasRef.current.style.width = `${newWidth}px`;
            canvasRef.current.style.height = `${newHeight}px`;
        }
    }, 100), []);

    // Initialize the canvas when the component mounts
    const initializeCanvas = () => {
        if (canvasRef.current) {
            const selectionWatcher = SelectionWatcher.getInstance();
            selectionWatcher.init(canvasRef.current);
            updateCanvasSize(); // Ensure the canvas size is updated after initialization
        }
    };

    // Handle mouse down event (deselect clip)
    const handleMouseDown = () => {
        ApplicationStore.setSelectedClipId(null);
    };

    return (
        <div className="composition" id={COMPOSITION_CONTAINER_ID} onMouseDown={handleMouseDown}>
            <div className="composition__container" ref={containerRef}>
                <div
                    className="relative"
                    onMouseDown={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    }}
                >
                    <canvas
                        ref={canvasRef}
                        id={CANVAS_ID}
                    ></canvas>
                    <DragResizeRotateContainer />
                </div>
            </div>
        </div>
    );
});

export default CompositionArea;