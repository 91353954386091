import React, { useEffect, useState } from 'react';
import { autorun } from "mobx";
import { PauseIcon } from "../../../../assets/icons/PauseIcon";
import { PlayIcon } from "../../../../assets/icons/PlayIcon";
import { ScissorsIcon } from "../../../../assets/icons/ScissorsIcon";
import { TrashIcon } from "../../../../assets/icons/TrashIcon";
import { ZoomInIcon } from "../../../../assets/icons/ZoomInIcon";
import { ZoomOutIcon } from "../../../../assets/icons/ZoomOutIcon";
import { RendleyService } from "../../../../services/RendleyService";
import { ApplicationStore } from "../../../../store/ApplicationStore";
import { RendleyStore } from "../../../../store/RendleyStore";
import { TimelineStore } from "../../../../store/TimelineStore";
import { secondsToTimecode } from "../../../../utils/secondsToTimecode";
import './Controls.styles.scss';
import Paper from '../../../../components/Paper/Paper';
import Button from "../../../../components/Button/Button";

const Controls = () => {
    const [isPlaying, setIsPlaying] = useState(RendleyStore.isPlaying);
    const [displayTime, setDisplayTime] = useState(secondsToTimecode(RendleyStore.currentTime, true));
    const [selectedClipId, setSelectedClipId] = useState(ApplicationStore.selectedClipId);
    const [selectedTransitionId, setSelectedTransitionId] = useState(ApplicationStore.selectedTransitionId);

    useEffect(() => {
        const dispose = autorun(() => {
            setIsPlaying(RendleyStore.isPlaying);
            setSelectedClipId(ApplicationStore.selectedClipId);
            setSelectedTransitionId(ApplicationStore.selectedTransitionId);
            setDisplayTime(secondsToTimecode(RendleyStore.currentTime, true));
        });

        return () => {
            dispose();
        };
    }, []);

    const handlePlay = () => RendleyService.play();
    const handlePause = () => RendleyService.pause();
    const handleTrash = () => RendleyService.deleteAtCurrentTime();
    const handleSplit = () => RendleyService.splitAtCurrentTime().then(()=> {
        console.log('Split at current time');
    })
    const decrementZoom = () => TimelineStore.setZoom(TimelineStore.zoom - 0.1);
    const incrementZoom = () => TimelineStore.setZoom(TimelineStore.zoom + 0.1);

    const handleStop = () => {
        if (isPlaying) {
            RendleyService.getEngine().pause();
        }
        RendleyService.getEngine().seek(0);
    };

    const isClipSelected = selectedClipId != null;
    const isTransitionSelected = selectedTransitionId != null;
    const isSplitEnabled = isClipSelected;
    const isTrashEnabled = isClipSelected || isTransitionSelected;

    return (
        <Paper>
            <div className="controls">
                <div className="controls__section">
                    <Button disabled={!isSplitEnabled} onClick={handleSplit}>
                        <ScissorsIcon />
                    </Button>

                    <Button disabled={!isTrashEnabled} onClick={handleTrash}>
                        <TrashIcon />
                    </Button>
                </div>

                <div className="controls__section">
                    <Button onClick={handleStop}>
                        <div className="controls__section--stop-btn"></div>
                    </Button>

                    {isPlaying ? (
                        <Button onClick={handlePause}>
                            <PauseIcon />
                        </Button>
                    ) : (
                        <Button onClick={handlePlay}>
                            <PlayIcon />
                        </Button>
                    )}
                    <span className="controls__playback__time">{displayTime}</span>
                </div>

                <div className="controls__section">

                    <Button onClick={decrementZoom}>
                        <ZoomOutIcon />
                    </Button>

                    <Button onClick={incrementZoom}>
                        <ZoomInIcon />
                    </Button>
                </div>
            </div>
        </Paper>
    );
};

export default Controls;