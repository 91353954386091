import Grid from "@mui/material/Grid";
import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {
    AdaptRecordingMode,
    GridVideoLayout,
    LibrettoRecordingMode, LibrettoStudioNameStyle, LibrettoStudioTheme,
    RecordingModeString,
    RefreshTokenAndRetry,
    StandardVideoResolution, StudioNameStyleString, TypeOfTrack
} from "../../utils/utils";
import {useTheme} from "@mui/material/styles";
import {collection, doc, onSnapshot, query, updateDoc, where} from "firebase/firestore";
import {db} from "../../Firebase";
import {
    kRoomStateCollectionName,
    kTracksCollectionName,
    RoomName,
    RoomNotRecording, TrackAssetId, TrackType
} from "../../utils/CollectionConstants";
import {CLIENT_URL, FetchContext} from "../../context/FetchContext";
import {AuthContext} from "../../context/AuthContext";
import {LiveKitRoom} from "@livekit/components-react";
import {
    NewStudioVideoConference,
    NewStudioVideoConferenceVideoRecording,
    StudioVideoConference
} from "../../components/LibrettoConference";
import Soundboard from "../Studio/Soundboard";
import {Button, ButtonBase, DialogContent, Typography} from "@mui/material";
import NewSoundboardWithButton from "./NewSoundboardWithButton";
import {NewCustomControlBar} from "./NewCustomControlBar";
import Box from "@mui/material/Box";
import {SettingsIcon, InviteIcon, StudioBackIconButton, StudioBackIcon} from "./SettingsIcon";
import useMediaQuery from "@mui/material/useMediaQuery";
import SettingsMenu from "./SettingsMenu";
import Dialog from "@mui/material/Dialog";
import {NewInviteParticipantCard} from "./NewInviteParticipantCard";
import {NewRecordingStoppedCard} from "../Studio/RecordingStoppedCard";
import InfoIcon from '@mui/icons-material/Info';
import RecordingGuidelinesCard from "./NewRecordingGuidelines";
import mixpanel from "mixpanel-browser";
import {useConfirmDialog} from "../NewHome/DeleteConfirmation";
import NewRecordButton from "./NewRecordButton";
import {HostLeaveButton} from "../NewGuestStudio/CustomLeaveButton";
import MediaBoard from "./MediaBoard";
import {MediaButton} from "./ControlBarButtons";
import Tooltip from "@mui/material/Tooltip";

const serverUrl = 'wss://librettobeta-htow58t4.livekit.cloud'

const NewStudio = ({userChoices, projectId, userFriendlyStudioName}) => {

    const theme = useTheme();

    const isTooSmall = useMediaQuery(theme.breakpoints.down('smd'));

    const announcementBarStyle = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '18px',
        backgroundColor: '#2B6BFD', // You can change this to match your theme
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999, // Ensure it's above other elements
        overflow: 'hidden',
        transition: 'height 0.3s ease',
        '&:hover': {
            height: '20px', // Expands on hover for better readability
        }
    };

    const announcementTextStyle = {
        color: 'white',
        fontSize: '13px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    };

    const pageStyle = (theme, showAnnouncement) => {

        let backgroundColor = theme === LibrettoStudioTheme.Light ? "#f3f4f5" : "#1A1A1A";
        if (theme === LibrettoStudioTheme.Libretto) {
            backgroundColor = "#2B6BBD";
        }

        return ({
            paddingTop: "12px",
            height: "100vh",
            width: "100vw",
            // Create a gradient background based on color in the backgroundColor variable. Use radial gradient for the top and linear gradient for the bottom.
            background: `radial-gradient(circle at top, ${backgroundColor} 0%, ${backgroundColor} 50%, ${backgroundColor} 100%), linear-gradient(180deg, ${backgroundColor} 0%, ${backgroundColor} 50%, ${backgroundColor} 100%)`,
        })
    }

    const [backgroundImage, setBackgroundImage] = useState({isBlur: false, imageUrl: null});

    const isXl = useMediaQuery(theme.breakpoints.up('xl'));
    const isLg = useMediaQuery(theme.breakpoints.up('lg'));
    const isMd = useMediaQuery(theme.breakpoints.up('md'));

    const barHeight = isXl ? "100px" : (isLg ? "75px" : "66px");

    const controlBarHeight = isXl ? "93px" : (isLg ? "75px" : "66px");

    const buttonHeight = isXl ? "40px" : (isLg ? "36px" : "34px");

    const titleAreaWidth = isXl ? "250px" : (isLg ? "170px" : "150px");

    const [videoAreaFullScreen, setVideoAreaFullScreen] = useState(false);

    const videoHeight = videoAreaFullScreen ? "100vh" : isXl ? "calc(100vh - 309px)" : (isLg ? "calc(100vh - 266px)" : "calc(100vh - 248px)");

    const videoWidth = videoAreaFullScreen ? "100vw" : isXl ? "calc((100vh - 316px) * 16 / 9)" : (isLg ? "calc((100vh - 266px) * 16 / 9)" : "calc((100vh - 248px) * 16 / 9)");

    const videoDisplayStyle = (videoAreaFullScreen) => {
        return ({
            // Set height to be everything that remains in the viewport after subtracting 316px for the control bar and soundboard together.
            height: videoHeight,
            // Set the width based on the height to maintain the aspect ratio of 16:9.
            width: videoWidth,
            maxWidth: videoAreaFullScreen ? "100vw" : "90vw",
        })
    }

    const controlBarMarginBottom = isXl ? "38px" : (isLg ? "32px" : "22px");

    const [settingsOpen, setSettingsOpen] = useState(false);
    const [inviteCardOpen, setInviteCardOpen] = useState(false);
    const [showRecordingEndedCard, setShowRecordingEndedCard] = useState(true);

    document.addEventListener('keydown', (event) => {
        if (event.ctrlKey && event.key === 'f') {
            event.preventDefault(); // Prevent the default F11 behavior
            setVideoAreaFullScreen(!videoAreaFullScreen);
        } else if (event.key === 'Escape' && videoAreaFullScreen) {
            setVideoAreaFullScreen(false);
        }
    });

    const controlBarStyle = (isTooSmall) => {
        return {
            height: controlBarHeight,
            width: "auto",
            paddingLeft: "22px",
            paddingRight: "22px",
            marginBottom: controlBarMarginBottom,
            gap: "12px",
            alignItems: "center",
            display: isTooSmall ? "none" : "inline-flex",
            backgroundColor: "#ffffff",
            borderRadius: "12px",
            boxShadow: "0px 20px 75px 0px rgba(0, 0, 0, 0.05)",
        }
    }


    const soundBoardStyle = (videoAreaFullScreen) => {
        return ({
            height: barHeight,
            marginTop: controlBarMarginBottom,
            paddingLeft: "22px",
            paddingRight: "22px",
            paddingTop: "12px",
            paddingBottom: "12px",
            display: videoAreaFullScreen ? 'none' : 'inline-flex',
            width: "auto",

            borderRadius: "12px",
            boxShadow: "0px 20px 100px 0px rgba(0, 0, 0, 0.05)",
            backgroundColor: "#ffffff",
        })
    }

    const smallButtonStyle = {
        display: 'flex',
        width: buttonHeight,
        height: buttonHeight,
        padding: "10px",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
        cursor: 'pointer',
        borderRadius: "8px",
        border: "1px solid #D5D5D5",
        transition: 'background-color 0.3s',
        backgroundColor: "#f3f4f5",
        '&:hover': {
            backgroundColor: '#e5e5e5', // Darker primary color
        }
    }

    const inviteButtonTextStyle = {
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "20px",
        letterSpacing: "0.14px",
    }

    const titleStyle = (theme) => {
        return ({
            fontFamily: "Inter",
            color: theme === LibrettoStudioTheme.Light ? "#1A1A1A" : "#ffffff",
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "24px",
            letterSpacing: "0.13px",
            overflow: "ellipsis",
        })
    }


    const [cloudEgressStarted, setCloudEgressStarted] = useState(false);   // This means cloud egress has been started by the backend.
    const [recordingInProgress, setRecordingInProgress] = useState(false); // This is the client UI state to show flashing red and timer.
    const [countdown, setCountdown] = useState(null);
    const [studioData, setStudioData] = useState({
        token: '',
        roomName: '',
        userFriendlyRoomName: '',
        userPlan: null,
        hostIdentity: ''
    });
    const [inviteLink, setInviteLink] = useState('');
    const [soundboardToken, setSoundboardToken] = useState('');
    const [showStoppedCard, setShowStoppedCard] = useState(false);
    const navigate = useNavigate();
    const assetIdRef = useRef(null);
    const [recordingStarting, setRecordingStarting] = useState(false);
    const [localRecordingInProgress, setLocalRecordingInProgress] = useState(false)
    const [localRecordingDone, setLocalRecordingDone] = useState(false);
    const [participantsLocalRecordingDone, setParticipantsLocalRecordingDone] = useState(false);
    const [selectedVideoResolution, setSelectedVideoResolution] = useState(StandardVideoResolution);
    const [selectedVideoLayout, setSelectedVideoLayout] = useState(GridVideoLayout);
    const [noiseCancellationEnabled, setNoiseCancellationEnabled] = useState(false);
    const [localRecordingEnabled, setLocalRecordingEnabled] = useState(false);
    const [videoMirrored, setVideoMirrored] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState("en");
    const [selectedBrandLogo, setSelectedBrandLogo] = useState({assetId: null, url: null});
    const [recordingMode, setRecordingMode] = useState(LibrettoRecordingMode.Audio);
    const [studioTheme, setStudioTheme] = useState(LibrettoStudioTheme.Light);
    const [studioNameStyle, setStudioNameStyle] = useState(LibrettoStudioNameStyle.Clean);

    const [uploadProgress, setUploadProgress] = useState(0);
    const [soundboardUploadProgress, setSoundboardUploadProgress] = useState(0);

    const [recordingPerformed, setRecordingPerformed] = useState(false);

    const [soundBoardRecordingDone, setSoundBoardRecordingDone] = useState(false);
    const [localRecordingStopped, setLocalRecordingStopped] = useState(false);

    const readableRecordingMode = (mode) => {
        return mode === LibrettoRecordingMode.Audio ? "audio-only recording" : "video recording";
    }

    const readableVideoResolution = (resolution) => {
        return resolution === StandardVideoResolution ? "Standard" : "High-definition";
    }

    const [showAnnouncement, setShowAnnouncement] = useState(true); // State for the announcement bar [true/false
    const [announcement, setAnnouncement] = useState("Welcome to your studio. The recording mode is " + readableRecordingMode(recordingMode) + ". View recording guidelines "); // State for the announcement message

    useEffect(() => {
        setAnnouncement("Welcome to your studio. The recording mode is " + readableRecordingMode(recordingMode) + ". View recording guidelines ");
    }, [recordingMode]);

    const handleSetSoundBoardRecordingDone = (value) => {
        console.log("Soundboard recording done: ", value);
        setSoundBoardRecordingDone(value);
    }

    const [triggerReload, setTriggerReload] = useState(false);

    const [room, setRoom] = useState(null);

    const [backgroundImageIndex, setBackgroundImageIndex] = useState(null);

    const [mediaBoardOpen, setMediaBoardOpen] = useState(false);

    const highQualityRecordingRef = useRef(null);
    const highQualitySoundboardRecordingRef = useRef(null);

    const startHQRecording = () => {
        if (highQualityRecordingRef.current && !highQualityRecordingRef.current.isHighQualityRecordingActive()) {
            highQualityRecordingRef.current.startRecording();
        }
        if (highQualitySoundboardRecordingRef.current && !highQualitySoundboardRecordingRef.current.isHighQualityRecordingActive()) {
            highQualitySoundboardRecordingRef.current.startRecording();
        }
    }

    const reliablyStartRecording = () => {
        startHQRecording();
        // Call start recording a couple of times to make sure it starts.
        setTimeout(() => {
            startHQRecording();
        }, 300);
        setTimeout(() => {
            startHQRecording();
        }, 600);
    }

    const stopHQRecording = () => {
        if (highQualityRecordingRef.current && highQualityRecordingRef.current.isHighQualityRecordingActive()) {
            highQualityRecordingRef.current.stopRecording();
        }
        if (highQualitySoundboardRecordingRef.current && highQualitySoundboardRecordingRef.current.isHighQualityRecordingActive()) {
            highQualitySoundboardRecordingRef.current.stopRecording();
        }
    }

    const reliablyStopRecording = () => {
        stopHQRecording();
        setTimeout(() => {
            stopHQRecording();
        }, 300);
        setTimeout(() => {
            stopHQRecording();
        }, 600);
    }

    useEffect(() => {
        setTriggerReload(prev => !prev);
    }, [selectedVideoResolution]);

    const handleNavigateToAsset = () => {
        navigate("/assets/" + assetIdRef.current);
    }

    // When recording is stopped, check the status of all local recording tracks for the assetId to see if all
    // local recordings are in "processing" state.
    useEffect(() => {
        if (!localRecordingDone || !assetIdRef.current) {
            return;
        }

        if (participantsLocalRecordingDone) {
            return;
        }

        const q = query(collection(db, kTracksCollectionName), where(TrackAssetId, "==", assetIdRef.current),
            where(TrackType, "in", [TypeOfTrack.LocalAudioRecording, TypeOfTrack.LocalVideoRecording, TypeOfTrack.SoundboardLocalRecording]));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const tracks = [];
            querySnapshot.forEach((doc) => {
                tracks.push(doc.data());
            });
            const allTracksDone = tracks.every(track => track.status !== "Recording in progress");
            if (allTracksDone) {
                setParticipantsLocalRecordingDone(true);
            }
        });

    }, [assetIdRef.current, localRecordingDone]);


    // Main state check. This is how recording is started/stopped and other studio settings are updated across clients.
    useEffect(() => {
        if (studioData.roomName !== '') {

            const q = query(collection(db, kRoomStateCollectionName), where(RoomName, "==", studioData.roomName));
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                const newRoomState = [];
                querySnapshot.forEach((doc) => {
                    newRoomState.push(doc.data());
                });
                if (newRoomState.length > 0) {
                    setNoiseCancellationEnabled(newRoomState[0].backgroundNoiseCancellationEnabled);
                    setLocalRecordingEnabled(newRoomState[0].hasOwnProperty("localRecordingEnabled") ? newRoomState[0].localRecordingEnabled : true);
                    setVideoMirrored(newRoomState[0].hasOwnProperty("videoMirrored") ? newRoomState[0].videoMirrored : false);
                    setSelectedVideoResolution(newRoomState[0].videoRecordingQuality);
                    setSelectedVideoLayout(newRoomState[0].videoRecordingLayout ? newRoomState[0].videoRecordingLayout : GridVideoLayout);
                    setSelectedLanguage(newRoomState[0].transcriptionLanguage);
                    setRecordingMode(AdaptRecordingMode(newRoomState[0].recordingType));
                    setStudioTheme(newRoomState[0].studioTheme ? newRoomState[0].studioTheme : LibrettoStudioTheme.Light);
                    setStudioNameStyle(newRoomState[0].studioNameStyle ? newRoomState[0].studioNameStyle : LibrettoStudioNameStyle.Clean);
                    setSelectedBrandLogo(newRoomState[0].studioBrandLogo && newRoomState[0].studioBrandLogo != "" ? newRoomState[0].studioBrandLogo : {
                        assetId: null,
                        url: null
                    });
                    // RoomNotRecording
                    if (newRoomState[0].roomState === 0) {
                        setRecordingInProgress(false);
                        setCloudEgressStarted(false);
                        setCountdown(null);
                        setRecordingStarting(false);
                        reliablyStopRecording();
                    }
                    // RoomRecordingStarting
                    if (newRoomState[0].roomState === 1 && countdown === null) {
                        setRecordingStarting(true);
                        setCountdown(5);
                        setShowAnnouncement(false);
                        reliablyStartRecording();
                    }
                    // RoomRecordingInProgress
                    if (newRoomState[0].roomState === 2 && !cloudEgressStarted) {
                        setCloudEgressStarted(true);
                        reliablyStartRecording();
                    }
                }
            });

            return () => unsubscribe();
        } else {

        }
    }, [studioData]);

    const urlFriendlyUserFriendlyRoomName = ({userFriendlyRoomName}) => {
        return userFriendlyRoomName.replace(/ /g, '-');
    }

    // Contexts
    const fetchContext = useContext(FetchContext);
    const auth = useContext(AuthContext);

    const tokenOptions = useMemo(() => {
        return {
            userInfo: {
                identity: userChoices.username,
                name: userChoices.username,
            },
        };
    }, [userChoices.username]);


    const startRecording = async () => {
        setShowStoppedCard(false);
        try {
            const response = await fetchContext.authAxios.post('/record', {
                projectId: projectId,
                type: RecordingModeString(recordingMode),
                title: userChoices.title ? userChoices.title : 'My podcast episode',
                hostIdentity: tokenOptions.userInfo.identity,
                livekitToken: studioData.token,
            }, {
                headers: {
                    Authorization: `Bearer ${auth.getToken()}`,
                }
            });
            mixpanel.track('Recording started', {
                'assetId': response.data.assetId,
                'localRecordingEnabled': localRecordingEnabled,
                'recordingMode': readableRecordingMode(recordingMode),
                'videoResolution': readableVideoResolution(selectedVideoResolution),
                'localRecordingStarted': highQualityRecordingRef.current.isHighQualityRecordingActive(),
            });
            assetIdRef.current = response.data.assetId;
        } catch (error) {
            console.log("Error starting recording: ", error)
            if (error.response.status === 401) {
                await RefreshTokenAndRetry(error, auth, fetchContext);
            }
        }
    };

    const stopRecording = async () => {
        try {
            await fetchContext.authAxios.post(`/stop`, {
                assetId: assetIdRef.current,
            }, {
                headers: {
                    Authorization: `Bearer ${auth.getToken()}`,
                }
            });
            setRecordingPerformed(true);
        } catch (error) {
            if (error.response.status === 401) {
                await RefreshTokenAndRetry(error, auth, fetchContext);
            }
        }
    };

    // The client UI state.
    useEffect(() => {
        if (cloudEgressStarted && countdown === null) {
            setRecordingInProgress(true);
        }
    }, [cloudEgressStarted, countdown]);

    useEffect(() => {
        setLocalRecordingInProgress(recordingStarting || recordingInProgress);
    }, [recordingStarting, recordingInProgress]);

    // Countdown before recording starts.
    useEffect(() => {
        let intervalId;
        if (countdown > 0) {
            intervalId = setInterval(() => {
                setCountdown((prevCountdown) => prevCountdown - 1);
            }, 1000);
        } else if (countdown === 0) {
            setCountdown(null);
        }

        // Clear interval on unmount or countdown change
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [countdown]);

    // Join studio when component loads. And make sure the room state is recording not started.
    useEffect(() => {

        const updateRoomState = async ({roomName}) => {
            if (roomName === '') {
                return;
            }

            const entityRef = doc(db, kRoomStateCollectionName, roomName);
            try {
                await updateDoc(entityRef, {roomState: RoomNotRecording});
            } catch (error) {
                console.error('Error updating recording type for room: ', error);
            }
        }
        const joinStudio = async () => {
            try {
                const {data} = await fetchContext.authAxios.post('/join', {
                    identity: tokenOptions.userInfo.identity,
                }, {
                    headers: {
                        Authorization: `Bearer ${auth.getToken()}`,
                    }
                });
                // When joining a room as a host (the first person in the room), make sure room is in a not recording state.
                updateRoomState({roomName: data.roomName});
                setStudioData({
                    token: data.token,
                    roomName: data.roomName,
                    userFriendlyRoomName: data.userFriendlyRoomName,
                    userPlan: data.userPlan,
                    hostIdentity: data.hostIdentity
                });
                console.log("User plan on join is: ", data.userPlan);
                setInviteLink(guestURL(data.roomName, data.userFriendlyRoomName));
            } catch (err) {
                if (err && err.response.status === 401) {
                    await RefreshTokenAndRetry(err, auth, fetchContext);
                }
                if (err.code === "ERR_NETWORK") {
                    await auth.logout();
                }
            }
        }

        joinStudio();
    }, [auth, fetchContext, tokenOptions]);

    // Add the soundboard to the studio as a hidden participant when the component loads.
    useEffect(() => {
        if (studioData.token === '')
            return;
        const joinSoundboard = async () => {
            try {
                const {data} = await fetchContext.authAxios.post('/soundboard', {
                    identity: "libretto_user_soundboard_" + studioData.roomName,
                }, {
                    headers: {
                        Authorization: `Bearer ${auth.getToken()}`,
                    }
                });
                setSoundboardToken(data.token);
            } catch (err) {
                if (err.code === "ERR_NETWORK") {
                    await auth.logout();
                }
            }
        }
        joinSoundboard();
    }, [studioData]);

    const guestURL = (roomName, userFriendlyRoomName) => {
        return CLIENT_URL + "/guest/" + roomName + "/" + urlFriendlyUserFriendlyRoomName({userFriendlyRoomName});
    }

    const onLeave = async () => {
        if (recordingInProgress) {
            await stopRecording();
        }
        navigate("/dashboard")
        setTimeout(() => {
            window.location.reload();
        }, 100);
    }

    const onDisconnected = () => {

    }

    const recordingTitle = userChoices.title ? userChoices.title : 'My podcast episode';

    const titleBoxStyle = {
        display: isMd ? 'flex' : 'none',
    }

    const countdownBoxStyle = {
        position: "absolute",
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "rgba(26, 26, 26, 0.15)",
        zIndex: 1000,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    }

    const countdownTextStyle = {
        color: "#2B6BFD",
        fontSize: "120px",
        fontStyle: "normal",
        fontWeight: "800",
        lineHeight: "normal",
        opacity: 1,
        zIndex: 999999,
        fontFamily: "Inter, sans-serif",  // Added fallback font
    }

    const [showRecordingGuidelines, setShowRecordingGuidelines] = useState(false);

    const topAreaStyle = (videoAreaFullScreen) => {
        return ({
            display: videoAreaFullScreen ? 'none' : 'flex',
        })
    }

    const infoIconStyle = {
        fontSize: '16px',
        marginLeft: '4px',
        verticalAlign: 'text-bottom',
        cursor: 'pointer',
        color: 'white',
    };

    const onInfoClick = () => {
        setShowRecordingGuidelines(true);
    }

    const averageUploadProgress = () => {
        return (uploadProgress + soundboardUploadProgress) / 2;
    }

    const {openDialog, ConfirmDialogComponent} = useConfirmDialog({confirmText: 'Leave', cancelText: 'Stay'});

    const handleLeaveWhileRecording = () => {
        openDialog({
            title: 'Leave studio while recording in progress?',
            message: 'Please stop the recording and wait for local recording to upload before leaving.',
            onConfirm: async () => {
                await onLeave();
            },
        });
    }

    const handleLeave = () => {
        if (recordingInProgress) {
            handleLeaveWhileRecording();
        } else {
            onLeave();
        }
    }

    return (
        <>
            {showAnnouncement && !isTooSmall && <Box sx={announcementBarStyle}>
                <Typography sx={announcementTextStyle}>
                    {announcement}
                    <Link
                        component="span"
                        onClick={onInfoClick}
                    >
                        <InfoIcon sx={infoIconStyle}/>
                    </Link>
                </Typography>
            </Box>}
            <ConfirmDialogComponent/>
            <Dialog
                open={isTooSmall}
                onClose={() => {
                }}
                aria-labelledby="form-dialog-title"
            >
                <DialogContent sx={{
                    borderRadius: '30px',
                    display: 'flex',        // Enable flexbox
                    justifyContent: 'center', // Center horizontally
                    alignItems: 'center',     // Center vertically
                    minWidth: {xs: '300px', md: '400px'},
                }}>
                    <Typography><b>Please switch to a larger screen.</b> For now, you need a larger screen
                        here.</Typography>
                </DialogContent>
            </Dialog>
            {soundboardToken ?
                <MediaBoard visible={mediaBoardOpen} onClose={() => setMediaBoardOpen(false)} projectId={projectId}
                            setUploadProgress={setSoundboardUploadProgress} assetIdRef={assetIdRef}
                            localRecordingEnabled={localRecordingEnabled}
                            setLocalRecordingStopped={() => {
                            }} setLocalRecordingDone={setSoundBoardRecordingDone}
                            highQualityRecordingRef={highQualitySoundboardRecordingRef}
                            token={soundboardToken}/> : null}
            <Grid container direction="column" style={pageStyle(studioTheme, showAnnouncement)} justifyContent="center"
                  alignItems="center">
                <Grid container justifyContent="space-between" alignItems="flex-start" position="relative"
                      sx={topAreaStyle(videoAreaFullScreen)}>
                    <Grid item marginLeft="32px" paddingTop={0} width={titleAreaWidth}
                          style={{display: "flex", justifyContent: "flex-start"}}>
                        <Box display={'flex'} gap={"8px"} paddingBottom={2} alignItems={"center"}
                             justifyContent={"center"}>
                            <Box>
                                <StudioBackIconButton theme={studioTheme} handleClick={handleLeave}/>
                            </Box>
                            <Box style={titleBoxStyle}>
                                <Typography style={titleStyle(studioTheme)}>{userChoices.title}</Typography>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid container item style={controlBarStyle(isTooSmall)} alignItems="center">
                        {room ? <NewCustomControlBar room={room} setBackgroundImage={setBackgroundImage}
                                                     roomName={studioData.roomName} controls={{chat: false}}
                                                     recordingInProgress={recordingInProgress}
                                                     backgroundImageIndex={backgroundImageIndex}
                                                     setBackgroundImageIndex={setBackgroundImageIndex}
                                                     isRecordButtonDisabled={false}
                                                     localRecordingInProgress={localRecordingInProgress}/> :
                            <Typography>Loading control bar..</Typography>}
                    </Grid>
                    <Grid item marginRight="32px" width="250px"
                          style={{display: isTooSmall ? "none" : "flex", justifyContent: "flex-end"}}>
                        <Box display={'flex'} gap={"12px"}>
                            <Tooltip title={"Settings"}>
                                <Box sx={smallButtonStyle} onClick={() => setSettingsOpen(!settingsOpen)}>
                                    <SettingsIcon/>
                                </Box>
                                <Dialog
                                    open={settingsOpen}
                                    onClose={() => setSettingsOpen(false)}
                                    aria-labelledby="form-dialog-title"
                                    maxWidth={"1000px"}
                                >
                                    <DialogContent sx={{
                                        borderRadius: '30px',
                                        display: 'flex',        // Enable flexbox
                                        justifyContent: 'center', // Center horizontally
                                        alignItems: 'center',     // Center vertically
                                        padding: "0px 0px 0px 0px",
                                    }}>
                                        <SettingsMenu onClose={() => setSettingsOpen(false)}
                                                      roomName={studioData.roomName}
                                                      noiseCancellationEnabled={noiseCancellationEnabled}
                                                      localRecordingEnabled={localRecordingEnabled}
                                                      selectedVideoLayout={selectedVideoLayout}
                                                      projectId={projectId}
                                                      videoMirrored={videoMirrored}
                                                      studioTheme={studioTheme}
                                                      studioNameStyle={studioNameStyle}
                                                      selectedLanguage={selectedLanguage}
                                                      selectedVideoResolution={selectedVideoResolution}
                                                      recordingMode={recordingMode}
                                                      selectedBrandLogo={selectedBrandLogo}
                                                      userPlan={studioData.userPlan}/>
                                    </DialogContent>
                                </Dialog>
                            </Tooltip>
                            <Box>
                                <Button variant="contained" sx={{
                                    borderRadius: "6px",
                                    padding: "10px 20px",
                                    display: "flex",
                                    height: buttonHeight,
                                    width: "106px",
                                    gap: "10px",
                                    backgroundColor: "#2B6BFD",
                                    '&:hover': {
                                        background: "linear-gradient(0deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%), #2B6BFD", // Darker primary color
                                    },
                                }} onClick={() => setInviteCardOpen(!inviteCardOpen)}>
                                    <InviteIcon style={{width: '18px', height: "18px"}}/>
                                    <Typography style={inviteButtonTextStyle}>Invite</Typography>
                                </Button>
                            </Box>
                            <Dialog
                                open={inviteCardOpen}
                                onClose={() => setInviteCardOpen(false)}
                                aria-labelledby="form-dialog-title"
                                maxWidth={"640px"}
                            >
                                <DialogContent sx={{
                                    borderRadius: '12px',
                                    display: 'flex',        // Enable flexbox
                                    justifyContent: 'center', // Center horizontally
                                    alignItems: 'center',     // Center vertically
                                    padding: "0px 0px 0px 0px",
                                }}>
                                    <NewInviteParticipantCard inviteLink={inviteLink}
                                                              onClose={() => setInviteCardOpen(false)}/>
                                </DialogContent>
                            </Dialog>
                            <Dialog
                                open={showRecordingGuidelines}
                                onClose={() => setShowRecordingGuidelines(false)}
                                aria-labelledby="form-dialog-title"
                                maxWidth={"500px"}
                            >
                                <DialogContent sx={{
                                    borderRadius: '30px',
                                    display: 'flex',        // Enable flexbox
                                    justifyContent: 'center', // Center horizontally
                                    alignItems: 'center',     // Center vertically
                                    padding: "0px 0px 0px 0px",
                                }}>
                                    <RecordingGuidelinesCard/>
                                </DialogContent>
                            </Dialog>
                        </Box>
                    </Grid>
                </Grid>
                {localRecordingStopped ? <NewRecordingStoppedCard uploadProgress={averageUploadProgress()}
                                                                  showRecordingEndedCard={showRecordingEndedCard}
                                                                  participantsLocalRecordingDone={participantsLocalRecordingDone}
                                                                  setShowRecordingEndedCard={setShowRecordingEndedCard}
                                                                  onNavigate={handleNavigateToAsset}
                                                                  soundboardRecordingDone={soundBoardRecordingDone}
                                                                  localRecordingDone={localRecordingDone}/> : null}
                <Grid item justifyContent="center" alignItems="center" style={videoDisplayStyle(videoAreaFullScreen)}>
                    <LiveKitRoom key={triggerReload} serverUrl={serverUrl} token={studioData.token}
                                 data-lk-theme="default"
                                 video={userChoices.videoEnabled} audio={userChoices.audioEnabled}
                                 style={{height: '100%', width: '100%', borderRadius: '8px'}}
                                 connect={true} onDisconnected={onDisconnected}>
                        {recordingMode === LibrettoRecordingMode.Audio ? <NewStudioVideoConference
                            localRecordingEnabled={localRecordingEnabled}
                            assetIdRef={assetIdRef}
                            backgroundImage={backgroundImage}
                            highQualityRecordingRef={highQualityRecordingRef}
                            roomName={studioData.roomName}
                            recordingInProgress={recordingInProgress}
                            startRecording={startRecording}
                            videoMirrored={videoMirrored}
                            studioNameStyle={StudioNameStyleString(studioNameStyle)}
                            localRecordingDone={localRecordingDone}
                            stopRecording={stopRecording}
                            enableKrispNoiseCancellation={noiseCancellationEnabled}
                            localRecordingInProgress={localRecordingInProgress}
                            livekitToken={studioData.token}
                            hostIdentity={studioData.hostIdentity}
                            brandLogoUrl={selectedBrandLogo.url}
                            setLocalRecordingDone={setLocalRecordingDone}
                            setLocalRecordingStopped={setLocalRecordingStopped}
                            videoResolution={selectedVideoResolution}
                            setUploadProgress={setUploadProgress}
                            setRoom={setRoom}
                        /> : <NewStudioVideoConferenceVideoRecording
                            setUploadProgress={setUploadProgress}
                            assetIdRef={assetIdRef}
                            localRecordingEnabled={localRecordingEnabled}
                            backgroundImage={backgroundImage}
                            highQualityRecordingRef={highQualityRecordingRef}
                            videoMirrored={videoMirrored}
                            roomName={studioData.roomName}
                            recordingInProgress={recordingInProgress}
                            startRecording={startRecording}
                            studioNameStyle={StudioNameStyleString(studioNameStyle)}
                            localRecordingDone={localRecordingDone}
                            stopRecording={stopRecording}
                            enableKrispNoiseCancellation={noiseCancellationEnabled}
                            localRecordingInProgress={localRecordingInProgress}
                            livekitToken={studioData.token}
                            hostIdentity={studioData.hostIdentity}
                            brandLogoUrl={selectedBrandLogo.url}
                            setLocalRecordingDone={setLocalRecordingDone}
                            setLocalRecordingStopped={setLocalRecordingStopped}
                            videoResolution={selectedVideoResolution}
                            setRoom={setRoom}
                        />}
                    </LiveKitRoom>
                </Grid>
                <Grid item alignSelf="center">
                    {countdown !== null && countdown > 0 && (<Box
                        style={countdownBoxStyle}
                    >
                        <Box style={countdownTextStyle}
                        >
                            {countdown}
                        </Box>
                    </Box>)}
                </Grid>
                <Grid container item justifyContent="center" alignItems="center"
                      display={isTooSmall ? "none" : "inline-flex"}>
                    <Grid container item justifyContent="center" alignItems="center" display="flex">
                        <Grid item style={soundBoardStyle(videoAreaFullScreen)}>
                            <Grid container spacing={1.5} direction="row" alignSelf="center" justifySelf="center">
                                <Grid item>
                                    <NewRecordButton recordingInProgress={recordingInProgress}
                                                     startRecording={startRecording}
                                                     stopRecording={stopRecording}
                                                     recordingPerformed={recordingPerformed}
                                                     localRecordingInProgress={localRecordingInProgress}/>
                                </Grid>
                                <Grid item>
                                    <MediaButton onClick={() => setMediaBoardOpen(!mediaBoardOpen)}
                                                 mediaBoardOpen={mediaBoardOpen}/>
                                </Grid>
                                <Grid item>
                                    {room && <HostLeaveButton room={room} onClick={handleLeave}/>}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default NewStudio;
