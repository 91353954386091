import React, { useState, useCallback } from 'react';
import { debounce } from "../../utils/debounce";
import { XIcon } from "../../assets/icons/XIcon";
import { CheckIcon } from "../../assets/icons/CheckIcon";
import './EffectShowcaseCard.styles.scss';
import {CardLoadingOverlay} from "../CardLoadingOverlay/CardLoadingOverlay"; // Assuming you've converted the SCSS to CSS
// Assuming you have this component

interface EffectShowcaseCardProps {
    imageUrl?: string;
    videoUrl?: string;
    name?: string;
    onDelete?: () => void;
    isSelected?: boolean;
    onMouseDown?: (event: React.MouseEvent<HTMLDivElement>) => Promise<unknown> | void;
}

const EffectShowcaseCard: React.FC<EffectShowcaseCardProps> = ({
                                                                   imageUrl,
                                                                   videoUrl,
                                                                   name,
                                                                   onDelete,
                                                                   isSelected = false,
                                                                   onMouseDown
                                                               }) => {
    const [isHovering, setIsHovering] = useState(false);
    const [imageLoaded, setImageLoaded] = useState(false);
    const [videoLoaded, setVideoLoaded] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleImageLoaded = () => setImageLoaded(true);
    const handleVideoLoaded = () => setVideoLoaded(true);

    const handleMouseOver = useCallback(
        debounce(() => setIsHovering(true), 100),
        []
    );

    const handleMouseOut = () => {
        setVideoLoaded(false);
        setTimeout(() => setIsHovering(false), 100);
    };

    const handleMouseDown = async (e: React.MouseEvent<HTMLDivElement>) => {
        if (!onMouseDown) return;

        setIsLoading(true);
        await Promise.resolve(onMouseDown(e));
        setIsLoading(false);
    };

    const isLoadingVisible = isLoading;
    const isVideoVisible = videoUrl != null && isHovering;
    const isDeleteVisible = isHovering && onDelete != null;

    return (
        <div
            className="effect-showcase"
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            onMouseDown={handleMouseDown}
        >
            <div className={`effect-showcase__root ${isSelected ? 'effect-showcase__root-selected' : ''}`}>
                {isLoadingVisible && (
                    <div className="effect-showcase__loading">
                        <CardLoadingOverlay />
                    </div>
                )}

                {isDeleteVisible && (
                    <div className="effect-showcase__delete">
                        <XIcon />
                    </div>
                )}

                {isSelected && (
                    <div className="effect-showcase__selected">
                        <CheckIcon />
                    </div>
                )}

                <img
                    className="effect-showcase__image"
                    src={imageUrl}
                    onLoad={handleImageLoaded}
                    style={{ opacity: imageLoaded ? 1 : 0 }}
                />

                {isVideoVisible && (
                    <video
                        className="effect-showcase__video"
                        src={videoUrl}
                        autoPlay
                        loop
                        preload="auto"
                        crossOrigin="anonymous"
                        playsInline
                        style={{ opacity: videoLoaded ? 1 : 0 }}
                        onLoadedData={handleVideoLoaded}
                    />
                )}
            </div>

            {name && (
                <span className={`effect-showcase__title ${isSelected ? 'effect-showcase__title-selected' : ''}`}>
                    {name}
                </span>
            )}
        </div>
    );
};

export default EffectShowcaseCard;